import {Component, HostListener, Input, OnInit} from '@angular/core';
import {AppGlobals} from "../../app.globals";
import {PublicDataService} from "../../shared/services/public-data.service";
import {EventsService} from "../../shared/services/events.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-home-page-slider',
  templateUrl: './home-page-slider.component.html',
  styleUrls: ['./home-page-slider.component.css']
})
export class HomePageSliderComponent implements OnInit {

  @Input() data;

  SliderItemsLength;
  ChangeSliderPixels = 0;
  ChangeSlider; //
  SliderItemsInPage;
  user: string;
  url = '';
  innerWidthState;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.onChangeResizeState(event.target.innerWidth)
  }

  constructor( public publicData: PublicDataService,
               private events: EventsService,
               public globals: AppGlobals,
               public router: Router ) {
    this.url = this.globals.urlForFiles;
    this.onChangeResizeState(window.innerWidth);
  }

  ngOnInit() {
      this.SliderItemsLength = this.data.length;
      this.events.ChangeSlider$.subscribe(event => {
        if(event === 'prev' && this.ChangeSliderPixels !== 0){
          this.ChangeSliderPixels += 370
        }
        if(event === 'next' && this.ChangeSliderPixels !== -(this.SliderItemsLength - 3) * 370 && this.SliderItemsLength > 3 && this.innerWidthState > 1590) {
          this.ChangeSliderPixels -= 370
        }
        if(event === 'next' && this.ChangeSliderPixels !== -(this.SliderItemsLength - 2) * 370 && this.SliderItemsLength > 3 && this.innerWidthState < 1590 && this.innerWidthState >= 1145) {
          this.ChangeSliderPixels -= 370
        }
        if(event === 'next' && this.ChangeSliderPixels !== -(this.SliderItemsLength - 1) * 370 && this.SliderItemsLength > 3 && this.innerWidthState <= 1144 && this.innerWidthState >= 1101) {
          this.ChangeSliderPixels -= 370
        }
        if(event === 'next' && this.ChangeSliderPixels !== -(this.SliderItemsLength -2) * 370 && this.SliderItemsLength > 3 && this.innerWidthState <= 1100 && this.innerWidthState >= 847) {
          this.ChangeSliderPixels -= 370
        }
        if(event === 'next' && this.ChangeSliderPixels !== -(this.SliderItemsLength -1) * 370 && this.SliderItemsLength > 3 && this.innerWidthState <= 846) {
          this.ChangeSliderPixels -= 370
        }
      })
  }

  onChangeResizeState(event) {
    this.innerWidthState = event;
    this.ChangeSliderPixels = 0;
  }

  errorImageLoad(e) {
    e.target.src = 'https://via.placeholder.com/150';
  }

  goToDetails(cart) {
    let lang = ''
    const rout = this.router.url.substring(1)
    if(rout.startsWith('en')) {
      lang = 'en'
    }
    if(rout.startsWith('ru')) {
      lang = 'ru'
    }
    if(rout.startsWith('am') || rout === '') {
      lang = ''
    }

    window.open(window.location.origin + `${lang !== '' ? '/' : ''}${lang}/details/${cart._id}/${cart.announcementType == 'Բնակարան' ? 'apartments' :
      cart.announcementType == 'Առանձնատուն' ? 'houses' :
        cart.announcementType == 'Տարածք' ? 'commercials' :
          (cart.announcementType == 'Համալիր' && cart.purpose == 'Բազմաֆունկցիոնալ' && cart.operational == 'Բազմաֆունկցիոնալ' && (cart.actualUse == 'Բազմաֆունկցիոնալ' || cart.actualUse == 'Նորակառույց')) ? 'newlyBuilds' :
            cart.announcementType == 'Համալիր' ? 'commercials' :
              cart.announcementType == 'Հողամաս' ? 'lands' :
                cart.announcementType == 'Բիզնես' ? 'businesses' : null
    }`, '_blank');
  }

}
