import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PublicDataService {

  initialMapClicker = false;

  language:string = 'am';
  searchForm:boolean = true;
  markers:any = [];
  complexIsDropdown:string = "";

  transaction = [
    {label: 'Վարձակալություն', value: 'Վարձակալություն'},
    {label: 'Վաճառք', value: 'Վաճառք'},
    {label: 'Օրավարձով', value: 'Օրավարձով'}
  ];
  transactionOnlySale = [
    {label: 'Վաճառք', value: 'Վաճառք'},
  ];
  building = [
    {label: 'Քարե', value: 'Քարե'},
    {label: 'Պանելային', value: 'Պանել'},
    {label: 'Միաձույլ', value: 'Միաձույլ'}
  ];
  rooms = [
    {label: '1', value: 1},
    {label: '2', value: 2},
    {label: '3', value: 3},
    {label: '4+', value: 4}
  ];
  surface = [
    {label: 'մինչև 50', value: {min: 2, max: 50}},
    {label: '50-80', value: {min: 50, max: 80}},
    {label: '80-120', value: {min: 80, max: 120}},
    {label: '120+', value: {min: 120, max: 99999999999999}}
  ];
  surfaceLand = [
    {label: 'մինչև 300', value: {min: 2, max: 300}},
    {label: '300-500', value: {min: 300, max: 500}},
    {label: '500-1000', value: {min: 500, max: 1000}},
    {label: '1000+', value: {min: 1000, max: 99999999999999}}
  ];
  pricesStart = [
    {label: '0', value: 0},
    {label: '10000', value: 10000},
    {label: '20000', value: 20000},
    {label: '30000', value: 30000},
    {label: '40000', value: 40000},
    {label: '50000', value: 50000},
    {label: '60000', value: 60000},
    {label: '70000', value: 70000},
    {label: '80000', value: 80000},
    {label: '90000', value: 90000},
    {label: '100000', value: 100000},
    {label: '150000', value: 150000},
    {label: '200000', value: 200000},
    {label: '250000', value: 250000},
    {label: '300000', value: 300000},
    {label: '350000', value: 350000},
    {label: '400000', value: 400000},
    {label: '450000', value: 450000},
    {label: '500000', value: 500000},

    {label: '600000', value: 600000},
    {label: '700000', value: 700000},
    {label: '800000', value: 800000},
    {label: '900000', value: 900000},
    {label: '1000000', value: 1000000},

    // {label: '1000000+', value: 10000005}
  ];
  pricesEnd = [
    {label: '10000', value: 10000},
    {label: '20000', value: 20000},
    {label: '30000', value: 30000},
    {label: '40000', value: 40000},
    {label: '50000', value: 50000},
    {label: '60000', value: 60000},
    {label: '70000', value: 70000},
    {label: '80000', value: 80000},
    {label: '90000', value: 90000},
    {label: '100000', value: 100000},
    {label: '150000', value: 150000},
    {label: '200000', value: 200000},
    {label: '250000', value: 250000},
    {label: '300000', value: 300000},
    {label: '350000', value: 350000},
    {label: '400000', value: 400000},
    {label: '450000', value: 450000},
    {label: '500000', value: 500000},

    {label: '600000', value: 600000},
    {label: '700000', value: 700000},
    {label: '800000', value: 800000},
    {label: '900000', value: 900000},
    // {label: '1000000', value: 1000000},

    {label: '1000000+', value: 100000000000005}
  ];
  surfaceHouse = [
    {label: 'Մինչև 50 քմ', value: {min: 1, max: 50}},
    {label: '50-100', value: {min: 50, max: 100}},
    {label: '100-150', value: {min: 100, max: 150}},
    {label: '150-200', value: {min: 150, max: 200}},
    {label: '250-300', value: {min: 250, max: 300}},
    {label: '350-400', value: {min: 350, max: 400}},
    {label: '450-500', value: {min: 450, max: 500}},
    {label: '550-600', value: {min: 550, max: 600}}
  ];
  residentialHouse = [
    {label: 'Մինչև 50 քմ', value: {min: 1, max: 50}},
    {label: '50-100', value: {min: 50, max: 100}},
    {label: '100-150', value: {min: 100, max: 150}},
    {label: '150-200', value: {min: 150, max: 200}},
    {label: '250-300', value: {min: 250, max: 300}},
    {label: '350-400', value: {min: 350, max: 400}},
    {label: '450-500', value: {min: 450, max: 500}}
  ];
  flooring = [
    {label: '1', value: 1},
    {label: '2', value: 2},
    {label: '3', value: 3},
    {label: '4', value: 4},
    {label: '5', value: 5},
    {label: '6', value: 6},
    {label: '7', value: 7},
    {label: '8', value: 8},
    {label: '9', value: 9},
    {label: '10', value: 10},
    {label: '11', value: 11},
    {label: '12', value: 12},
    {label: '13', value: 13},
    {label: '14', value: 14},
    {label: '15', value: 15},
    {label: '16', value: 16},
    {label: '17', value: 17},
    {label: '18', value: 18},
    {label: '19', value: 19},
    {label: '20', value: 20},
  ];
  floor = [
    {label: '1', value: 1},
    {label: '2', value: 2},
    {label: '3', value: 3},
    {label: '4', value: 4},
    {label: '5', value: 5},
    {label: '6', value: 6},
    {label: '7', value: 7},
    {label: '8', value: 8},
    {label: '9', value: 9},
    {label: '10', value: 10},
    {label: '11', value: 11},
    {label: '12', value: 12},
    {label: '13', value: 13},
    {label: '14', value: 14},
    {label: '15', value: 15},
    {label: '16', value: 16},
    {label: '17', value: 17},
    {label: '18', value: 18},
    {label: '19', value: 19},
    {label: '20', value: 20},
  ];
  buildingArea = [
    {label: 'Մինչև 50 քմ', value: {min: 1, max: 50}},
    {label: '50-100', value: {min: 50, max: 100}},
    {label: '100-150', value: {min: 100, max: 150}},
    {label: '150-200', value: {min: 150, max: 200}},
    {label: '250-300', value: {min: 250, max: 300}},
    {label: '350-400', value: {min: 350, max: 400}},
    {label: '450-500', value: {min: 450, max: 500}}
  ];
  actualUse = [
    {
      "label": "Հասարակական",
      "value": "Հասարակական"
    },
    {
      "label": "Առևտրային",
      "value": "Առևտրային"
    },
    {
      "label": "Տեխսպասարկում",
      "value": "Տեխսպասարկում"
    },
    {
      "label": "Բենզալցակայան",
      "value": "Բենզալցակայան"
    },
    {
      "label": "ԱԳԼՃԿ",
      "value": "ԱԳԼՃԿ"
    },
    {
      "label": "Արտադրական",
      "value": "Արտադրական"
    },
    {
      "label": "Հիդրոէլեկտրակայան",
      "value": "Հիդրոէլեկտրակայան"
    },
    {
      "label": "Ընդերքի օգտագործում",
      "value": "Ընդերքի օգտագործում"
    },
    {
      "label": "Անասնապահական",
      "value": "Անասնապահական"
    },
    {
      "label": "Թռչնաֆաբրիկա",
      "value": "Թռչնաֆաբրիկա"
    },
    {
      "label": "Ձկնաբուծարան",
      "value": "Ձկնաբուծարան"
    },
    {
      "label": "Ջերմոց",
      "value": "Ջերմոց"
    },
    {
      "label": "Հյուրանոց հոթել, մոթել",
      "value": "Հյուրանոց հոթել, մոթել"
    },
    {
      "label": "Ռեստորան-հյուրանոց",
      "value": "Ռեստորան-հյուրանոց"
    },
    {
      "label": "Ռեստորան",
      "value": "Ռեստորան"
    },
    {
      "label": "Սրճարան",
      "value": "Սրճարան"
    },
    {
      "label": "Նկուղ կիսանկուղ",
      "value": "Նկուղ կիսանկուղ"
    }
  ];
  transactions = [
    {
      "label": "Վաճառք",
      "value": "Վաճառք"
    },
    {
      "label": "Վարձակալություն",
      "value": "Վարձակալություն"
    },
    {
      "label": "Օրավարձով",
      "value": "Օրավարձով"
    }
  ];
  transactionsCommercial = [
    {
      "label": "Վաճառք",
      "value": "Վաճառք"
    },
    {
      "label": "Վարձակալություն",
      "value": "Վարձակալություն"
    }
  ];
  regions = [
    {
      "label": "Երևան",
      "value": "Երևան"
    },
    {
      "label": "Արագածոտն",
      "value": "Արագածոտն"
    },
    {
      "label": "Արարատ",
      "value": "Արարատ"
    },
    {
      "label": "Արմավիր",
      "value": "Արմավիր"
    },
    {
      "label": "Գեղարքունիք",
      "value": "Գեղարքունիք"
    },
    {
      "label": "Լոռի",
      "value": "Լոռի"
    },
    {
      "label": "Կոտայք",
      "value": "Կոտայք"
    },
    {
      "label": "Շիրակ",
      "value": "Շիրակ"
    },
    {
      "label": "Սյունիք",
      "value": "Սյունիք"
    },
    {
      "label": "Վայոց Ձոր",
      "value": "Վայոց Ձոր"
    },
    {
      "label": "Տավուշ",
      "value": "Տավուշ"
    }
  ];
  cities = [
    {
      city: 'Երևան',
      info: [
        {
          "label": "Երևան",
          "value": "Երևան"
        }
      ]
    },
    {
      city: 'Արագածոտն',
      info: [
        {
          "label": "Աշտարակ ք.",
          "value": "Աշտարակ ք."
        },
        {
          "label": "Ապարան ք.",
          "value": "Ապարան ք."
        },
        {
          "label": "Թալին ք.",
          "value": "Թալին ք."
        },
        {
          "label": "Գյուղեր(Արագածոտն)",
          "value": "Գյուղեր(Արագածոտն)"
        }
      ]
    },
    {
      city: 'Արարատ',
      info: [
        {
          "label": "Արարատ ք.",
          "value": "Արարատ ք."
        },
        {
          "label": "Արտաշատ ք.",
          "value": "Արտաշատ ք."
        },
        {
          "label": "Մասիս ք.",
          "value": "Մասիս ք."
        },
        {
          "label": "Վեդի ք.",
          "value": "Վեդի ք."
        },
        {
          "label": "Գյուղեր(Արարատ)",
          "value": "Գյուղեր(Արարատ)"
        }
      ]
    },
    {
      city: 'Արմավիր',
      info: [
        {
          "label": "Արմավիր ք.",
          "value": "Արմավիր ք."
        },
        {
          "label": "Մեծամոր ք.",
          "value": "Մեծամոր ք."
        },
        {
          "label": "Վաղարշապատ /Էջմիածին/ ք.",
          "value": "Վաղարշապատ /Էջմիածին/ ք."
        },
        {
          "label": "Գյուղեր(Արմավիր)",
          "value": "Գյուղեր(Արմավիր)"
        }
      ]
    },
    {
      city: 'Գեղարքունիք',
      info: [
        {
          "label": "Գավառ ք.",
          "value": "Գավառ ք."
        },
        {
          "label": "Ճամբարակ ք.",
          "value": "Ճամբարակ ք."
        },
        {
          "label": "Մարտունի ք.",
          "value": "Մարտունի ք."
        },
        {
          "label": "Սևան ք.",
          "value": "Սևան ք."
        },
        {
          "label": "Վարդենիս ք.",
          "value": "Վարդենիս ք."
        },
        {
          "label": "Գյուղեր(Գեղարքունիք)",
          "value": "Գյուղեր(Գեղարքունիք)"
        }
      ]
    },
    {
      city: 'Լոռի',
      info: [
        {
          "label": "Ալավերդի ք.",
          "value": "Ալավերդի ք."
        },
        {
          "label": "Ախթալա ք.",
          "value": "Ախթալա ք."
        },
        {
          "label": "Թումանյան ք.",
          "value": "Թումանյան ք."
        },
        {
          "label": "Շամլուղ ք.",
          "value": "Շամլուղ ք."
        },
        {
          "label": "Սպիտակ ք.",
          "value": "Սպիտակ ք."
        },
        {
          "label": "Ստեփանավան ք.",
          "value": "Ստեփանավան ք."
        },
        {
          "label": "Վանաձոր ք.",
          "value": "Վանաձոր ք."
        },
        {
          "label": "Տաշիր ք.",
          "value": "Տաշիր ք."
        },
        {
          "label": "Գյուղեր(Լոռի)",
          "value": "Գյուղեր(Լոռի)"
        }
      ]
    },
    {
      city: 'Կոտայք',
      info: [
        {
          "label": "Աբովյան ք.",
          "value": "Աբովյան ք."
        },
        {
          "label": "Բյուրեղավան ք.",
          "value": "Բյուրեղավան ք."
        },
        {
          "label": "Ծաղկաձոր ք.",
          "value": "Ծաղկաձոր ք."
        },
        {
          "label": "Հրազդան ք.",
          "value": "Հրազդան ք."
        },
        {
          "label": "Նոր Հաճն ք.",
          "value": "Նոր Հաճն ք."
        },
        {
          "label": "Չարենցավան ք.",
          "value": "Չարենցավան ք."
        },
        {
          "label": "Գյուղեր(Կոտայք)",
          "value": "Գյուղեր(Կոտայք)"
        }
      ]
    },
    {
      city: 'Շիրակ',
      info: [
        {
          "label": "Արթիկ ք.",
          "value": "Արթիկ ք."
        },
        {
          "label": "Գյումրի ք.",
          "value": "Գյումրի ք."
        },
        {
          "label": "Մարալիկ ք.",
          "value": "Մարալիկ ք."
        },
        {
          "label": "Գյուղեր(Շիրակ)",
          "value": "Գյուղեր(Շիրակ)"
        }
      ]
    },
    {
      city: 'Սյունիք',
      info: [
        {
          "label": "Ագարակ ք.",
          "value": "Ագարակ ք."
        },
        {
          "label": "Գորիս ք.",
          "value": "Գորիս ք."
        },
        {
          "label": "Դաստակերտ ք.",
          "value": "Դաստակերտ ք."
        },
        {
          "label": "Կապան ք.",
          "value": "Կապան ք."
        },
        {
          "label": "Մեղրի ք.",
          "value": "Մեղրի ք."
        },
        {
          "label": "Սիսիան ք.",
          "value": "Սիսիան ք."
        },
        {
          "label": "Քաջարան ք.",
          "value": "Քաջարան ք."
        },
        {
          "label": "Գյուղեր(Սյունիք)",
          "value": "Գյուղեր(Սյունիք)"
        }
      ]
    },
    {
      city: 'Վայոց Ձոր',
      info: [
        {
          "label": "Եղեգնաձոր ք.",
          "value": "Եղեգնաձոր"
        },
        {
          "label": "Ջերմուկ ք.",
          "value": "Ջերմուկ ք."
        },
        {
          "label": "Վայք ք.",
          "value": "Վայք ք."
        },
        {
          "label": "Գյուղեր(Վայոց Ձոր)",
          "value": "Գյուղեր(Վայոց Ձոր)"
        }
      ]
    },
    {
      city: 'Տավուշ',
      info: [
        {
          "label": "Այրում ք.",
          "value": "Այրում ք."
        },
        {
          "label": "Բերդ ք.",
          "value": "Բերդ ք."
        },
        {
          "label": "Դիլիջան ք.",
          "value": "Դիլիջան ք."
        },
        {
          "label": "Իջևան ք.",
          "value": "Իջևան ք."
        },
        {
          "label": "Նոյեմբերյան ք.",
          "value": "Նոյեմբերյան ք."
        },
        {
          "label": "Գյուղեր(Տավուշ)",
          "value": "Գյուղեր(Տավուշ)"
        }
      ]
    }
  ];
  communities = [
    {
      city: 'Երևան',
      info: [
        {
          "label": "Աջափնյակ",
          "value": "Աջափնյակ"
        },
        {
          "label": "Ավան",
          "value": "Ավան"
        },
        {
          "label": "Արաբկիր",
          "value": "Արաբկիր"
        },
        {
          "label": "Դավթաշեն",
          "value": "Դավթաշեն"
        },
        {
          "label": "Էրեբունի",
          "value": "Էրեբունի"
        },
        {
          "label": "Կենտրոն",
          "value": "Կենտրոն"
        },
        {
          "label": "Մալաթիա-Սեբաստիա",
          "value": "Մալաթիա-Սեբաստիա"
        },
        {
          "label": "Նոր-Նորք",
          "value": "Նոր-Նորք"
        },
        {
          "label": "Նորք-Մարաշ",
          "value": "Նորք-Մարաշ"
        },
        {
          "label": "Նուբարաշեն",
          "value": "Նուբարաշեն"
        },
        {
          "label": "Շենգավիթ",
          "value": "Շենգավիթ"
        },
        {
          "label": "Վահագնի թաղամաս",
          "value": "Վահագնի թաղամաս"
        },
        {
          "label": "Քանաքեռ-Զեյթուն",
          "value": "Քանաքեռ-Զեյթուն"
        }
      ]
    },
    {
      city: 'Գյուղեր(Արագածոտն)',
      info: [
        {
          "label": "Ագարակ գ.",
          "value": "Ագարակ գ."
        },
        {
          "label": "Ագարակավան գ.",
          "value": "Ագարակավան գ."
        },
        {
          "label": "Ալագյազ գ.",
          "value": "Ալագյազ գ."
        },
        {
          "label": "Ակունք գ.",
          "value": "Ակունք գ."
        },
        {
          "label": "Աղձք գ.",
          "value": "Աղձք գ."
        },
        {
          "label": "Ամրե Թազա գ.",
          "value": "Ամրե Թազա գ."
        },
        {
          "label": "Անտառուտ գ.",
          "value": "Անտառուտ գ."
        },
        {
          "label": "Աշնակ գ.",
          "value": "Աշնակ գ."
        },
        {
          "label": "Ավան գ.",
          "value": "Ավան գ."
        },
        {
          "label": "Ավթոնա գ.",
          "value": "Ավթոնա գ."
        },
        {
          "label": "Ավշեն գ.",
          "value": "Ավշեն գ."
        },
        {
          "label": "Արա գ.",
          "value": "Արա գ."
        },
        {
          "label": "Արագած գ.",
          "value": "Արագած գ."
        },
        {
          "label": "Արագածավան գ.",
          "value": "Արագածավան գ."
        },
        {
          "label": "Արագածոտն գ.",
          "value": "Արագածոտն գ."
        },
        {
          "label": "Արեգ գ.",
          "value": "Արեգ գ."
        },
        {
          "label": "Արուճ գ.",
          "value": "Արուճ գ."
        },
        {
          "label": "Արտաշավան գ.",
          "value": "Արտաշավան գ."
        },
        {
          "label": "Արտենի գ.",
          "value": "Արտենի գ."
        },
        {
          "label": "Ափնագյուղ գ.",
          "value": "Ափնագյուղ գ."
        },
        {
          "label": "Բազմաղբյուր գ.",
          "value": "Բազմաղբյուր գ."
        },
        {
          "label": "Բայսզ գ.",
          "value": "Բայսզ գ."
        },
        {
          "label": "Բարոժ գ.",
          "value": "Բարոժ գ."
        },
        {
          "label": "Բերքառատ գ.",
          "value": "Բերքառատ գ."
        },
        {
          "label": "Բյուրական գ.",
          "value": "Բյուրական գ."
        },
        {
          "label": "Բուժական գ.",
          "value": "Բուժական գ."
        },
        {
          "label": "Գառնահովիտ գ.",
          "value": "Գառնահովիտ գ."
        },
        {
          "label": "Գեղադիր գ.",
          "value": "Գեղադիր գ."
        },
        {
          "label": "Գեղաձոր գ.",
          "value": "Գեղաձոր գ."
        },
        {
          "label": "Գեղարոտ գ.",
          "value": "Գեղարոտ գ."
        },
        {
          "label": "Գետափ գ.",
          "value": "Գետափ գ."
        },
        {
          "label": "Գյալթո գ.",
          "value": "Գյալթո գ."
        },
        {
          "label": "Դաշտադեմ գ.",
          "value": "Դաշտադեմ գ."
        },
        {
          "label": "Դավթաշեն գ.",
          "value": "Դավթաշեն գ."
        },
        {
          "label": "Դերեկ գ.",
          "value": "Դերեկ գ."
        },
        {
          "label": "Դիան գ.",
          "value": "Դիան գ."
        },
        {
          "label": "Դպրեվանք գ.",
          "value": "Դպրեվանք գ."
        },
        {
          "label": "Եղիպատրուշ գ.",
          "value": "Եղիպատրուշ գ."
        },
        {
          "label": "Եղնիկ գ.",
          "value": "Եղնիկ գ."
        },
        {
          "label": "Երջատափ գ.",
          "value": "Երջատափ գ."
        },
        {
          "label": "Երրորդ Սովխոզ գ.",
          "value": "Երրորդ Սովխոզ գ."
        },
        {
          "label": "Զարինջա գ.",
          "value": "Զարինջա գ."
        },
        {
          "label": "Զովասար գ.",
          "value": "Զովասար գ."
        },
        {
          "label": "Թթուջուր գ.",
          "value": "Թթուջուր գ."
        },
        {
          "label": "Թլիկ գ.",
          "value": "Թլիկ գ."
        },
        {
          "label": "Իրինդ գ.",
          "value": "Իրինդ գ."
        },
        {
          "label": "Լեռնապատ գ.",
          "value": "Լեռնապատ գ."
        },
        {
          "label": "Լեռնապար գ.",
          "value": "Լեռնապար գ."
        },
        {
          "label": "Լուսագյուղ գ.",
          "value": "Լուսագյուղ գ."
        },
        {
          "label": "Լուսակն գ.",
          "value": "Լուսակն գ."
        },
        {
          "label": "Լուսաղբյուր գ.",
          "value": "Լուսաղբյուր գ."
        },
        {
          "label": "Խնուսիկ գ.",
          "value": "Խնուսիկ գ."
        },
        {
          "label": "Ծաղկահովիտ գ.",
          "value": "Ծաղկահովիտ գ."
        },
        {
          "label": "Ծաղկաշեն գ.",
          "value": "Ծաղկաշեն գ."
        },
        {
          "label": "Ծաղկասար գ.",
          "value": "Ծաղկասար գ."
        },
        {
          "label": "Ծիլքար գ.",
          "value": "Ծիլքար գ."
        },
        {
          "label": "Կաթնաղբյուր գ.",
          "value": "Կաթնաղբյուր գ."
        },
        {
          "label": "Կարբի գ.",
          "value": "Կարբի գ."
        },
        {
          "label": "Կարին գ.",
          "value": "Կարին գ."
        },
        {
          "label": "Կարմրաշեն գ.",
          "value": "Կարմրաշեն գ."
        },
        {
          "label": "Կաքավաձոր գ.",
          "value": "Կաքավաձոր գ."
        },
        {
          "label": "Կոշ գ.",
          "value": "Կոշ գ."
        },
        {
          "label": "Հարթավան գ.",
          "value": "Հարթավան գ."
        },
        {
          "label": "Հացաշեն գ.",
          "value": "Հացաշեն գ."
        },
        {
          "label": "Հնաբերդ գ.",
          "value": "Հնաբերդ գ."
        },
        {
          "label": "Հնաշեն գ.",
          "value": "Հնաշեն գ."
        },
        {
          "label": "Հոկկո գ.",
          "value": "Հոկկո գ."
        },
        {
          "label": "Ձորագյուղ գ.",
          "value": "Ձորագյուղ գ."
        },
        {
          "label": "Ղաբաղթափա գ.",
          "value": "Ղաբաղթափա գ."
        },
        {
          "label": "Ղազարավան /Նազրվան/ գ.",
          "value": "Ղազարավան /Նազրվան/ գ."
        },
        {
          "label": "Ղազարվան գ.",
          "value": "Ղազարվան գ."
        },
        {
          "label": "Մաստարա գ.",
          "value": "Մաստարա գ."
        },
        {
          "label": "Մելիքգյուղ գ.",
          "value": "Մելիքգյուղ գ."
        },
        {
          "label": "Միրաք գ.",
          "value": "Միրաք գ."
        },
        {
          "label": "Մուլքի գ.",
          "value": "Մուլքի գ."
        },
        {
          "label": "Մուղնի գ.",
          "value": "Մուղնի գ."
        },
        {
          "label": "Ներքին Բազմաբերդ գ.",
          "value": "Ներքին Բազմաբերդ գ."
        },
        {
          "label": "Ներքին Սասունաշեն գ.",
          "value": "Ներքին Սասունաշեն գ."
        },
        {
          "label": "Նիգավան գ.",
          "value": "Նիգավան գ."
        },
        {
          "label": "Նիգատուն գ.",
          "value": "Նիգատուն գ."
        },
        {
          "label": "Նոր Ամանոս գ.",
          "value": "Նոր Ամանոս գ."
        },
        {
          "label": "Նորաշեն գ.",
          "value": "Նորաշեն գ."
        },
        {
          "label": "Շամիրամ գ.",
          "value": "Շամիրամ գ."
        },
        {
          "label": "Շենավան գ.",
          "value": "Շենավան գ."
        },
        {
          "label": "Շենկանի գ.",
          "value": "Շենկանի գ."
        },
        {
          "label": "Շղարշիկ գ.",
          "value": "Շղարշիկ գ."
        },
        {
          "label": "Շողակն գ.",
          "value": "Շողակն գ."
        },
        {
          "label": "Ոսկեթաս գ.",
          "value": "Ոսկեթաս գ."
        },
        {
          "label": "Ոսկեհատ գ.",
          "value": "Ոսկեհատ գ."
        },
        {
          "label": "Ոսկեվազ գ.",
          "value": "Ոսկեվազ գ."
        },
        {
          "label": "Ուշի գ.",
          "value": "Ուշի գ."
        },
        {
          "label": "Ուջան գ.",
          "value": "Ուջան գ."
        },
        {
          "label": "Չքնաղ գ.",
          "value": "Չքնաղ գ."
        },
        {
          "label": "Պարտիզակ գ.",
          "value": "Պարտիզակ գ."
        },
        {
          "label": "Պռոշյան գ.",
          "value": "Պռոշյան գ."
        },
        {
          "label": "Ջամշլու գ.",
          "value": "Ջամշլու գ."
        },
        {
          "label": "Ջրամբար գ.",
          "value": "Ջրամբար գ."
        },
        {
          "label": "Ռյա Թազա գ.",
          "value": "Ռյա Թազա գ."
        },
        {
          "label": "Սաղմոսավան գ.",
          "value": "Սաղմոսավան գ."
        },
        {
          "label": "Սանգյառ գ.",
          "value": "Սանգյառ գ."
        },
        {
          "label": "Սասունիկ գ.",
          "value": "Սասունիկ գ."
        },
        {
          "label": "Սարալանջ գ.",
          "value": "Սարալանջ գ."
        },
        {
          "label": "Սիփան գ.",
          "value": "Սիփան գ."
        },
        {
          "label": "Սորիկ գ.",
          "value": "Սորիկ գ."
        },
        {
          "label": "Սուսեր գ.",
          "value": "Սուսեր գ."
        },
        {
          "label": "Վարդաբյուր գ.",
          "value": "Վարդաբյուր գ."
        },
        {
          "label": "Վարդենիս գ.",
          "value": "Վարդենիս գ."
        },
        {
          "label": "Վարդենուտ գ.",
          "value": "Վարդենուտ գ."
        },
        {
          "label": "Վերին Բազմաբերդ գ.",
          "value": "Վերին Բազմաբերդ գ."
        },
        {
          "label": "Վերին Սասունաշեն գ.",
          "value": "Վերին Սասունաշեն գ."
        },
        {
          "label": "Վերին Սասունիկ գ.",
          "value": "Վերին Սասունիկ գ."
        },
        {
          "label": "Տեղեր գ.",
          "value": "Տեղեր գ."
        },
        {
          "label": "Ցամաքասար գ.",
          "value": "Ցամաքասար գ."
        },
        {
          "label": "Փարպի գ.",
          "value": "Փարպի գ."
        },
        {
          "label": "Քուչակ գ.",
          "value": "Քուչակ գ."
        },
        {
          "label": "Օհանավան գ.",
          "value": "Օհանավան գ."
        },
        {
          "label": "Օշական գ.",
          "value": "Օշական գ."
        },
        {
          "label": "Օրգով գ.",
          "value": "Օրգով գ."
        },
        {
          "label": "Օրթաճյա գ.",
          "value": "Օրթաճյա գ."
        }
      ]
    },
    {
      city: 'Գյուղեր(Արարատ)',
      info: [
        {
          "label": "Աբովյան գ.",
          "value": "Աբովյան գ."
        },
        {
          "label": "Ազատաշեն գ.",
          "value": "Ազատաշեն գ."
        },
        {
          "label": "Ազատավան գ.",
          "value": "Ազատավան գ."
        },
        {
          "label": "Այգավան գ.",
          "value": "Այգավան գ."
        },
        {
          "label": "Այգեզարդ գ.",
          "value": "Այգեզարդ գ."
        },
        {
          "label": "Այգեպատ գ.",
          "value": "Այգեպատ գ."
        },
        {
          "label": "Այգեստան գ.",
          "value": "Այգեստան գ."
        },
        {
          "label": "Այնթապ գ.",
          "value": "Այնթապ գ."
        },
        {
          "label": "Ավշար գ.",
          "value": "Ավշար գ."
        },
        {
          "label": "Արալեզ գ.",
          "value": "Արալեզ գ."
        },
        {
          "label": "Արարատ գ.",
          "value": "Արարատ գ."
        },
        {
          "label": "Արաքսավան գ.",
          "value": "Արաքսավան գ."
        },
        {
          "label": "Արբաթ գ.",
          "value": "Արբաթ գ."
        },
        {
          "label": "Արգավանդ գ.",
          "value": "Արգավանդ գ."
        },
        {
          "label": "Արևաբույր գ.",
          "value": "Արևաբույր գ."
        },
        {
          "label": "Արևշատ գ.",
          "value": "Արևշատ գ."
        },
        {
          "label": "Արմաշ գ.",
          "value": "Արմաշ գ."
        },
        {
          "label": "Բաղրամյան գ.",
          "value": "Բաղրամյան գ."
        },
        {
          "label": "Բարձրաշեն գ.",
          "value": "Բարձրաշեն գ."
        },
        {
          "label": "Բերդիկ գ.",
          "value": "Բերդիկ գ."
        },
        {
          "label": "Բերքանուշ գ.",
          "value": "Բերքանուշ գ."
        },
        {
          "label": "Բյուրավան գ.",
          "value": "Բյուրավան գ."
        },
        {
          "label": "Բուրաստան գ.",
          "value": "Բուրաստան գ."
        },
        {
          "label": "Գեղանիստ գ.",
          "value": "Գեղանիստ գ."
        },
        {
          "label": "Գետազատ գ.",
          "value": "Գետազատ գ."
        },
        {
          "label": "Գետափնյա գ.",
          "value": "Գետափնյա գ."
        },
        {
          "label": "Գինեվետ գ.",
          "value": "Գինեվետ գ."
        },
        {
          "label": "Գոռավան գ.",
          "value": "Գոռավան գ."
        },
        {
          "label": "Դալար գ.",
          "value": "Դալար գ."
        },
        {
          "label": "Դաշտավան գ.",
          "value": "Դաշտավան գ."
        },
        {
          "label": "Դաշտաքար գ.",
          "value": "Դաշտաքար գ."
        },
        {
          "label": "Դարակերտ գ.",
          "value": "Դարակերտ գ."
        },
        {
          "label": "Դարբնիկ գ.",
          "value": "Դարբնիկ գ."
        },
        {
          "label": "Դեղձուտ գ.",
          "value": "Դեղձուտ գ."
        },
        {
          "label": "Դիմիտրով գ.",
          "value": "Դիմիտրով գ."
        },
        {
          "label": "Դիտակ գ.",
          "value": "Դիտակ գ."
        },
        {
          "label": "Դվին գ.",
          "value": "Դվին գ."
        },
        {
          "label": "Եղեգնավան գ.",
          "value": "Եղեգնավան գ."
        },
        {
          "label": "Երասխ գ.",
          "value": "Երասխ գ."
        },
        {
          "label": "Զանգակատուն գ.",
          "value": "Զանգակատուն գ."
        },
        {
          "label": "Զորակ գ.",
          "value": "Զորակ գ."
        },
        {
          "label": "Լանջազատ գ.",
          "value": "Լանջազատ գ."
        },
        {
          "label": "Լանջանիստ գ.",
          "value": "Լանջանիստ գ."
        },
        {
          "label": "Լանջառ գ.",
          "value": "Լանջառ գ."
        },
        {
          "label": "Լուսաշող գ.",
          "value": "Լուսաշող գ."
        },
        {
          "label": "Լուսառատ գ.",
          "value": "Լուսառատ գ."
        },
        {
          "label": "Խաչպար գ.",
          "value": "Խաչպար գ."
        },
        {
          "label": "Կանաչուտ գ.",
          "value": "Կանաչուտ գ."
        },
        {
          "label": "Կաքավաբերդ գ.",
          "value": "Կաքավաբերդ գ."
        },
        {
          "label": "Հայանիստ գ.",
          "value": "Հայանիստ գ."
        },
        {
          "label": "Հնաբերդ գ.",
          "value": "Հնաբերդ գ."
        },
        {
          "label": "Հովտաշատ գ.",
          "value": "Հովտաշատ գ."
        },
        {
          "label": "Հովտաշեն գ.",
          "value": "Հովտաշեն գ."
        },
        {
          "label": "Ղուկասավան գ.",
          "value": "Ղուկասավան գ."
        },
        {
          "label": "Մասիս գ.",
          "value": "Մասիս գ."
        },
        {
          "label": "Մարմարաշեն գ.",
          "value": "Մարմարաշեն գ."
        },
        {
          "label": "Մխչյան գ.",
          "value": "Մխչյան գ."
        },
        {
          "label": "Մրգանուշ գ.",
          "value": "Մրգանուշ գ."
        },
        {
          "label": "Մրգավան գ.",
          "value": "Մրգավան գ."
        },
        {
          "label": "Մրգավետ գ.",
          "value": "Մրգավետ գ."
        },
        {
          "label": "Նարեկ գ.",
          "value": "Նարեկ գ."
        },
        {
          "label": "Նիզամի գ.",
          "value": "Նիզամի գ."
        },
        {
          "label": "Նշավան գ.",
          "value": "Նշավան գ."
        },
        {
          "label": "Նոյակերտ գ.",
          "value": "Նոյակերտ գ."
        },
        {
          "label": "Նոր Խարբերդ գ.",
          "value": "Նոր Խարբերդ գ."
        },
        {
          "label": "Նոր Կյանք գ.",
          "value": "Նոր Կյանք գ."
        },
        {
          "label": "Նոր Կյուրին գ.",
          "value": "Նոր Կյուրին գ."
        },
        {
          "label": "Նոր Ուղի գ.",
          "value": "Նոր Ուղի գ."
        },
        {
          "label": "Նորաբաց գ.",
          "value": "Նորաբաց գ."
        },
        {
          "label": "Նորամարգ գ.",
          "value": "Նորամարգ գ."
        },
        {
          "label": "Նորաշեն գ.",
          "value": "Նորաշեն գ."
        },
        {
          "label": "Նորաշեն համ.",
          "value": "Նորաշեն համ."
        },
        {
          "label": "Շահումյան գ.",
          "value": "Շահումյան գ."
        },
        {
          "label": "Շաղափ գ.",
          "value": "Շաղափ գ."
        },
        {
          "label": "Ոսկետափ գ.",
          "value": "Ոսկետափ գ."
        },
        {
          "label": "Ոստան գ.",
          "value": "Ոստան գ."
        },
        {
          "label": "Ուրցալանջ գ.",
          "value": "Ուրցալանջ գ."
        },
        {
          "label": "Ուրցաձոր գ.",
          "value": "Ուրցաձոր գ."
        },
        {
          "label": "Պարույր Սևակ գ.",
          "value": "Պարույր Սևակ գ."
        },
        {
          "label": "Ջրահովիտ գ.",
          "value": "Ջրահովիտ գ."
        },
        {
          "label": "Ջրաշեն գ.",
          "value": "Ջրաշեն գ."
        },
        {
          "label": "Ռանչպար գ.",
          "value": "Ռանչպար գ."
        },
        {
          "label": "Սայաթ-Նովա գ.",
          "value": "Սայաթ-Նովա գ."
        },
        {
          "label": "Սիս գ.",
          "value": "Սիս գ."
        },
        {
          "label": "Սիսավան գ.",
          "value": "Սիսավան գ."
        },
        {
          "label": "Սիփանիկ գ.",
          "value": "Սիփանիկ գ."
        },
        {
          "label": "Սուրենավան գ.",
          "value": "Սուրենավան գ."
        },
        {
          "label": "Վադաշետ գ.",
          "value": "Վադաշետ գ."
        },
        {
          "label": "Վանաշեն գ.",
          "value": "Վանաշեն գ."
        },
        {
          "label": "Վարդաշատ գ.",
          "value": "Վարդաշատ գ."
        },
        {
          "label": "Վարդաշեն գ.",
          "value": "Վարդաշեն գ."
        },
        {
          "label": "Վերին Արտաշատ գ.",
          "value": "Վերին Արտաշատ գ."
        },
        {
          "label": "Վերին Դվին գ.",
          "value": "Վերին Դվին գ."
        },
        {
          "label": "Տափերական գ.",
          "value": "Տափերական գ."
        },
        {
          "label": "Փոքր Վեդի գ.",
          "value": "Փոքր Վեդի գ."
        },
        {
          "label": "Քաղցրաշեն գ.",
          "value": "Քաղցրաշեն գ."
        }
      ]
    },
    {
      city: 'Գյուղեր(Արմավիր)',
      info: [
        {
          "label": "Ալաշկերտ գ.",
          "value": "Ալաշկերտ գ."
        },
        {
          "label": "Ակնալիճ գ.",
          "value": "Ակնալիճ գ."
        },
        {
          "label": "Ակնաշեն գ.",
          "value": "Ակնաշեն գ."
        },
        {
          "label": "Աղավնատուն գ.",
          "value": "Աղավնատուն գ."
        },
        {
          "label": "Ամասիա գ.",
          "value": "Ամասիա գ."
        },
        {
          "label": "Ամբերդ գ.",
          "value": "Ամբերդ գ."
        },
        {
          "label": "Այգեկ գ.",
          "value": "Այգեկ գ."
        },
        {
          "label": "Այգեշատ գ.",
          "value": "Այգեշատ գ."
        },
        {
          "label": "Այգևան գ.",
          "value": "Այգևան գ."
        },
        {
          "label": "Ապագա գ.",
          "value": "Ապագա գ."
        },
        {
          "label": "Առատաշեն գ.",
          "value": "Առատաշեն գ."
        },
        {
          "label": "Արագած գ.",
          "value": "Արագած գ."
        },
        {
          "label": "Արազափ գ.",
          "value": "Արազափ գ."
        },
        {
          "label": "Արաքս գ.",
          "value": "Արաքս գ."
        },
        {
          "label": "Արգավանդ գ.",
          "value": "Արգավանդ գ."
        },
        {
          "label": "Արգինա գ.",
          "value": "Արգինա գ."
        },
        {
          "label": "Արևադաշտ գ.",
          "value": "Արևադաշտ գ."
        },
        {
          "label": "Արևաշատ գ.",
          "value": "Արևաշատ գ."
        },
        {
          "label": "Արևիկ գ.",
          "value": "Արևիկ գ."
        },
        {
          "label": "Արևշատ գ.",
          "value": "Արևշատ գ."
        },
        {
          "label": "Արմավիր գ.",
          "value": "Արմավիր գ."
        },
        {
          "label": "Արշալույս գ.",
          "value": "Արշալույս գ."
        },
        {
          "label": "Արտամետ գ.",
          "value": "Արտամետ գ."
        },
        {
          "label": "Արտաշար գ.",
          "value": "Արտաշար գ."
        },
        {
          "label": "Արտիմետ գ.",
          "value": "Արտիմետ գ."
        },
        {
          "label": "Բագարան գ.",
          "value": "Բագարան գ."
        },
        {
          "label": "Բաղրամյան գ.",
          "value": "Բաղրամյան գ."
        },
        {
          "label": "Բամբակաշատ գ.",
          "value": "Բամբակաշատ գ."
        },
        {
          "label": "Բերքաշատ գ.",
          "value": "Բերքաշատ գ."
        },
        {
          "label": "Գայ գ.",
          "value": "Գայ գ."
        },
        {
          "label": "Գեղակերտ գ.",
          "value": "Գեղակերտ գ."
        },
        {
          "label": "Գետաշեն գ.",
          "value": "Գետաշեն գ."
        },
        {
          "label": "Գրիբոյեդով գ.",
          "value": "Գրիբոյեդով գ."
        },
        {
          "label": "Դալարիկ գ.",
          "value": "Դալարիկ գ."
        },
        {
          "label": "Դաշտ գ.",
          "value": "Դաշտ գ."
        },
        {
          "label": "Դողս գ.",
          "value": "Դողս գ."
        },
        {
          "label": "Եղեգնուտ գ.",
          "value": "Եղեգնուտ գ."
        },
        {
          "label": "Երասխահուն գ.",
          "value": "Երասխահուն գ."
        },
        {
          "label": "Երվանդաշատ գ.",
          "value": "Երվանդաշատ գ."
        },
        {
          "label": "Զարթոնք գ.",
          "value": "Զարթոնք գ."
        },
        {
          "label": "Թաիրով գ.",
          "value": "Թաիրով գ."
        },
        {
          "label": "Լենուղի գ.",
          "value": "Լենուղի գ."
        },
        {
          "label": "Լեռնագոգ գ.",
          "value": "Լեռնագոգ գ."
        },
        {
          "label": "Լեռնամերձ գ.",
          "value": "Լեռնամերձ գ."
        },
        {
          "label": "Լուկաշին գ.",
          "value": "Լուկաշին գ."
        },
        {
          "label": "Լուսագյուղ գ.",
          "value": "Լուսագյուղ գ."
        },
        {
          "label": "Խորոնք գ.",
          "value": "Խորոնք գ."
        },
        {
          "label": "Ծաղկլանջ գ.",
          "value": "Ծաղկլանջ գ."
        },
        {
          "label": "Ծաղկունք գ.",
          "value": "Ծաղկունք գ."
        },
        {
          "label": "Ծաղկունք համ.,",
          "value": "Ծաղկունք համ.,"
        },
        {
          "label": "Ծիածան գ.",
          "value": "Ծիածան գ."
        },
        {
          "label": "Կողթավան գ.",
          "value": "Կողթավան գ."
        },
        {
          "label": "Հայթաղ գ.",
          "value": "Հայթաղ գ."
        },
        {
          "label": "Հայկաշեն գ.",
          "value": "Հայկաշեն գ."
        },
        {
          "label": "Հայկավան գ.",
          "value": "Հայկավան գ."
        },
        {
          "label": "Հացիկ գ.",
          "value": "Հացիկ գ."
        },
        {
          "label": "Հոկտեմբեր գ.",
          "value": "Հոկտեմբեր գ."
        },
        {
          "label": "Հովտամեջ գ.",
          "value": "Հովտամեջ գ."
        },
        {
          "label": "Հուշակերտ գ.",
          "value": "Հուշակերտ գ."
        },
        {
          "label": "Մայիսյան գ.",
          "value": "Մայիսյան գ."
        },
        {
          "label": "Մարգարա գ.",
          "value": "Մարգարա գ."
        },
        {
          "label": "Մեծամոր գ.",
          "value": "Մեծամոր գ."
        },
        {
          "label": "Մերձավան գ.",
          "value": "Մերձավան գ."
        },
        {
          "label": "Մյասնիկյան գ.",
          "value": "Մյասնիկյան գ."
        },
        {
          "label": "Մուսալեռ գ.",
          "value": "Մուսալեռ գ."
        },
        {
          "label": "Մրգաշատ գ.",
          "value": "Մրգաշատ գ."
        },
        {
          "label": "Մրգաստան գ.",
          "value": "Մրգաստան գ."
        },
        {
          "label": "Նալբանդյան գ.",
          "value": "Նալբանդյան գ."
        },
        {
          "label": "Ներ Կեսարիա գ.",
          "value": "Ներ Կեսարիա գ."
        },
        {
          "label": "Նոր Ամասիա գ.",
          "value": "Նոր Ամասիա գ."
        },
        {
          "label": "Նոր Արևադաշտ գ.",
          "value": "Նոր Արևադաշտ գ."
        },
        {
          "label": "Նոր Արմավիր գ.",
          "value": "Նոր Արմավիր գ."
        },
        {
          "label": "Նոր Արտագերս գ.",
          "value": "Նոր Արտագերս գ."
        },
        {
          "label": "Նորակերտ գ.",
          "value": "Նորակերտ գ."
        },
        {
          "label": "Նորապատ գ.",
          "value": "Նորապատ գ."
        },
        {
          "label": "Նորավան գ.",
          "value": "Նորավան գ."
        },
        {
          "label": "Շահումյան գ.",
          "value": "Շահումյան գ."
        },
        {
          "label": "Շենավան գ.",
          "value": "Շենավան գ."
        },
        {
          "label": "Շենիկ գ.",
          "value": "Շենիկ գ."
        },
        {
          "label": "Ոսկեհատ գ.",
          "value": "Ոսկեհատ գ."
        },
        {
          "label": "Պտղունք գ.",
          "value": "Պտղունք գ."
        },
        {
          "label": "Ջանֆիդա գ.",
          "value": "Ջանֆիդա գ."
        },
        {
          "label": "Ջրառատ գ.",
          "value": "Ջրառատ գ."
        },
        {
          "label": "Ջրարբի գ.",
          "value": "Ջրարբի գ."
        },
        {
          "label": "Սամաղար գ.",
          "value": "Սամաղար գ."
        },
        {
          "label": "Վանանդ գ.",
          "value": "Վանանդ գ."
        },
        {
          "label": "Վաչե գ.",
          "value": "Վաչե գ."
        },
        {
          "label": "Վարդանաշեն գ.",
          "value": "Վարդանաշեն գ."
        },
        {
          "label": "Տալվորիկ գ.",
          "value": "Տալվորիկ գ."
        },
        {
          "label": "Տանձուտ գ.",
          "value": "Տանձուտ գ."
        },
        {
          "label": "Տարոնիկ գ.",
          "value": "Տարոնիկ գ."
        },
        {
          "label": "Փարաքար գ.",
          "value": "Փարաքար գ."
        },
        {
          "label": "Փշատավան գ.",
          "value": "Փշատավան գ."
        },
        {
          "label": "Քարակերտ գ.",
          "value": "Քարակերտ գ."
        }
      ]
    },
    {
      city: 'Գյուղեր(Գեղարքունիք)',
      info: [
        {
          "label": "Ազատ գ.",
          "value": "Ազատ գ."
        },
        {
          "label": "Ախպրաձոր գ.",
          "value": "Ախպրաձոր գ."
        },
        {
          "label": "Ակունք գ.",
          "value": "Ակունք գ."
        },
        {
          "label": "Աղբերք գ.",
          "value": "Աղբերք գ."
        },
        {
          "label": "Այգուտ գ.",
          "value": "Այգուտ գ."
        },
        {
          "label": "Այրք գ.",
          "value": "Այրք գ."
        },
        {
          "label": "Անտառամեջ գ.",
          "value": "Անտառամեջ գ."
        },
        {
          "label": "Աստղաձոր գ.",
          "value": "Աստղաձոր գ."
        },
        {
          "label": "Ավազան գ.",
          "value": "Ավազան գ."
        },
        {
          "label": "Արեգունի գ.",
          "value": "Արեգունի գ."
        },
        {
          "label": "Արծվանիստ գ.",
          "value": "Արծվանիստ գ."
        },
        {
          "label": "Արծվաշեն գ.",
          "value": "Արծվաշեն գ."
        },
        {
          "label": "Արտանիշ գ.",
          "value": "Արտանիշ գ."
        },
        {
          "label": "Արփունք գ.",
          "value": "Արփունք գ."
        },
        {
          "label": "Բարեպատ գ.",
          "value": "Բարեպատ գ."
        },
        {
          "label": "Բերդկունք գ.",
          "value": "Բերդկունք գ."
        },
        {
          "label": "Գագարին գ.",
          "value": "Գագարին գ."
        },
        {
          "label": "Գանձակ գ.",
          "value": "Գանձակ գ."
        },
        {
          "label": "Գեղահովիտ գ.",
          "value": "Գեղահովիտ գ."
        },
        {
          "label": "Գեղամասար գ.",
          "value": "Գեղամասար գ."
        },
        {
          "label": "Գեղամավան գ.",
          "value": "Գեղամավան գ."
        },
        {
          "label": "Գեղամբակ գ.",
          "value": "Գեղամբակ գ."
        },
        {
          "label": "Գեղարքունիք գ.",
          "value": "Գեղարքունիք գ."
        },
        {
          "label": "Գեղաքար գ.",
          "value": "Գեղաքար գ."
        },
        {
          "label": "Գեղհովիտ գ.",
          "value": "Գեղհովիտ գ."
        },
        {
          "label": "Գետիկ գ.",
          "value": "Գետիկ գ."
        },
        {
          "label": "Դարանակ գ.",
          "value": "Դարանակ գ."
        },
        {
          "label": "Դդմաշեն գ.",
          "value": "Դդմաշեն գ."
        },
        {
          "label": "Դպրաբակ գ.",
          "value": "Դպրաբակ գ."
        },
        {
          "label": "Դրախտիկ գ.",
          "value": "Դրախտիկ գ."
        },
        {
          "label": "Երանոս գ.",
          "value": "Երանոս գ."
        },
        {
          "label": "Զառիվեր գ.",
          "value": "Զառիվեր գ."
        },
        {
          "label": "Զոլաքար գ.",
          "value": "Զոլաքար գ."
        },
        {
          "label": "Զովաբեր գ.",
          "value": "Զովաբեր գ."
        },
        {
          "label": "Թթուջուր գ.",
          "value": "Թթուջուր գ."
        },
        {
          "label": "Լանջաղբյուր գ.",
          "value": "Լանջաղբյուր գ."
        },
        {
          "label": "Լեռնակերտ գ.",
          "value": "Լեռնակերտ գ."
        },
        {
          "label": "Լիճք գ.",
          "value": "Լիճք գ."
        },
        {
          "label": "Լճաշեն գ.",
          "value": "Լճաշեն գ."
        },
        {
          "label": "Լճավան գ.",
          "value": "Լճավան գ."
        },
        {
          "label": "Լճափ գ.",
          "value": "Լճափ գ."
        },
        {
          "label": "Լուսակունք գ.",
          "value": "Լուսակունք գ."
        },
        {
          "label": "Խաչաղբյուր գ.",
          "value": "Խաչաղբյուր գ."
        },
        {
          "label": "Ծակքար գ.",
          "value": "Ծակքար գ."
        },
        {
          "label": "Ծաղկաշեն գ.",
          "value": "Ծաղկաշեն գ."
        },
        {
          "label": "Ծաղկունք գ.",
          "value": "Ծաղկունք գ."
        },
        {
          "label": "Ծափաթաղ գ.",
          "value": "Ծափաթաղ գ."
        },
        {
          "label": "Ծովագյուղ գ.",
          "value": "Ծովագյուղ գ."
        },
        {
          "label": "Ծովազարդ գ.",
          "value": "Ծովազարդ գ."
        },
        {
          "label": "Ծովակ գ.",
          "value": "Ծովակ գ."
        },
        {
          "label": "Ծովասար գ.",
          "value": "Ծովասար գ."
        },
        {
          "label": "Ծովինար գ.",
          "value": "Ծովինար գ."
        },
        {
          "label": "Կալավան գ.",
          "value": "Կալավան գ."
        },
        {
          "label": "Կախակն գ.",
          "value": "Կախակն գ."
        },
        {
          "label": "Կարճաղբյուր գ.",
          "value": "Կարճաղբյուր գ."
        },
        {
          "label": "Կարմիրգյուղ գ.",
          "value": "Կարմիրգյուղ գ."
        },
        {
          "label": "Կութ գ.",
          "value": "Կութ գ."
        },
        {
          "label": "Կուտական գ.",
          "value": "Կուտական գ."
        },
        {
          "label": "Հայրավանք գ.",
          "value": "Հայրավանք գ."
        },
        {
          "label": "Ձորագյուղ գ.",
          "value": "Ձորագյուղ գ."
        },
        {
          "label": "Ձորավանք գ.",
          "value": "Ձորավանք գ."
        },
        {
          "label": "Ճապկուտ գ.",
          "value": "Ճապկուտ գ."
        },
        {
          "label": "Մադինա գ.",
          "value": "Մադինա գ."
        },
        {
          "label": "Մարտունի գ.",
          "value": "Մարտունի գ."
        },
        {
          "label": "Մաքենիս գ.",
          "value": "Մաքենիս գ."
        },
        {
          "label": "Մեծ Մասրիկ գ.",
          "value": "Մեծ Մասրիկ գ."
        },
        {
          "label": "Ներքին Գետաշեն գ.",
          "value": "Ներքին Գետաշեն գ."
        },
        {
          "label": "Ներքին Շորժա գ.",
          "value": "Ներքին Շորժա գ."
        },
        {
          "label": "Նշխարք գ.",
          "value": "Նշխարք գ."
        },
        {
          "label": "Նորաբակ գ.",
          "value": "Նորաբակ գ."
        },
        {
          "label": "Նորակերտ գ.",
          "value": "Նորակերտ գ."
        },
        {
          "label": "Նորաշեն գ.",
          "value": "Նորաշեն գ."
        },
        {
          "label": "Նորատուս գ.",
          "value": "Նորատուս գ."
        },
        {
          "label": "Շատջրեք գ.",
          "value": "Շատջրեք գ."
        },
        {
          "label": "Շատվան գ.",
          "value": "Շատվան գ."
        },
        {
          "label": "Շորժա գ.",
          "value": "Շորժա գ."
        },
        {
          "label": "Չկալովկա գ.",
          "value": "Չկալովկա գ."
        },
        {
          "label": "Ջաղացձոր գ.",
          "value": "Ջաղացձոր գ."
        },
        {
          "label": "Ջիլ գ.",
          "value": "Ջիլ գ."
        },
        {
          "label": "Սարուխան գ.",
          "value": "Սարուխան գ."
        },
        {
          "label": "Սեմյոնովկա գ.",
          "value": "Սեմյոնովկա գ."
        },
        {
          "label": "Սոթք գ.",
          "value": "Սոթք գ."
        },
        {
          "label": "Վահան գ.",
          "value": "Վահան գ."
        },
        {
          "label": "Վաղաշեն գ.",
          "value": "Վաղաշեն գ."
        },
        {
          "label": "Վանևան գ.",
          "value": "Վանևան գ."
        },
        {
          "label": "Վարդաձոր գ.",
          "value": "Վարդաձոր գ."
        },
        {
          "label": "Վարդենիկ գ.",
          "value": "Վարդենիկ գ."
        },
        {
          "label": "Վարսեր գ.",
          "value": "Վարսեր գ."
        },
        {
          "label": "Վերին Գետաշեն գ.",
          "value": "Վերին Գետաշեն գ."
        },
        {
          "label": "Վերին Շորժա գ.",
          "value": "Վերին Շորժա գ."
        },
        {
          "label": "Տերետուք գ.",
          "value": "Տերետուք գ."
        },
        {
          "label": "Տորֆավան գ.",
          "value": "Տորֆավան գ."
        },
        {
          "label": "Փամբակ գ.",
          "value": "Փամբակ գ."
        },
        {
          "label": "Փոքր Մասրիկ գ.",
          "value": "Փոքր Մասրիկ գ."
        }
      ]
    },
    {
      city: 'Գյուղեր(Լոռի)',
      info: [
        {
          "label": "Ագարակ գ.",
          "value": "Ագարակ գ."
        },
        {
          "label": "Ազնվաձոր գ.",
          "value": "Ազնվաձոր գ."
        },
        {
          "label": "Աթան գ.",
          "value": "Աթան գ."
        },
        {
          "label": "Ախթալայի առողջարանին կից գ.",
          "value": "Ախթալայի առողջարանին կից գ."
        },
        {
          "label": "Ակներ գ.",
          "value": "Ակներ գ."
        },
        {
          "label": "Ահնիձոր գ.",
          "value": "Ահնիձոր գ."
        },
        {
          "label": "Ամոջ գ.",
          "value": "Ամոջ գ."
        },
        {
          "label": "Ամրակից գ.",
          "value": "Ամրակից գ."
        },
        {
          "label": "Այգեհատ գ.",
          "value": "Այգեհատ գ."
        },
        {
          "label": "Անտառամուտ գ.",
          "value": "Անտառամուտ գ."
        },
        {
          "label": "Անտառաշեն գ.",
          "value": "Անտառաշեն գ."
        },
        {
          "label": "Ապավեն գ.",
          "value": "Ապավեն գ."
        },
        {
          "label": "Արդվի գ.",
          "value": "Արդվի գ."
        },
        {
          "label": "Արևածագ գ.",
          "value": "Արևածագ գ."
        },
        {
          "label": "Արևշող գ.",
          "value": "Արևշող գ."
        },
        {
          "label": "Արծնի գ.",
          "value": "Արծնի գ."
        },
        {
          "label": "Արմանիս գ.",
          "value": "Արմանիս գ."
        },
        {
          "label": "Արջուտ գ.",
          "value": "Արջուտ գ."
        },
        {
          "label": "Արջուտ գ.",
          "value": "Արջուտ գ."
        },
        {
          "label": "Արջուտ կայարան գ.",
          "value": "Արջուտ կայարան գ."
        },
        {
          "label": "Աքորի գ.",
          "value": "Աքորի գ."
        },
        {
          "label": "Բազում գ.",
          "value": "Բազում գ."
        },
        {
          "label": "Բենդիկ գ.",
          "value": "Բենդիկ գ."
        },
        {
          "label": "Բլագոդարնոյե գ.",
          "value": "Բլագոդարնոյե գ."
        },
        {
          "label": "Բովաձոր գ.",
          "value": "Բովաձոր գ."
        },
        {
          "label": "Գարգառ գ.",
          "value": "Գարգառ գ."
        },
        {
          "label": "Գեղասար գ.",
          "value": "Գեղասար գ."
        },
        {
          "label": "Գետավան գ.",
          "value": "Գետավան գ."
        },
        {
          "label": "Գյուլագարակ գ.",
          "value": "Գյուլագարակ գ."
        },
        {
          "label": "Գյուլլուդրա գ.",
          "value": "Գյուլլուդրա գ."
        },
        {
          "label": "Գոգավան գ.",
          "value": "Գոգավան գ."
        },
        {
          "label": "Գոգարան գ.",
          "value": "Գոգարան գ."
        },
        {
          "label": "Գուգարք գ.",
          "value": "Գուգարք գ."
        },
        {
          "label": "Դաշտադեմ գ.",
          "value": "Դաշտադեմ գ."
        },
        {
          "label": "Դարպաս գ.",
          "value": "Դարպաս գ."
        },
        {
          "label": "Դեբետ գ.",
          "value": "Դեբետ գ."
        },
        {
          "label": "Դսեղ գ.",
          "value": "Դսեղ գ."
        },
        {
          "label": "Եղեգնուտ գ.",
          "value": "Եղեգնուտ գ."
        },
        {
          "label": "Թեղուտ գ.",
          "value": "Թեղուտ գ."
        },
        {
          "label": "Լեջան գ.",
          "value": "Լեջան գ."
        },
        {
          "label": "Լեռնահովիտ գ.",
          "value": "Լեռնահովիտ գ."
        },
        {
          "label": "Լեռնանցք գ.",
          "value": "Լեռնանցք գ."
        },
        {
          "label": "Լեռնապատ գ.",
          "value": "Լեռնապատ գ."
        },
        {
          "label": "Լեռնավան գ.",
          "value": "Լեռնավան գ."
        },
        {
          "label": "Լերմոնտովո գ.",
          "value": "Լերմոնտովո գ."
        },
        {
          "label": "Լոռի բերդ գ.",
          "value": "Լոռի բերդ գ."
        },
        {
          "label": "Լորուտ գ.",
          "value": "Լորուտ գ."
        },
        {
          "label": "Լուսաղբյուր գ.",
          "value": "Լուսաղբյուր գ."
        },
        {
          "label": "Խնկոյան գ.",
          "value": "Խնկոյան գ."
        },
        {
          "label": "Ծաթեր գ.",
          "value": "Ծաթեր գ."
        },
        {
          "label": "Ծաղկաբեր գ.",
          "value": "Ծաղկաբեր գ."
        },
        {
          "label": "Ծաղկաշատ գ.",
          "value": "Ծաղկաշատ գ."
        },
        {
          "label": "Կաթնաղբյուր գ.",
          "value": "Կաթնաղբյուր գ."
        },
        {
          "label": "Կաթնաջուր գ.",
          "value": "Կաթնաջուր գ."
        },
        {
          "label": "Կաթնառատ գ.",
          "value": "Կաթնառատ գ."
        },
        {
          "label": "Կաճաճկուտ գ.",
          "value": "Կաճաճկուտ գ."
        },
        {
          "label": "Կարմիր Աղեկ գ.",
          "value": "Կարմիր Աղեկ գ."
        },
        {
          "label": "Կողես գ.",
          "value": "Կողես գ."
        },
        {
          "label": "Կուրթան գ.",
          "value": "Կուրթան գ."
        },
        {
          "label": "Կրուգլայա Շիշկա գ.",
          "value": "Կրուգլայա Շիշկա գ."
        },
        {
          "label": "Հագվի գ.",
          "value": "Հագվի գ."
        },
        {
          "label": "Հալավար գ.",
          "value": "Հալավար գ."
        },
        {
          "label": "Հաղպատ գ.",
          "value": "Հաղպատ գ."
        },
        {
          "label": "Հայդարլի գ.",
          "value": "Հայդարլի գ."
        },
        {
          "label": "Հարթագյուղ գ.",
          "value": "Հարթագյուղ գ."
        },
        {
          "label": "Հոբարձի գ.",
          "value": "Հոբարձի գ."
        },
        {
          "label": "Հովնանաձոր գ.",
          "value": "Հովնանաձոր գ."
        },
        {
          "label": "Ձյունաշող գ.",
          "value": "Ձյունաշող գ."
        },
        {
          "label": "Ձորագետ գ.",
          "value": "Ձորագետ գ."
        },
        {
          "label": "Ձորագյուղ գ.",
          "value": "Ձորագյուղ գ."
        },
        {
          "label": "Ձորամուտ գ.",
          "value": "Ձորամուտ գ."
        },
        {
          "label": "Ղուրսալի գ.",
          "value": "Ղուրսալի գ."
        },
        {
          "label": "Ճոճկան գ.",
          "value": "Ճոճկան գ."
        },
        {
          "label": "Մարգահովիտ գ.",
          "value": "Մարգահովիտ գ."
        },
        {
          "label": "Մարց գ.",
          "value": "Մարց գ."
        },
        {
          "label": "Մեդովկա գ.",
          "value": "Մեդովկա գ."
        },
        {
          "label": "Մեծ Այրում գ.",
          "value": "Մեծ Այրում գ."
        },
        {
          "label": "Մեծ Պարնի գ.",
          "value": "Մեծ Պարնի գ."
        },
        {
          "label": "Մեծավան գ.",
          "value": "Մեծավան գ."
        },
        {
          "label": "Մեղվահովիտ գ.",
          "value": "Մեղվահովիտ գ."
        },
        {
          "label": "Միխայլովկա գ.",
          "value": "Միխայլովկա գ."
        },
        {
          "label": "Մղարթ գ.",
          "value": "Մղարթ գ."
        },
        {
          "label": "Յաղդան գ.",
          "value": "Յաղդան գ."
        },
        {
          "label": "Նեղոց գ.",
          "value": "Նեղոց գ."
        },
        {
          "label": "Նովոսելցովո գ.",
          "value": "Նովոսելցովո գ."
        },
        {
          "label": "Նոր Խաչկապ գ.",
          "value": "Նոր Խաչկապ գ."
        },
        {
          "label": "Նորամուտ գ.",
          "value": "Նորամուտ գ."
        },
        {
          "label": "Նորաշեն գ.",
          "value": "Նորաշեն գ."
        },
        {
          "label": "Շահումյան գ.",
          "value": "Շահումյան գ."
        },
        {
          "label": "Շամուտ գ.",
          "value": "Շամուտ գ."
        },
        {
          "label": "Շենավան գ.",
          "value": "Շենավան գ."
        },
        {
          "label": "Շիրակամուտ գ.",
          "value": "Շիրակամուտ գ."
        },
        {
          "label": "Շնող գ.",
          "value": "Շնող գ."
        },
        {
          "label": "Ուռուտ գ.",
          "value": "Ուռուտ գ."
        },
        {
          "label": "Ուրասար գ.",
          "value": "Ուրասար գ."
        },
        {
          "label": "Չկալով գ.",
          "value": "Չկալով գ."
        },
        {
          "label": "Պաղաղբյուր գ.",
          "value": "Պաղաղբյուր գ."
        },
        {
          "label": "Պետրովկա գ.",
          "value": "Պետրովկա գ."
        },
        {
          "label": "Պուշկինո գ.",
          "value": "Պուշկինո գ."
        },
        {
          "label": "Պրիվոլնոյե գ.",
          "value": "Պրիվոլնոյե գ."
        },
        {
          "label": "Ջիլիզա գ.",
          "value": "Ջիլիզա գ."
        },
        {
          "label": "Ջրաշեն գ.",
          "value": "Ջրաշեն գ."
        },
        {
          "label": "Սարալանջ գ.",
          "value": "Սարալանջ գ."
        },
        {
          "label": "Սարահարթ գ.",
          "value": "Սարահարթ գ."
        },
        {
          "label": "Սարամեջ գ.",
          "value": "Սարամեջ գ."
        },
        {
          "label": "Սարատովկա գ.",
          "value": "Սարատովկա գ."
        },
        {
          "label": "Սարչապետ գ.",
          "value": "Սարչապետ գ."
        },
        {
          "label": "Սվերդլով գ.",
          "value": "Սվերդլով գ."
        },
        {
          "label": "Վահագնաձոր գ.",
          "value": "Վահագնաձոր գ."
        },
        {
          "label": "Վահագնի գ.",
          "value": "Վահագնի գ."
        },
        {
          "label": "Վարդաբլուր գ.",
          "value": "Վարդաբլուր գ."
        },
        {
          "label": "Փամբակ գ.",
          "value": "Փամբակ գ."
        },
        {
          "label": "Փամբակ կայարան գ.",
          "value": "Փամբակ կայարան գ."
        },
        {
          "label": "Փոքր Այրում գ.",
          "value": "Փոքր Այրում գ."
        },
        {
          "label": "Քարաբերդ գ.",
          "value": "Քարաբերդ գ."
        },
        {
          "label": "Քարաձոր գ.",
          "value": "Քարաձոր գ."
        },
        {
          "label": "Քարինջ գ.",
          "value": "Քարինջ գ."
        },
        {
          "label": "Քարկոփ գ.",
          "value": "Քարկոփ գ."
        },
        {
          "label": "Քիլիսա գ.",
          "value": "Քիլիսա գ."
        },
        {
          "label": "Օձուն գ.",
          "value": "Օձուն գ."
        },
        {
          "label": "Ֆիոլետովո գ.",
          "value": "Ֆիոլետովո գ."
        }
      ]
    },
    {
      city: 'Գյուղեր(Կոտայք)',
      info: [
        {
          "label": "Ալափարս գ.",
          "value": "Ալափարս գ."
        },
        {
          "label": "Ակունք գ.",
          "value": "Ակունք գ."
        },
        {
          "label": "Աղավնաձոր գ.",
          "value": "Աղավնաձոր գ."
        },
        {
          "label": "Առինջ գ.",
          "value": "Առինջ գ."
        },
        {
          "label": "Արագյուղ գ.",
          "value": "Արագյուղ գ."
        },
        {
          "label": "Արամուս գ.",
          "value": "Արամուս գ."
        },
        {
          "label": "Արգել գ.",
          "value": "Արգել գ."
        },
        {
          "label": "Արզական գ.",
          "value": "Արզական գ."
        },
        {
          "label": "Արզնի գ.",
          "value": "Արզնի գ."
        },
        {
          "label": "Արտավազ գ.",
          "value": "Արտավազ գ."
        },
        {
          "label": "Բալահովիտ գ.",
          "value": "Բալահովիտ գ."
        },
        {
          "label": "Բուժական գ.",
          "value": "Բուժական գ."
        },
        {
          "label": "Բջնի գ.",
          "value": "Բջնի գ."
        },
        {
          "label": "Գառնի գ.",
          "value": "Գառնի գ."
        },
        {
          "label": "Գեղադիր գ.",
          "value": "Գեղադիր գ."
        },
        {
          "label": "Գեղաշեն գ.",
          "value": "Գեղաշեն գ."
        },
        {
          "label": "Գեղարդ գ.",
          "value": "Գեղարդ գ."
        },
        {
          "label": "Գետամեջ գ.",
          "value": "Գետամեջ գ."
        },
        {
          "label": "Գետարգել գ.",
          "value": "Գետարգել գ."
        },
        {
          "label": "Գողթ գ.",
          "value": "Գողթ գ."
        },
        {
          "label": "Գոռգոչ գ.",
          "value": "Գոռգոչ գ."
        },
        {
          "label": "Զառ գ.",
          "value": "Զառ գ."
        },
        {
          "label": "Զինավան գ.",
          "value": "Զինավան գ."
        },
        {
          "label": "Զովաշեն գ.",
          "value": "Զովաշեն գ."
        },
        {
          "label": "Զովունի գ.",
          "value": "Զովունի գ."
        },
        {
          "label": "Զովք գ.",
          "value": "Զովք գ."
        },
        {
          "label": "Զորավան գ.",
          "value": "Զորավան գ."
        },
        {
          "label": "Լեռնանիստ գ.",
          "value": "Լեռնանիստ գ."
        },
        {
          "label": "Կաթնաղբյուր գ.",
          "value": "Կաթնաղբյուր գ."
        },
        {
          "label": "Կամարիս գ.",
          "value": "Կամարիս գ."
        },
        {
          "label": "Կապուտան գ.",
          "value": "Կապուտան գ."
        },
        {
          "label": "Կարենիս գ.",
          "value": "Կարենիս գ."
        },
        {
          "label": "Կոտայք գ.",
          "value": "Կոտայք գ."
        },
        {
          "label": "Հանքավան գ.",
          "value": "Հանքավան գ."
        },
        {
          "label": "Հատիս գ.",
          "value": "Հատիս գ."
        },
        {
          "label": "Հացավան գ.",
          "value": "Հացավան գ."
        },
        {
          "label": "Ձորաղբյուր գ.",
          "value": "Ձորաղբյուր գ."
        },
        {
          "label": "Մայակովսի գ.",
          "value": "Մայակովսի գ."
        },
        {
          "label": "Մարմարիկ գ.",
          "value": "Մարմարիկ գ."
        },
        {
          "label": "Մեղրաձոր գ.",
          "value": "Մեղրաձոր գ."
        },
        {
          "label": "Մրգաշեն գ.",
          "value": "Մրգաշեն գ."
        },
        {
          "label": "Նոր Արտամետ գ.",
          "value": "Նոր Արտամետ գ."
        },
        {
          "label": "Նոր Գեղի գ.",
          "value": "Նոր Գեղի գ."
        },
        {
          "label": "Նոր Գյուղ գ.",
          "value": "Նոր Գյուղ գ."
        },
        {
          "label": "Նոր Երզնկա գ.",
          "value": "Նոր Երզնկա գ."
        },
        {
          "label": "Նուռնուս գ.",
          "value": "Նուռնուս գ."
        },
        {
          "label": "Ողջաբերդ գ.",
          "value": "Ողջաբերդ գ."
        },
        {
          "label": "Պռոշյան գ.",
          "value": "Պռոշյան գ."
        },
        {
          "label": "Պտղնի գ.",
          "value": "Պտղնի գ."
        },
        {
          "label": "Ջրաբեր գ.",
          "value": "Ջրաբեր գ."
        },
        {
          "label": "Ջրառատ գ.",
          "value": "Ջրառատ գ."
        },
        {
          "label": "Ջրվեժ գ.",
          "value": "Ջրվեժ գ."
        },
        {
          "label": "Սարալանջ գ.",
          "value": "Սարալանջ գ."
        },
        {
          "label": "Սևաբերդ գ.",
          "value": "Սևաբերդ գ."
        },
        {
          "label": "Սոլակ գ.",
          "value": "Սոլակ գ."
        },
        {
          "label": "Վերին Պտղնի գ.",
          "value": "Վերին Պտղնի գ."
        },
        {
          "label": "Փյունիկ գ.",
          "value": "Փյունիկ գ."
        },
        {
          "label": "Քաղսի գ.",
          "value": "Քաղսի գ."
        },
        {
          "label": "Քանաքեռավան գ.",
          "value": "Քանաքեռավան գ."
        },
        {
          "label": "Քասախ գ.",
          "value": "Քասախ գ."
        }
      ]
    },
    {
      city: 'Գյուղեր(Շիրակ)',
      info: [
        {
          "label": "Ազատան գ.",
          "value": "Ազատան գ."
        },
        {
          "label": "Ալվար գ.",
          "value": "Ալվար գ."
        },
        {
          "label": "Ախուրիկ գ.",
          "value": "Ախուրիկ գ."
        },
        {
          "label": "Ախուրյան գ.",
          "value": "Ախուրյան գ."
        },
        {
          "label": "Ախուրյան կայարան գ.",
          "value": "Ախուրյան կայարան գ."
        },
        {
          "label": "Աղին գ.",
          "value": "Աղին գ."
        },
        {
          "label": "Աղին կայարան գ.",
          "value": "Աղին կայարան գ."
        },
        {
          "label": "Աղվորիկ գ.",
          "value": "Աղվորիկ գ."
        },
        {
          "label": "Ամասիա գ.",
          "value": "Ամասիա գ."
        },
        {
          "label": "Այգեբաց գ.",
          "value": "Այգեբաց գ."
        },
        {
          "label": "Անիավան /Անի կայարան/ գ.",
          "value": "Անիավան /Անի կայարան/ գ."
        },
        {
          "label": "Անիպեմզա գ.",
          "value": "Անիպեմզա գ."
        },
        {
          "label": "Անուշավան գ.",
          "value": "Անուշավան գ."
        },
        {
          "label": "Աշոցք գ.",
          "value": "Աշոցք գ."
        },
        {
          "label": "Առափի գ.",
          "value": "Առափի գ."
        },
        {
          "label": "Արավետ գ.",
          "value": "Արավետ գ."
        },
        {
          "label": "Արդենիս գ.",
          "value": "Արդենիս գ."
        },
        {
          "label": "Արեգնադեմ գ.",
          "value": "Արեգնադեմ գ."
        },
        {
          "label": "Արևիկ գ.",
          "value": "Արևիկ գ."
        },
        {
          "label": "Արևշատ գ.",
          "value": "Արևշատ գ."
        },
        {
          "label": "Արփենի գ.",
          "value": "Արփենի գ."
        },
        {
          "label": "Բագրավան /Խարկով/ գ.",
          "value": "Բագրավան /Խարկով/ գ."
        },
        {
          "label": "Բայանդուր գ.",
          "value": "Բայանդուր գ."
        },
        {
          "label": "Բանդիվան գ.",
          "value": "Բանդիվան գ."
        },
        {
          "label": "Բաշգյուղ գ.",
          "value": "Բաշգյուղ գ."
        },
        {
          "label": "Բասեն գ.",
          "value": "Բասեն գ."
        },
        {
          "label": "Բավրա գ.",
          "value": "Բավրա գ."
        },
        {
          "label": "Բարձրաշեն գ.",
          "value": "Բարձրաշեն գ."
        },
        {
          "label": "Բենիամին գ.",
          "value": "Բենիամին գ."
        },
        {
          "label": "Բերդաշեն գ.",
          "value": "Բերդաշեն գ."
        },
        {
          "label": "Բյուրակն գ.",
          "value": "Բյուրակն գ."
        },
        {
          "label": "Գառնառիճ գ.",
          "value": "Գառնառիճ գ."
        },
        {
          "label": "Գեղանիստ գ.",
          "value": "Գեղանիստ գ."
        },
        {
          "label": "Գետափ գ.",
          "value": "Գետափ գ."
        },
        {
          "label": "Գետք գ.",
          "value": "Գետք գ."
        },
        {
          "label": "Գոգհովիտ գ.",
          "value": "Գոգհովիտ գ."
        },
        {
          "label": "Գուսանագյուղ գ.",
          "value": "Գուսանագյուղ գ."
        },
        {
          "label": "Գտաշեն գ.",
          "value": "Գտաշեն գ."
        },
        {
          "label": "Դարիկ գ.",
          "value": "Դարիկ գ."
        },
        {
          "label": "Եղնաջուր գ.",
          "value": "Եղնաջուր գ."
        },
        {
          "label": "Երազգավորս գ.",
          "value": "Երազգավորս գ."
        },
        {
          "label": "Երիզակ գ.",
          "value": "Երիզակ գ."
        },
        {
          "label": "Զարիշատ գ.",
          "value": "Զարիշատ գ."
        },
        {
          "label": "Զորակերտ գ.",
          "value": "Զորակերտ գ."
        },
        {
          "label": "Զույքաղբյուր գ.",
          "value": "Զույքաղբյուր գ."
        },
        {
          "label": "Թավշուտ գ.",
          "value": "Թավշուտ գ."
        },
        {
          "label": "Թորոսգյուղ գ.",
          "value": "Թորոսգյուղ գ."
        },
        {
          "label": "Իսահակյան գ.",
          "value": "Իսահակյան գ."
        },
        {
          "label": "Լանջիկ գ.",
          "value": "Լանջիկ գ."
        },
        {
          "label": "Լեռնագյուղ գ.",
          "value": "Լեռնագյուղ գ."
        },
        {
          "label": "Լեռնակերտ գ.",
          "value": "Լեռնակերտ գ."
        },
        {
          "label": "Լեռնուտ գ.",
          "value": "Լեռնուտ գ."
        },
        {
          "label": "Լորասար գ.",
          "value": "Լորասար գ."
        },
        {
          "label": "Լուսակերտ գ.",
          "value": "Լուսակերտ գ."
        },
        {
          "label": "Լուսղբյուր գ.",
          "value": "Լուսղբյուր գ."
        },
        {
          "label": "Ծաղկուտ գ.",
          "value": "Ծաղկուտ գ."
        },
        {
          "label": "Կամխուտ գ.",
          "value": "Կամխուտ գ."
        },
        {
          "label": "Կամո գ.",
          "value": "Կամո գ."
        },
        {
          "label": "Կապս գ.",
          "value": "Կապս գ."
        },
        {
          "label": "Կառնուտ գ.",
          "value": "Կառնուտ գ."
        },
        {
          "label": "Կարմրավան գ.",
          "value": "Կարմրավան գ."
        },
        {
          "label": "Կարմրաքար գ.",
          "value": "Կարմրաքար գ."
        },
        {
          "label": "Կաքավասար գ.",
          "value": "Կաքավասար գ."
        },
        {
          "label": "Կրաշեն գ.",
          "value": "Կրաշեն գ."
        },
        {
          "label": "Կրասար գ.",
          "value": "Կրասար գ."
        },
        {
          "label": "Հայկաձոր գ.",
          "value": "Հայկաձոր գ."
        },
        {
          "label": "Հայկասար գ.",
          "value": "Հայկասար գ."
        },
        {
          "label": "Հայկավան գ.",
          "value": "Հայկավան գ."
        },
        {
          "label": "Հայրենյաց գ.",
          "value": "Հայրենյաց գ."
        },
        {
          "label": "Հառիճ գ.",
          "value": "Հառիճ գ."
        },
        {
          "label": "Հարթաշեն գ.",
          "value": "Հարթաշեն գ."
        },
        {
          "label": "Հացիկ գ.",
          "value": "Հացիկ գ."
        },
        {
          "label": "Հացիկավան գ.",
          "value": "Հացիկավան գ."
        },
        {
          "label": "Հողմիկ գ.",
          "value": "Հողմիկ գ."
        },
        {
          "label": "Հոռոմ գ.",
          "value": "Հոռոմ գ."
        },
        {
          "label": "Հովիտ գ.",
          "value": "Հովիտ գ."
        },
        {
          "label": "Հովունի գ.",
          "value": "Հովունի գ."
        },
        {
          "label": "Հովտաշեն գ.",
          "value": "Հովտաշեն գ."
        },
        {
          "label": "Հովտուն գ.",
          "value": "Հովտուն գ."
        },
        {
          "label": "Ձիթհանքով գ.",
          "value": "Ձիթհանքով գ."
        },
        {
          "label": "Ձորակապ գ.",
          "value": "Ձորակապ գ."
        },
        {
          "label": "Ձորաշեն գ.",
          "value": "Ձորաշեն գ."
        },
        {
          "label": "Ղազանչի գ.",
          "value": "Ղազանչի գ."
        },
        {
          "label": "Ղարիբջանյան գ.",
          "value": "Ղարիբջանյան գ."
        },
        {
          "label": "Մայիսյան գ.",
          "value": "Մայիսյան գ."
        },
        {
          "label": "Մարալիկ գ.",
          "value": "Մարալիկ գ."
        },
        {
          "label": "Մարմաշեն գ.",
          "value": "Մարմաշեն գ."
        },
        {
          "label": "Մեծ Մանթաշ գ.",
          "value": "Մեծ Մանթաշ գ."
        },
        {
          "label": "Մեծ Սարիար գ.",
          "value": "Մեծ Սարիար գ."
        },
        {
          "label": "Մեծ Սեպասար գ.",
          "value": "Մեծ Սեպասար գ."
        },
        {
          "label": "Մեղրաշար գ.",
          "value": "Մեղրաշար գ."
        },
        {
          "label": "Մեղրաշեն գ.",
          "value": "Մեղրաշեն գ."
        },
        {
          "label": "Մուսելյան գ.",
          "value": "Մուսելյան գ."
        },
        {
          "label": "Նահապետավան գ.",
          "value": "Նահապետավան գ."
        },
        {
          "label": "Նոր Ախուրյան  գ.",
          "value": "Նոր Ախուրյան  գ."
        },
        {
          "label": "Նոր Կյանք գ.",
          "value": "Նոր Կյանք գ."
        },
        {
          "label": "Նորշեն գ.",
          "value": "Նորշեն գ."
        },
        {
          "label": "Շաղիկ գ.",
          "value": "Շաղիկ գ."
        },
        {
          "label": "Շիրակ գ.",
          "value": "Շիրակ գ."
        },
        {
          "label": "Շիրակավան գ.",
          "value": "Շիրակավան գ."
        },
        {
          "label": "Ողջի գ.",
          "value": "Ողջի գ."
        },
        {
          "label": "Ոսկեհասկ գ.",
          "value": "Ոսկեհասկ գ."
        },
        {
          "label": "Պաղակն գ.",
          "value": "Պաղակն գ."
        },
        {
          "label": "Պեմզաշեն գ.",
          "value": "Պեմզաշեն գ."
        },
        {
          "label": "Ջաջուռ գ.",
          "value": "Ջաջուռ գ."
        },
        {
          "label": "Ջաջուռավան գ.",
          "value": "Ջաջուռավան գ."
        },
        {
          "label": "Ջրաձոր գ.",
          "value": "Ջրաձոր գ."
        },
        {
          "label": "Ջրաջատ գ.",
          "value": "Ջրաջատ գ."
        },
        {
          "label": "Ջրափի գ.",
          "value": "Ջրափի գ."
        },
        {
          "label": "Սալուտ գ.",
          "value": "Սալուտ գ."
        },
        {
          "label": "Սառնաղբյուր գ.",
          "value": "Սառնաղբյուր գ."
        },
        {
          "label": "Սարագյուղ գ.",
          "value": "Սարագյուղ գ."
        },
        {
          "label": "Սարալանջ գ.",
          "value": "Սարալանջ գ."
        },
        {
          "label": "Սարակապ գ.",
          "value": "Սարակապ գ."
        },
        {
          "label": "Սարապատ գ.",
          "value": "Սարապատ գ."
        },
        {
          "label": "Սարատակ գ.",
          "value": "Սարատակ գ."
        },
        {
          "label": "Սիզավետ գ.",
          "value": "Սիզավետ գ."
        },
        {
          "label": "Սպանդարյան գ.",
          "value": "Սպանդարյան գ."
        },
        {
          "label": "Վահրամաբերդ գ.",
          "value": "Վահրամաբերդ գ."
        },
        {
          "label": "Վարդաղբյուր գ.",
          "value": "Վարդաղբյուր գ."
        },
        {
          "label": "Վարդաքար գ.",
          "value": "Վարդաքար գ."
        },
        {
          "label": "Տուֆաշեն գ.",
          "value": "Տուֆաշեն գ."
        },
        {
          "label": "Ցողամարգ գ.",
          "value": "Ցողամարգ գ."
        },
        {
          "label": "Փանիկ գ.",
          "value": "Փանիկ գ."
        },
        {
          "label": "Փոքր Մանթաշ գ.",
          "value": "Փոքր Մանթաշ գ."
        },
        {
          "label": "Փոքր Սարիար գ.",
          "value": "Փոքր Սարիար գ."
        },
        {
          "label": "Փոքր Սեպասար գ.",
          "value": "Փոքր Սեպասար գ."
        },
        {
          "label": "Փոքրաշեն գ.",
          "value": "Փոքրաշեն գ."
        },
        {
          "label": "Քարաբերդ գ.",
          "value": "Քարաբերդ գ."
        },
        {
          "label": "Քեթի գ.",
          "value": "Քեթի գ."
        }
      ]
    },
    {
      city: 'Գյուղեր(Սյունիք)',
      info: [
        {
          "label": "Ագաթանգեղոս գ.",
          "value": "Ագաթանգեղոս գ."
        },
        {
          "label": "Ագարակ գ.",
          "value": "Ագարակ գ."
        },
        {
          "label": "Ալվանք գ.",
          "value": "Ալվանք գ."
        },
        {
          "label": "Ախլաթյան գ.",
          "value": "Ախլաթյան գ."
        },
        {
          "label": "Ակներ գ.",
          "value": "Ակներ գ."
        },
        {
          "label": "Աղավնի գ.",
          "value": "Աղավնի գ."
        },
        {
          "label": "Աղբուլաղ գ.",
          "value": "Աղբուլաղ գ."
        },
        {
          "label": "Աղիտու գ.",
          "value": "Աղիտու գ."
        },
        {
          "label": "Աճանան գ.",
          "value": "Աճանան գ."
        },
        {
          "label": "Այգեձոր գ.",
          "value": "Այգեձոր գ."
        },
        {
          "label": "Անտառաշատ գ.",
          "value": "Անտառաշատ գ."
        },
        {
          "label": "Աշոտավան գ.",
          "value": "Աշոտավան գ."
        },
        {
          "label": "Աջաբաջ գ.",
          "value": "Աջաբաջ գ."
        },
        {
          "label": "Առաջաձոր գ.",
          "value": "Առաջաձոր գ."
        },
        {
          "label": "Արավուս գ.",
          "value": "Արավուս գ."
        },
        {
          "label": "Արևիս գ.",
          "value": "Արևիս գ."
        },
        {
          "label": "Արծվանիկ գ.",
          "value": "Արծվանիկ գ."
        },
        {
          "label": "Արփիկ գ.",
          "value": "Արփիկ գ."
        },
        {
          "label": "Բալաք գ.",
          "value": "Բալաք գ."
        },
        {
          "label": "Բարաբատում գ.",
          "value": "Բարաբատում գ."
        },
        {
          "label": "Բարգուշատ գ.",
          "value": "Բարգուշատ գ."
        },
        {
          "label": "Բարձրավան գ.",
          "value": "Բարձրավան գ."
        },
        {
          "label": "Բնունիս գ.",
          "value": "Բնունիս գ."
        },
        {
          "label": "Բռնակոթ գ.",
          "value": "Բռնակոթ գ."
        },
        {
          "label": "Գեղանուշ գ.",
          "value": "Գեղանուշ գ."
        },
        {
          "label": "Գեղավանք գ.",
          "value": "Գեղավանք գ."
        },
        {
          "label": "Գեղի գ.",
          "value": "Գեղի գ."
        },
        {
          "label": "Գետաթաղ գ.",
          "value": "Գետաթաղ գ."
        },
        {
          "label": "Գետիշեն գ.",
          "value": "Գետիշեն գ."
        },
        {
          "label": "Գոմարան գ.",
          "value": "Գոմարան գ."
        },
        {
          "label": "Գորայք գ.",
          "value": "Գորայք գ."
        },
        {
          "label": "Գուդեմսիս գ.",
          "value": "Գուդեմսիս գ."
        },
        {
          "label": "Դաստակերտ գ.",
          "value": "Դաստակերտ գ."
        },
        {
          "label": "Դավիթ Բեկ գ.",
          "value": "Դավիթ Բեկ գ."
        },
        {
          "label": "Դարբաս գ.",
          "value": "Դարբաս գ."
        },
        {
          "label": "Դիցմայրի գ.",
          "value": "Դիցմայրի գ."
        },
        {
          "label": "Եղեք գ.",
          "value": "Եղեք գ."
        },
        {
          "label": "Եղվարդ գ.",
          "value": "Եղվարդ գ."
        },
        {
          "label": "Թանահան գ.",
          "value": "Թանահան գ."
        },
        {
          "label": "Թասիկ գ.",
          "value": "Թասիկ գ."
        },
        {
          "label": "Իշխանասար գ.",
          "value": "Իշխանասար գ."
        },
        {
          "label": "Լեհվազ գ.",
          "value": "Լեհվազ գ."
        },
        {
          "label": "Լեռնաձոր գ.",
          "value": "Լեռնաձոր գ."
        },
        {
          "label": "Լիճք գ.",
          "value": "Լիճք գ."
        },
        {
          "label": "Լծեն գ.",
          "value": "Լծեն գ."
        },
        {
          "label": "Լոր գ.",
          "value": "Լոր գ."
        },
        {
          "label": "Խալաջ գ.",
          "value": "Խալաջ գ."
        },
        {
          "label": "Խդրանց գ.",
          "value": "Խդրանց գ."
        },
        {
          "label": "Խնածախ գ.",
          "value": "Խնածախ գ."
        },
        {
          "label": "Խնձորեսկ գ.",
          "value": "Խնձորեսկ գ."
        },
        {
          "label": "Խոզավար գ.",
          "value": "Խոզավար գ."
        },
        {
          "label": "Խոտ գ.",
          "value": "Խոտ գ."
        },
        {
          "label": "Խորձոր գ.",
          "value": "Խորձոր գ."
        },
        {
          "label": "Ծավ գ.",
          "value": "Ծավ գ."
        },
        {
          "label": "Ծղուկ գ.",
          "value": "Ծղուկ գ."
        },
        {
          "label": "Կաղնուտ գ.",
          "value": "Կաղնուտ գ."
        },
        {
          "label": "Կավճուտ գ.",
          "value": "Կավճուտ գ."
        },
        {
          "label": "Կարդ գ.",
          "value": "Կարդ գ."
        },
        {
          "label": "Կարճևան գ.",
          "value": "Կարճևան գ."
        },
        {
          "label": "Կիցք /Քիրիս/ գ.",
          "value": "Կիցք /Քիրիս/ գ."
        },
        {
          "label": "Կոռնիձոր գ.",
          "value": "Կոռնիձոր գ."
        },
        {
          "label": "Կուրիս գ.",
          "value": "Կուրիս գ."
        },
        {
          "label": "Հալիձոր գ.",
          "value": "Հալիձոր գ."
        },
        {
          "label": "Հարթաշեն գ.",
          "value": "Հարթաշեն գ."
        },
        {
          "label": "Հարժիս գ.",
          "value": "Հարժիս գ."
        },
        {
          "label": "Հացավան գ.",
          "value": "Հացավան գ."
        },
        {
          "label": "Ձագեձոր գ.",
          "value": "Ձագեձոր գ."
        },
        {
          "label": "Ձորակ գ.",
          "value": "Ձորակ գ."
        },
        {
          "label": "Ձորաստան գ.",
          "value": "Ձորաստան գ."
        },
        {
          "label": "Ճակատեն գ.",
          "value": "Ճակատեն գ."
        },
        {
          "label": "Մուսալլամ գ.",
          "value": "Մուսալլամ գ."
        },
        {
          "label": "Մուցք գ.",
          "value": "Մուցք գ."
        },
        {
          "label": "Ներքին Գիրաթաղ գ.",
          "value": "Ներքին Գիրաթաղ գ."
        },
        {
          "label": "Ներքին Խնձորեսկ գ.",
          "value": "Ներքին Խնձորեսկ գ."
        },
        {
          "label": "Ներքին Խոտանան գ.",
          "value": "Ներքին Խոտանան գ."
        },
        {
          "label": "Ներքին Հանդ գ.",
          "value": "Ներքին Հանդ գ."
        },
        {
          "label": "Նժդեհ /Սոֆլու/ գ.",
          "value": "Նժդեհ /Սոֆլու/ գ."
        },
        {
          "label": "Նոր Աստղաբերդ /Փայահան/ գ.",
          "value": "Նոր Աստղաբերդ /Փայահան/ գ."
        },
        {
          "label": "Նորաշեկ գ.",
          "value": "Նորաշեկ գ."
        },
        {
          "label": "Նորավան գ.",
          "value": "Նորավան գ."
        },
        {
          "label": "Նռնաձոր գ.",
          "value": "Նռնաձոր գ."
        },
        {
          "label": "Շաղատ գ.",
          "value": "Շաղատ գ."
        },
        {
          "label": "Շամբ գ.",
          "value": "Շամբ գ."
        },
        {
          "label": "Շաքի գ.",
          "value": "Շաքի գ."
        },
        {
          "label": "Շենաթաղ գ.",
          "value": "Շենաթաղ գ."
        },
        {
          "label": "Շիկահող գ.",
          "value": "Շիկահող գ."
        },
        {
          "label": "Շինուհայր գ.",
          "value": "Շինուհայր գ."
        },
        {
          "label": "Շիշկերտ գ.",
          "value": "Շիշկերտ գ."
        },
        {
          "label": "Շղարշիկ գ.",
          "value": "Շղարշիկ գ."
        },
        {
          "label": "Շղարշիկ գ.",
          "value": "Շղարշիկ գ."
        },
        {
          "label": "Շուռնուխ գ.",
          "value": "Շուռնուխ գ."
        },
        {
          "label": "Շվանիձոր գ.",
          "value": "Շվանիձոր գ."
        },
        {
          "label": "Շրվենանց գ.",
          "value": "Շրվենանց գ."
        },
        {
          "label": "Ոչեթի /Չայքենդ/ գ.",
          "value": "Ոչեթի /Չայքենդ/ գ."
        },
        {
          "label": "Որոտան /Գոր/ գ.",
          "value": "Որոտան /Գոր/ գ."
        },
        {
          "label": "Ուժանիս գ.",
          "value": "Ուժանիս գ."
        },
        {
          "label": "Ույծ գ.",
          "value": "Ույծ գ."
        },
        {
          "label": "Չափնի գ.",
          "value": "Չափնի գ."
        },
        {
          "label": "Սալվարդ գ.",
          "value": "Սալվարդ գ."
        },
        {
          "label": "Սառնակունք գ.",
          "value": "Սառնակունք գ."
        },
        {
          "label": "Սևաքար գ.",
          "value": "Սևաքար գ."
        },
        {
          "label": "Սզնակ /Ն. Գյոդակլու/ գ.",
          "value": "Սզնակ /Ն. Գյոդակլու/ գ."
        },
        {
          "label": "Սյունիք գ.",
          "value": "Սյունիք գ."
        },
        {
          "label": "Սրաշեն գ.",
          "value": "Սրաշեն գ."
        },
        {
          "label": "Վահրավար գ.",
          "value": "Վահրավար գ."
        },
        {
          "label": "Վաղատին գ.",
          "value": "Վաղատին գ."
        },
        {
          "label": "Վաղատուր գ.",
          "value": "Վաղատուր գ."
        },
        {
          "label": "Վանանդ գ.",
          "value": "Վանանդ գ."
        },
        {
          "label": "Վանեք գ.",
          "value": "Վանեք գ."
        },
        {
          "label": "Վարդանիձոր գ.",
          "value": "Վարդանիձոր գ."
        },
        {
          "label": "Վարդավանք գ.",
          "value": "Վարդավանք գ."
        },
        {
          "label": "Տաթև գ.",
          "value": "Տաթև գ."
        },
        {
          "label": "Տանձատափ գ.",
          "value": "Տանձատափ գ."
        },
        {
          "label": "Տանձևեր գ.",
          "value": "Տանձևեր գ."
        },
        {
          "label": "Տաշտուն գ.",
          "value": "Տաշտուն գ."
        },
        {
          "label": "Տավրոս գ.",
          "value": "Տավրոս գ."
        },
        {
          "label": "Տեղ գ.",
          "value": "Տեղ գ."
        },
        {
          "label": "Տոլորս գ.",
          "value": "Տոլորս գ."
        },
        {
          "label": "Տորունիք գ.",
          "value": "Տորունիք գ."
        },
        {
          "label": "Ցղունի գ.",
          "value": "Ցղունի գ."
        },
        {
          "label": "Քաշունի գ.",
          "value": "Քաշունի գ."
        },
        {
          "label": "Քաջարան գ.",
          "value": "Քաջարան գ."
        },
        {
          "label": "Քարահունջ գ.",
          "value": "Քարահունջ գ."
        },
        {
          "label": "Քարաշեն գ.",
          "value": "Քարաշեն գ."
        },
        {
          "label": "Քարուտ գ.",
          "value": "Քարուտ գ."
        },
        {
          "label": "Օխտար գ.",
          "value": "Օխտար գ."
        }
      ]
    },
    {
      city: 'Գյուղեր(Վայոց Ձոր)',
      info: [
        {
          "label": "Ագարակաձոր գ.",
          "value": "Ագարակաձոր գ."
        },
        {
          "label": "Ազատեկ գ.",
          "value": "Ազատեկ գ."
        },
        {
          "label": "Ախտա գ.",
          "value": "Ախտա գ."
        },
        {
          "label": "Աղավնաձոր գ.",
          "value": "Աղավնաձոր գ."
        },
        {
          "label": "Աղեգիս գ.",
          "value": "Աղեգիս գ."
        },
        {
          "label": "Աղնջաձոր գ.",
          "value": "Աղնջաձոր գ."
        },
        {
          "label": "Ամաղու գ.",
          "value": "Ամաղու գ."
        },
        {
          "label": "Արատես գ.",
          "value": "Արատես գ."
        },
        {
          "label": "Արենի գ.",
          "value": "Արենի գ."
        },
        {
          "label": "Արին գ.",
          "value": "Արին գ."
        },
        {
          "label": "Արտաբույնք գ.",
          "value": "Արտաբույնք գ."
        },
        {
          "label": "Արտավան գ.",
          "value": "Արտավան գ."
        },
        {
          "label": "Արփի գ.",
          "value": "Արփի գ."
        },
        {
          "label": "Բարձրունի գ.",
          "value": "Բարձրունի գ."
        },
        {
          "label": "Գետափ գ.",
          "value": "Գետափ գ."
        },
        {
          "label": "Գետիկվանք գ.",
          "value": "Գետիկվանք գ."
        },
        {
          "label": "Գլաձոր գ.",
          "value": "Գլաձոր գ."
        },
        {
          "label": "Գնդեվազ գ.",
          "value": "Գնդեվազ գ."
        },
        {
          "label": "Գնիշիկ գ.",
          "value": "Գնիշիկ գ."
        },
        {
          "label": "Գողթանիկ գ.",
          "value": "Գողթանիկ գ."
        },
        {
          "label": "Գոմք գ.",
          "value": "Գոմք գ."
        },
        {
          "label": "Ելփին գ.",
          "value": "Ելփին գ."
        },
        {
          "label": "Զառիթափ գ.",
          "value": "Զառիթափ գ."
        },
        {
          "label": "Զեդեա գ.",
          "value": "Զեդեա գ."
        },
        {
          "label": "Թառաթումբ գ.",
          "value": "Թառաթումբ գ."
        },
        {
          "label": "Խաչիկ գ.",
          "value": "Խաչիկ գ."
        },
        {
          "label": "Խնձորուտ գ.",
          "value": "Խնձորուտ գ."
        },
        {
          "label": "Կալասար գ.",
          "value": "Կալասար գ."
        },
        {
          "label": "Կապույտ գ.",
          "value": "Կապույտ գ."
        },
        {
          "label": "Կարմրաշեն գ.",
          "value": "Կարմրաշեն գ."
        },
        {
          "label": "Կեչուտ գ.",
          "value": "Կեչուտ գ."
        },
        {
          "label": "Հերհեր գ.",
          "value": "Հերհեր գ."
        },
        {
          "label": "Հերմոն գ.",
          "value": "Հերմոն գ."
        },
        {
          "label": "Հորադիս գ.",
          "value": "Հորադիս գ."
        },
        {
          "label": "Հորբատեղ գ.",
          "value": "Հորբատեղ գ."
        },
        {
          "label": "Հորս գ.",
          "value": "Հորս գ."
        },
        {
          "label": "Մալիշկա գ.",
          "value": "Մալիշկա գ."
        },
        {
          "label": "Մարտիրոս գ.",
          "value": "Մարտիրոս գ."
        },
        {
          "label": "Նոր Ազնաբերդ գ.",
          "value": "Նոր Ազնաբերդ գ."
        },
        {
          "label": "Շատին գ.",
          "value": "Շատին գ."
        },
        {
          "label": "Չիվա գ.",
          "value": "Չիվա գ."
        },
        {
          "label": "Ռինդ գ.",
          "value": "Ռինդ գ."
        },
        {
          "label": "Սալլի գ.",
          "value": "Սալլի գ."
        },
        {
          "label": "Սարավան գ.",
          "value": "Սարավան գ."
        },
        {
          "label": "Սերս գ.",
          "value": "Սերս գ."
        },
        {
          "label": "Վարդահովիտ գ.",
          "value": "Վարդահովիտ գ."
        },
        {
          "label": "Սևաժայռ գ.",
          "value": "Սևաժայռ գ."
        },
        {
          "label": "Վերնաշեն գ.",
          "value": "Վերնաշեն գ."
        },
        {
          "label": "Փոռ գ.",
          "value": "Փոռ գ."
        },
        {
          "label": "Քարագլուխ գ.",
          "value": "Քարագլուխ գ."
        }
      ]
    },
    {
      city: 'Գյուղեր(Տավուշ)',
      info: [
        {
          "label": "Ազատանուտ գ.",
          "value": "Ազատանուտ գ."
        },
        {
          "label": "Ակնաղբյուր գ.",
          "value": "Ակնաղբյուր գ."
        },
        {
          "label": "Աղավնավանք գ.",
          "value": "Աղավնավանք գ."
        },
        {
          "label": "Աճարկուտ գ.",
          "value": "Աճարկուտ գ."
        },
        {
          "label": "Այգեհովիտ գ.",
          "value": "Այգեհովիտ գ."
        },
        {
          "label": "Այգեձոր գ.",
          "value": "Այգեձոր գ."
        },
        {
          "label": "Այգեպար գ.",
          "value": "Այգեպար գ."
        },
        {
          "label": "Աչաջուր գ.",
          "value": "Աչաջուր գ."
        },
        {
          "label": "Արծվաբերդ գ.",
          "value": "Արծվաբերդ գ."
        },
        {
          "label": "Արճիս գ.",
          "value": "Արճիս գ."
        },
        {
          "label": "Բագրատաշեն գ.",
          "value": "Բագրատաշեն գ."
        },
        {
          "label": "Բաղանիս գ.",
          "value": "Բաղանիս գ."
        },
        {
          "label": "Բարեկամավան գ.",
          "value": "Բարեկամավան գ."
        },
        {
          "label": "Բերդավան գ.",
          "value": "Բերդավան գ."
        },
        {
          "label": "Բերքաբեր գ.",
          "value": "Բերքաբեր գ."
        },
        {
          "label": "Գանձատափ գ.",
          "value": "Գանձատափ գ."
        },
        {
          "label": "Գանձաքար գ.",
          "value": "Գանձաքար գ."
        },
        {
          "label": "Գետահովիտ գ.",
          "value": "Գետահովիտ գ."
        },
        {
          "label": "Գոշ գ.",
          "value": "Գոշ գ."
        },
        {
          "label": "Դեբեդավան գ.",
          "value": "Դեբեդավան գ."
        },
        {
          "label": "Դեղձավան գ.",
          "value": "Դեղձավան գ."
        },
        {
          "label": "Դիտավան գ.",
          "value": "Դիտավան գ."
        },
        {
          "label": "Դովեղ գ.",
          "value": "Դովեղ գ."
        },
        {
          "label": "Ենոքավան գ.",
          "value": "Ենոքավան գ."
        },
        {
          "label": "Զորական գ.",
          "value": "Զորական գ."
        },
        {
          "label": "Թեղուտ գ.",
          "value": "Թեղուտ գ."
        },
        {
          "label": "Իծաքար գ.",
          "value": "Իծաքար գ."
        },
        {
          "label": "Լճկաձոր գ.",
          "value": "Լճկաձոր գ."
        },
        {
          "label": "Լուսահովիտ գ.",
          "value": "Լուսահովիտ գ."
        },
        {
          "label": "Լուսաձոր գ.",
          "value": "Լուսաձոր գ."
        },
        {
          "label": "Խաշթառակ գ.",
          "value": "Խաշթառակ գ."
        },
        {
          "label": "Խաչարձան գ.",
          "value": "Խաչարձան գ."
        },
        {
          "label": "Ծաղկավան գ.",
          "value": "Ծաղկավան գ."
        },
        {
          "label": "Կիրանց գ.",
          "value": "Կիրանց գ."
        },
        {
          "label": "Կոթի գ.",
          "value": "Կոթի գ."
        },
        {
          "label": "Կողբ գ.",
          "value": "Կողբ գ."
        },
        {
          "label": "Հաղարծին գ.",
          "value": "Հաղարծին գ."
        },
        {
          "label": "Հաղթանակ գ.",
          "value": "Հաղթանակ գ."
        },
        {
          "label": "Հովք գ.",
          "value": "Հովք գ."
        },
        {
          "label": "Ճերմակավան գ.",
          "value": "Ճերմակավան գ."
        },
        {
          "label": "Մովսեսգյուղ գ.",
          "value": "Մովսեսգյուղ գ."
        },
        {
          "label": "Նավուր գ.",
          "value": "Նավուր գ."
        },
        {
          "label": "Ներքին Կարմրաղբյուր գ.",
          "value": "Ներքին Կարմրաղբյուր գ."
        },
        {
          "label": "Նորաշեն գ.",
          "value": "Նորաշեն գ."
        },
        {
          "label": "Ոսկեպար գ.",
          "value": "Ոսկեպար գ."
        },
        {
          "label": "Ոսկեվան գ.",
          "value": "Ոսկեվան գ."
        },
        {
          "label": "Չինարի գ.",
          "value": "Չինարի գ."
        },
        {
          "label": "Չինչին գ.",
          "value": "Չինչին գ."
        },
        {
          "label": "Չորաթան գ.",
          "value": "Չորաթան գ."
        },
        {
          "label": "Պառավաքար գ.",
          "value": "Պառավաքար գ."
        },
        {
          "label": "Պտղավան գ.",
          "value": "Պտղավան գ."
        },
        {
          "label": "Ջուջևան գ.",
          "value": "Ջուջևան գ."
        },
        {
          "label": "Սարիգյուղ գ.",
          "value": "Սարիգյուղ գ."
        },
        {
          "label": "Սևքար գ.",
          "value": "Սևքար գ."
        },
        {
          "label": "Վազաշեն գ.",
          "value": "Վազաշեն գ."
        },
        {
          "label": "Վարագավան գ.",
          "value": "Վարագավան գ."
        },
        {
          "label": "Վերին Կարմրաղբյուր գ.",
          "value": "Վերին Կարմրաղբյուր գ."
        }
      ]
    }
  ];

  streets = [
    {
      communities: 'Աջափնյակ',
      info: [
        {
          "label": "Աբելյան փող.",
          "value": "Աբելյան փող."
          // value: 'Աբելյան, Yerevan, Armenia'
        },
        {
          "label": "Ալիխանյան եղբայրներ նրբ.",
          "value": "Ալիխանյան եղբայրներ նրբ."
        },
        {
          "label": "Ալիխանյան եղբայրներ փող.",
          "value": "Ալիխանյան եղբայրներ փող."
          // value: 'Ի. Ալիխանյան, Yerevan, Armenia'
        },
        {
          "label": "Աշտարակի խճ.",
          "value": "Աշտարակի խճ."
        },
        {
          "label": "Աջափնյակ 15 թաղ.",
          "value": "Աջափնյակ 15 թաղ."
        },
        {
          "label": "Աջափնյակ 16 թաղ.",
          "value": "Աջափնյակ 16 թաղ."
        },
        {
          "label": "Առաքելյան փող.",
          "value": "Առաքելյան փող."
        },
        {
          "label": "Արզումանյան փող.",
          "value": "Արզումանյան փող."
        },
        {
          "label": "Բաշինջաղյան 1 նրբ.",
          "value": "Բաշինջաղյան 1 նրբ."
        },
        {
          "label": "Բաշինջաղյան 1 փող.",
          "value": "Բաշինջաղյան 1 փող."
        },
        {
          "label": "Բաշինջաղյան 2 նրբ.",
          "value": "Բաշինջաղյան 2 նրբ."
        },
        {
          "label": "Բաշինջաղյան փակ.",
          "value": "Բաշինջաղյան փակ."
        },
        {
          "label": "Բաշինջաղյան փող.",
          "value": "Բաշինջաղյան փող."
        },
        {
          "label": "Բեկնազարյան փող.",
          "value": "Բեկնազարյան փող."
        },
        {
          "label": "Գևորգ Չաուշ փող.",
          "value": "Գևորգ Չաուշ փող."
        },
        {
          "label": "Եղիազարյան փող.",
          "value": "Եղիազարյան փող."
        },
        {
          "label": "Զոհրապ փող.",
          "value": "Զոհրապ փող."
        },
        {
          "label": "Էստոնական փող.",
          "value": "Էստոնական փող."
        },
        {
          "label": "Թերլեմեզյան փող.",
          "value": "Թերլեմեզյան փող."
        },
        {
          "label": "Լենինգրադյան փող.",
          "value": "Լենինգրադյան փող."
        },
        {
          "label": "Լևոնյան փող.",
          "value": "Լևոնյան փող."
        },
        {
          "label": "Լուկաշին 1 փող.",
          "value": "Լուկաշին 1 փող."
        },
        {
          "label": "Լուկաշին 2 փող.",
          "value": "Լուկաշին 2 փող."
        },
        {
          "label": "Լուկաշին 3 փող.",
          "value": "Լուկաշին 3 փող."
        },
        {
          "label": "Լուկաշին փող.",
          "value": "Լուկաշին փող."
        },
        {
          "label": "Լուկաշին փող. 2-րդ նրբ.",
          "value": "Լուկաշին փող. 2-րդ նրբ."
        },
        {
          "label": "Խ. Խաչատրյան 1 նրբ.",
          "value": "Խ. Խաչատրյան 1 նրբ."
        },
        {
          "label": "Խ. Խաչատրյան 2 նրբ.",
          "value": "Խ. Խաչատրյան 2 նրբ."
        },
        {
          "label": "Խ. Խաչատրյան փող.",
          "value": "Խ. Խաչատրյան փող."
        },
        {
          "label": "Կարապետյան փող.",
          "value": "Կարապետյան փող."
        },
        {
          "label": "Հ/Ա Գ-1 թաղ.",
          "value": "Հ/Ա Գ-1 թաղ."
        },
        {
          "label": "Հ/Ա Գ-3 թաղ.",
          "value": "Հ/Ա Գ-3 թաղ."
        },
        {
          "label": "Հալաբյան փող.",
          "value": "Հալաբյան փող."
        },
        {
          "label": "Հակոբյանց փող.",
          "value": "Հակոբյանց փող."
        },
        {
          "label": "Հաղթանակ 1 փող.",
          "value": "Հաղթանակ 1 փող."
        },
        {
          "label": "Հաղթանակ թաղ.",
          "value": "Հաղթանակ թաղ."
        },
        {
          "label": "Հասմիկ փող.",
          "value": "Հասմիկ փող."
        },
        {
          "label": "Հասրաթյան/Լենինական/ նրբ.",
          "value": "Հասրաթյան/Լենինական/ նրբ."
        },
        {
          "label": "Հասրաթյան/Լենինական/ փող.",
          "value": "Հասրաթյան/Լենինական/ փող."
        },
        {
          "label": "Մազմանյան փող.",
          "value": "Մազմանյան փող."
        },
        {
          "label": "Մարգարյան 1 նրբ.",
          "value": "Մարգարյան 1 նրբ."
        },
        {
          "label": "Մարգարյան 2 նրբ.",
          "value": "Մարգարյան 2 նրբ."
        },
        {
          "label": "Մարգարյան փող.",
          "value": "Մարգարյան փող."
        },
        {
          "label": "Մարտիրոսյան փող.",
          "value": "Մարտիրոսյան փող."
        },
        {
          "label": "Մելքումով փող.",
          "value": "Մելքումով փող."
        },
        {
          "label": "Միկոյան փող.",
          "value": "Միկոյան փող."
        },
        {
          "label": "Միրաքյան նրբ.",
          "value": "Միրաքյան նրբ."
        },
        {
          "label": "Միրաքյան փող.",
          "value": "Միրաքյան փող."
        },
        {
          "label": "Ն. Շնորհալի փող.",
          "value": "Ն. Շնորհալի փող."
        },
        {
          "label": "Նազարբեկյան թաղ. /17 թաղ./",
          "value": "Նազարբեկյան թաղ. /17 թաղ./"
        },
        {
          "label": "Նորաշեն թաղ.",
          "value": "Նորաշեն թաղ."
        },
        {
          "label": "Շահումյան 1 փող.",
          "value": "Շահումյան 1 փող."
        },
        {
          "label": "Շինարարներ փող.",
          "value": "Շինարարներ փող."
        },
        {
          "label": "Շիրազ փող.",
          "value": "Շիրազ փող."
        },
        {
          "label": "Չուխաջյան",
          "value": "Չուխաջյան"
        },
        {
          "label": "Պարսեղով փող.",
          "value": "Պարսեղով փող."
        },
        {
          "label": "Ջանիբեկյան փող.",
          "value": "Ջանիբեկյան փող."
        },
        {
          "label": "Ռ. Մելիքյան փող.",
          "value": "Ռ. Մելիքյան փող."
        },
        {
          "label": "Ռուստամյան փող.",
          "value": "Ռուստամյան փող."
        },
        {
          "label": "Սարգսյան փող.",
          "value": "Սարգսյան փող."
        },
        {
          "label": "Սեբաստիա փող.",
          "value": "Սեբաստիա փող."
        },
        {
          "label": "Սիլիկյան թաղ.",
          "value": "Սիլիկյան թաղ."
        },
        {
          "label": "Սիլիկյան թաղ. 1 փող.",
          "value": "Սիլիկյան թաղ. 1 փող."
        },
        {
          "label": "Սիլիկյան թաղ. 10 փող.",
          "value": "Սիլիկյան թաղ. 10 փող."
        },
        {
          "label": "Սիլիկյան թաղ. 11 փող.",
          "value": "Սիլիկյան թաղ. 11 փող."
        },
        {
          "label": "Սիլիկյան թաղ. 12 փող.",
          "value": "Սիլիկյան թաղ. 12 փող."
        },
        {
          "label": "Սիլիկյան թաղ. 13 փող.",
          "value": "Սիլիկյան թաղ. 13 փող."
        },
        {
          "label": "Սիլիկյան թաղ. 14 փող.",
          "value": "Սիլիկյան թաղ. 14 փող."
        },
        {
          "label": "Սիլիկյան թաղ. 2 փող.",
          "value": "Սիլիկյան թաղ. 2 փող."
        },
        {
          "label": "Սիլիկյան թաղ. 3 փող.",
          "value": "Սիլիկյան թաղ. 3 փող."
        },
        {
          "label": "Սիլիկյան թաղ. 4 փող.",
          "value": "Սիլիկյան թաղ. 4 փող."
        },
        {
          "label": "Սիլիկյան թաղ. 5 փող.",
          "value": "Սիլիկյան թաղ. 5 փող."
        },
        {
          "label": "Սիլիկյան թաղ. 6 փող.",
          "value": "Սիլիկյան թաղ. 6 փող."
        },
        {
          "label": "Սիլիկյան թաղ. 7 փող.",
          "value": "Սիլիկյան թաղ. 7 փող."
        },
        {
          "label": "Սիլիկյան թաղ. 8 փող.",
          "value": "Սիլիկյան թաղ. 8 փող."
        },
        {
          "label": "Սիլիկյան թաղ. 9 փող.",
          "value": "Սիլիկյան թաղ. 9 փող."
        },
        {
          "label": "Սիլիկյան հին խճ.",
          "value": "Սիլիկյան հին խճ."
        },
        {
          "label": "Սիլիկյան նոր խճ.",
          "value": "Սիլիկյան նոր խճ."
        },
        {
          "label": "Սիսակյան փող.",
          "value": "Սիսակյան փող."
        },
        {
          "label": "Սպանդարյան թաղ. 5 փող.",
          "value": "Սպանդարյան թաղ. 5 փող."
        },
        {
          "label": "Սպորտ փող.",
          "value": "Սպորտ փող."
        },
        {
          "label": "Վահագնի թաղ",
          "value": "Վահագնի թաղ"
        },
        {
          "label": "Վահագնի թաղ., Արա -Լեռ փող.",
          "value": "Վահագնի թաղ., Արա -Լեռ փող."
        },
        {
          "label": "Վահագնի թաղ., Հատիսի փող.",
          "value": "Վահագնի թաղ., Հատիսի փող."
        },
        {
          "label": "Վահագնի թաղ., Սիփանի փող.",
          "value": "Վահագնի թաղ., Սիփանի փող."
        },
        {
          "label": "Վշտունի 1 նրբ.",
          "value": "Վշտունի 1 նրբ."
        },
        {
          "label": "Վշտունի 2 նրբ.",
          "value": "Վշտունի 2 նրբ."
        },
        {
          "label": "Վշտունի 3 նրբ.",
          "value": "Վշտունի 3 նրբ."
        },
        {
          "label": "Վշտունի 4 նրբ.",
          "value": "Վշտունի 4 նրբ."
        },
        {
          "label": "Վշտունի փող.",
          "value": "Վշտունի փող."
        },
        {
          "label": "Վշտունու 5 նրբ.",
          "value": "Վշտունու 5 նրբ."
        },
        {
          "label": "Տիչինայի 3-րդ նրբ.",
          "value": "Տիչինայի 3-րդ նրբ."
        },
        {
          "label": "Տիչինայի փող.",
          "value": "Տիչինայի փող."
        },
        {
          "label": "Ֆուչիկ 1 նրբ.",
          "value": "Ֆուչիկ 1 նրբ."
        },
        {
          "label": "Ֆուչիկ 2 նրբ.",
          "value": "Ֆուչիկ 2 նրբ."
        },
        {
          "label": "Ֆուչիկ փող.",
          "value": "Ֆուչիկ փող."
        }
      ]
    },
    {
      communities: 'Ավան',
      info: [
        {
          "label": "Ա. Շահինյան 1 նրբ.",
          "value": "Ա. Շահինյան 1 նրբ."
        },
        {
          "label": "Ա. Շահինյան 2 նրբ.",
          "value": "Ա. Շահինյան 2 նրբ."
        },
        {
          "label": "Ա. Շահինյան 3 նրբ.",
          "value": "Ա. Շահինյան 3 նրբ."
        },
        {
          "label": "Ա. Շահինյան 4 նրբ.",
          "value": "Ա. Շահինյան 4 նրբ."
        },
        {
          "label": "Ա. Շահինյան 5 նրբ.",
          "value": "Ա. Շահինյան 5 նրբ."
        },
        {
          "label": "Ա. Շահինյան փող.",
          "value": "Ա. Շահինյան փող."
        },
        {
          "label": "Աբովյան թաղ.",
          "value": "Աբովյան թաղ."
        },
        {
          "label": "Ալմա-Աթա փող.",
          "value": "Ալմա-Աթա փող."
        },
        {
          "label": "Աճառյան 1 փակ.",
          "value": "Աճառյան 1 փակ."
        },
        {
          "label": "Աճառյան 2 փակ.",
          "value": "Աճառյան 2 փակ."
        },
        {
          "label": "Աճառյան փող.",
          "value": "Աճառյան փող."
        },
        {
          "label": "Աշխաբադի փող.",
          "value": "Աշխաբադի փող."
        },
        {
          "label": "Ավան 10 փող.",
          "value": "Ավան 10 փող."
        },
        {
          "label": "Ավան 12 փող.",
          "value": "Ավան 12 փող."
        },
        {
          "label": "Ավան 14 փող.",
          "value": "Ավան 14 փող."
        },
        {
          "label": "Ավան 3 փող.",
          "value": "Ավան 3 փող."
        },
        {
          "label": "Ավան 4 փող.",
          "value": "Ավան 4 փող."
        },
        {
          "label": "Ավան 4 փող. 1 նրբ.",
          "value": "Ավան 4 փող. 1 նրբ."
        },
        {
          "label": "Ավան 4 փող. 2 նրբ.",
          "value": "Ավան 4 փող. 2 նրբ."
        },
        {
          "label": "Ավան 4 փող. 3 նրբ.",
          "value": "Ավան 4 փող. 3 նրբ."
        },
        {
          "label": "Ավան 4 փող. 4 նրբ.",
          "value": "Ավան 4 փող. 4 նրբ."
        },
        {
          "label": "Ավան 5 փող.",
          "value": "Ավան 5 փող."
        },
        {
          "label": "Ավան 5 փող. 1 նրբ.",
          "value": "Ավան 5 փող. 1 նրբ."
        },
        {
          "label": "Ավան 6 փող.",
          "value": "Ավան 6 փող."
        },
        {
          "label": "Ավան 6 փող. 1 նրբ.",
          "value": "Ավան 6 փող. 1 նրբ."
        },
        {
          "label": "Ավան 7 փող.",
          "value": "Ավան 7 փող."
        },
        {
          "label": "Ավան 8 փող.",
          "value": "Ավան 8 փող."
        },
        {
          "label": "Ավան -Առինջ 1 միկրո.",
          "value": "Ավան -Առինջ 1 միկրո."
        },
        {
          "label": "Ավան -Առինջ 2 միկրո.",
          "value": "Ավան -Առինջ 2 միկրո."
        },
        {
          "label": "Ավան-Առինջ թաղ.",
          "value": "Ավան-Առինջ թաղ."
        },
        {
          "label": "Բագրևանդ փող.",
          "value": "Բագրևանդ փող."
        },
        {
          "label": "Բուսաբանական փող.",
          "value": "Բուսաբանական փող."
        },
        {
          "label": "Բրյուսով թաղ.",
          "value": "Բրյուսով թաղ."
        },
        {
          "label": "Դ. Մալյան նրբ.",
          "value": "Դ. Մալյան նրբ."
        },
        {
          "label": "Դ. Մալյան փող.",
          "value": "Դ. Մալյան փող."
        },
        {
          "label": "Դուշանբե փող.",
          "value": "Դուշանբե փող."
        },
        {
          "label": "Դուրյան թաղ.",
          "value": "Դուրյան թաղ."
        },
        {
          "label": "Թամրուչ փող.",
          "value": "Թամրուչ փող."
        },
        {
          "label": "Թումանյան թաղ.",
          "value": "Թումանյան թաղ."
        },
        {
          "label": "Իսահակյան թաղ.",
          "value": "Իսահակյան թաղ."
        },
        {
          "label": "Լեպսիուսի փող.",
          "value": "Լեպսիուսի փող."
        },
        {
          "label": "Խուդյակով 1 նրբ.",
          "value": "Խուդյակով 1 նրբ."
        },
        {
          "label": "Խուդյակով 2 նրբ.",
          "value": "Խուդյակով 2 նրբ."
        },
        {
          "label": "Խուդյակով 3 նրբ.",
          "value": "Խուդյակով 3 նրբ."
        },
        {
          "label": "Խուդյակով 4 նրբ.",
          "value": "Խուդյակով 4 նրբ."
        },
        {
          "label": "Խուդյակով 5 նրբ.",
          "value": "Խուդյակով 5 նրբ."
        },
        {
          "label": "Խուդյակով փող.",
          "value": "Խուդյակով փող."
        },
        {
          "label": "Ծարավ Աղբյուր փող.",
          "value": "Ծարավ Աղբյուր փող."
        },
        {
          "label": "Հովհաննիսյան թաղ.",
          "value": "Հովհաննիսյան թաղ."
        },
        {
          "label": "Մ. Բաբաջանյան նրբ.",
          "value": "Մ. Բաբաջանյան նրբ."
        },
        {
          "label": "Մ. Բաբաջանյան փող.",
          "value": "Մ. Բաբաջանյան փող."
        },
        {
          "label": "Մ. Մկրտչյան թաղ.",
          "value": "Մ. Մկրտչյան թաղ."
        },
        {
          "label": "Մյասնիկյան պող.",
          "value": "Մյասնիկյան պող."
        },
        {
          "label": "Նարեկացի թաղ.",
          "value": "Նարեկացի թաղ."
        },
        {
          "label": "Չարենց թաղ.",
          "value": "Չարենց թաղ."
        },
        {
          "label": "Պ. Սևակ թաղ.",
          "value": "Պ. Սևակ թաղ."
        },
        {
          "label": "Պ. Սևակ թաղ., 1-ին փող.",
          "value": "Պ. Սևակ թաղ., 1-ին փող."
        },
        {
          "label": "Ռուբինյանց փող.",
          "value": "Ռուբինյանց փող."
        },
        {
          "label": "Սայաթ-Նովա թաղ.",
          "value": "Սայաթ-Նովա թաղ."
        },
        {
          "label": "Սաֆարյան փող.",
          "value": "Սաֆարյան փող."
        },
        {
          "label": "Վաղթանգով փող.",
          "value": "Վաղթանգով փող."
        },
        {
          "label": "Վարուժան թաղ.",
          "value": "Վարուժան թաղ."
        },
        {
          "label": "Տաշքենդ նրբ.",
          "value": "Տաշքենդ նրբ."
        },
        {
          "label": "Տաշքենդ փող.",
          "value": "Տաշքենդ փող."
        },
        {
          "label": "Քուչակ թաղ.",
          "value": "Քուչակ թաղ."
        }
      ]
    },
    {
      communities: 'Արաբկիր',
      info: [
        {
          "label": "Ա. Ավետիսյան փող.",
          "value": "Ա. Ավետիսյան փող."
        },
        {
          "label": "Ա. Բակունց փող.",
          "value": "Ա. Բակունց փող."
        },
        {
          "label": "Ա.Խաչատրյան 1 նրբ.",
          "value": "Ա.Խաչատրյան 1 նրբ."
        },
        {
          "label": "Ա.Խաչատրյան փող.",
          "value": "Ա.Խաչատրյան փող."
        },
        {
          "label": "Ադոնց փող.",
          "value": "Ադոնց փող."
        },
        {
          "label": "Ազատության նրբ.",
          "value": "Ազատության նրբ."
        },
        {
          "label": "Ազատության պող.",
          "value": "Ազատության պող."
        },
        {
          "label": "Աղբյուր Սերոբ փող.",
          "value": "Աղբյուր Սերոբ փող."
        },
        {
          "label": "Այգեկցի փող.",
          "value": "Այգեկցի փող."
        },
        {
          "label": "Այգեձոր 1 նրբ.",
          "value": "Այգեձոր 1 նրբ."
        },
        {
          "label": "Այգեձոր նրբ.",
          "value": "Այգեձոր նրբ."
        },
        {
          "label": "Այգեձոր փող.",
          "value": "Այգեձոր փող."
        },
        {
          "label": "Արաբկիր 17 փող.",
          "value": "Արաբկիր 17 փող."
        },
        {
          "label": "Արաբկիր 19 փող.",
          "value": "Արաբկիր 19 փող."
        },
        {
          "label": "Արաբկիր 21 փող.",
          "value": "Արաբկիր 21 փող."
        },
        {
          "label": "Արաբկիր 23 փող.",
          "value": "Արաբկիր 23 փող."
        },
        {
          "label": "Արաբկիր 25 փող.",
          "value": "Արաբկիր 25 փող."
        },
        {
          "label": "Արաբկիր 27 փող.",
          "value": "Արաբկիր 27 փող."
        },
        {
          "label": "Արաբկիր 33 փող.",
          "value": "Արաբկիր 33 փող."
        },
        {
          "label": "Արաբկիր 34 փող.",
          "value": "Արաբկիր 34 փող."
        },
        {
          "label": "Արաբկիր 35 փող.",
          "value": "Արաբկիր 35 փող."
        },
        {
          "label": "Արաբկիր 37 փող.",
          "value": "Արաբկիր 37 փող."
        },
        {
          "label": "Արաբկիր 38 փող.",
          "value": "Արաբկիր 38 փող."
        },
        {
          "label": "Արաբկիր 39 փող.",
          "value": "Արաբկիր 39 փող."
        },
        {
          "label": "Արաբկիր 41 փող.",
          "value": "Արաբկիր 41 փող."
        },
        {
          "label": "Արաբկիր 43 փող.",
          "value": "Արաբկիր 43 փող."
        },
        {
          "label": "Արաբկիր 45 փող.",
          "value": "Արաբկիր 45 փող."
        },
        {
          "label": "Արաբկիր 47 փող.",
          "value": "Արաբկիր 47 փող."
        },
        {
          "label": "Արաբկիր 49 փող.",
          "value": "Արաբկիր 49 փող."
        },
        {
          "label": "Արաբկիր 51 փող.",
          "value": "Արաբկիր 51 փող."
        },
        {
          "label": "Արաբկիր 53 փող.",
          "value": "Արաբկիր 53 փող."
        },
        {
          "label": "Արղության 1 նրբ.",
          "value": "Արղության 1 նրբ."
        },
        {
          "label": "Արղության 2 նրբ.",
          "value": "Արղության 2 նրբ."
        },
        {
          "label": "Արղության փող.",
          "value": "Արղության փող."
        },
        {
          "label": "Բաբայան փող.",
          "value": "Բաբայան փող."
        },
        {
          "label": "Բակունց փող.",
          "value": "Բակունց փող."
        },
        {
          "label": "Բաղրամյան 4 նրբ.",
          "value": "Բաղրամյան 4 նրբ."
        },
        {
          "label": "Բաղրամյան պող.",
          "value": "Բաղրամյան պող."
        },
        {
          "label": "Բաղրամյան պող. 1 փակ.",
          "value": "Բաղրամյան պող. 1 փակ."
        },
        {
          "label": "Բարբյուս փող.",
          "value": "Բարբյուս փող."
        },
        {
          "label": "Բուդաղյան փող.",
          "value": "Բուդաղյան փող."
        },
        {
          "label": "Գ. Արծրունի փող.",
          "value": "Գ. Արծրունի փող."
        },
        {
          "label": "Գյուլբենկյան 1 փակ.",
          "value": "Գյուլբենկյան 1 փակ."
        },
        {
          "label": "Բաղրամյան 2 նրբ.",
          "value": "Բաղրամյան 2 նրբ."
        },
        {
          "label": "Բաղրամյան 2 փակ.",
          "value": "Բաղրամյան 2 փակ."
        },
        {
          "label": "Բաղրամյան 3 նրբ.",
          "value": "Բաղրամյան 3 նրբ."
        },
        {
          "label": "Գյուլբենկյան փող.",
          "value": "Գյուլբենկյան փող."
        },
        {
          "label": "Գուլակյան փող.",
          "value": "Գուլակյան փող."
        },
        {
          "label": "Գրիբոյեդով 1 նրբ.",
          "value": "Գրիբոյեդով 1 նրբ."
        },
        {
          "label": "Գրիբոյեդով 4 նրբ.",
          "value": "Գրիբոյեդով 4 նրբ."
        },
        {
          "label": "Գրիբոյեդով փող.",
          "value": "Գրիբոյեդով փող."
        },
        {
          "label": "Դարաբաղ փող.",
          "value": "Դարաբաղ փող."
        },
        {
          "label": "Երզնկյան փող.",
          "value": "Երզնկյան փող."
        },
        {
          "label": "Թավրիզյան փող.",
          "value": "Թավրիզյան փող."
        },
        {
          "label": "Թբիլիսյան խճ.",
          "value": "Թբիլիսյան խճ."
        },
        {
          "label": "Թբիլիսյան նրբ.",
          "value": "Թբիլիսյան նրբ."
        },
        {
          "label": "Լեռ Կամսար փող.",
          "value": "Լեռ Կամսար փող."
        },
        {
          "label": "Խնկո Ապեր փող.",
          "value": "Խնկո Ապեր փող."
        },
        {
          "label": "Կալենց փող.",
          "value": "Կալենց փող."
        },
        {
          "label": "Կասյան փող.",
          "value": "Կասյան փող."
        },
        {
          "label": "Կիևյան 1-ին նրբ.",
          "value": "Կիևյան 1-ին նրբ."
        },
        {
          "label": "Կիևյան 2-րդ նրբ.",
          "value": "Կիևյան 2-րդ նրբ."
        },
        {
          "label": "Կիևյան փող.",
          "value": "Կիևյան փող."
        },
        {
          "label": "Կոմիտաս 1-ին նրբ.",
          "value": "Կոմիտաս 1-ին նրբ."
        },
        {
          "label": "Կոմիտաս 2-րդ նրբ.",
          "value": "Կոմիտաս 2-րդ նրբ."
        },
        {
          "label": "Կոմիտաս պող.",
          "value": "Կոմիտաս պող."
        },
        {
          "label": "Հ. Էմին 1 նրբ.",
          "value": "Հ. Էմին 1 նրբ."
        },
        {
          "label": "Հ. Էմին նրբ.",
          "value": "Հ. Էմին նրբ."
        },
        {
          "label": "Հ. Էմին փող.",
          "value": "Հ. Էմին փող."
        },
        {
          "label": "Հ. Հակոբյան փող.",
          "value": "Հ. Հակոբյան փող."
        },
        {
          "label": "Համո Սահյան փող.",
          "value": "Համո Սահյան փող."
        },
        {
          "label": "Հյուսիսային Ճառագայթ",
          "value": "Հյուսիսային Ճառագայթ"
        },
        {
          "label": "Հր. Քոչար 1 նրբ.",
          "value": "Հր. Քոչար 1 նրբ."
        },
        {
          "label": "Հր. Քոչար 2 նրբ.",
          "value": "Հր. Քոչար 2 նրբ."
        },
        {
          "label": "Հր. Քոչար փող.",
          "value": "Հր. Քոչար փող."
        },
        {
          "label": "Ղափանցյան փող.",
          "value": "Ղափանցյան փող."
        },
        {
          "label": "Մալխասյանց փող.",
          "value": "Մալխասյանց փող."
        },
        {
          "label": "Մամիկոնյանց փող.",
          "value": "Մամիկոնյանց փող."
        },
        {
          "label": "Մանուշյան փող.",
          "value": "Մանուշյան փող."
        },
        {
          "label": "Ն. Զարյան /Դպրոցականներ/ 1 նրբ.",
          "value": "Ն. Զարյան /Դպրոցականներ/ 1 նրբ."
        },
        {
          "label": "Ն. Զարյան /Դպրոցականներ/ փող.",
          "value": "Ն. Զարյան /Դպրոցականներ/ փող."
        },
        {
          "label": "Ն. Տիգրանյան փող.",
          "value": "Ն. Տիգրանյան փող."
        },
        {
          "label": "Նիկոլ Դուման /Նախիմով/ փող.",
          "value": "Նիկոլ Դուման /Նախիմով/ փող."
        },
        {
          "label": "Շահսուվարյան փող.",
          "value": "Շահսուվարյան փող."
        },
        {
          "label": "Շիրվանզադե փող.",
          "value": "Շիրվանզադե փող."
        },
        {
          "label": "Շրջանցիկ թունել",
          "value": "Շրջանցիկ թունել"
        },
        {
          "label": "Ջեյմս Բրայս փող.",
          "value": "Ջեյմս Բրայս փող."
        },
        {
          "label": "Ռիգա փող.",
          "value": "Ռիգա փող."
        },
        {
          "label": "Սարալանջ փող.",
          "value": "Սարալանջ փող."
        },
        {
          "label": "Սեպուհ փող.",
          "value": "Սեպուհ փող."
        },
        {
          "label": "Սևաստոպոլյան փող.",
          "value": "Սևաստոպոլյան փող."
        },
        {
          "label": "Սևքարեցի Սաքո փող.",
          "value": "Սևքարեցի Սաքո փող."
        },
        {
          "label": "Սոսե փող.",
          "value": "Սոսե փող."
        },
        {
          "label": "Սունդուկյան փող.",
          "value": "Սունդուկյան փող."
        },
        {
          "label": "Վ. Համբարձումյան /Գայդար/ փող.",
          "value": "Վ. Համբարձումյան /Գայդար/ փող."
        },
        {
          "label": "Վ. Փափազյան 1-ին նրբ.",
          "value": "Վ. Փափազյան 1-ին նրբ."
        },
        {
          "label": "Վ. Փափազյան 2-րդ նրբ.",
          "value": "Վ. Փափազյան 2-րդ նրբ."
        },
        {
          "label": "Վ. Փափազյան փող.",
          "value": "Վ. Փափազյան փող."
        },
        {
          "label": "Վահագն Դավթյան փող.",
          "value": "Վահագն Դավթյան փող."
        },
        {
          "label": "Վաղարշյան փող.",
          "value": "Վաղարշյան փող."
        },
        {
          "label": "Վատուտին փող.",
          "value": "Վատուտին փող."
        },
        {
          "label": "Վրացական 4 նրբ.",
          "value": "Վրացական 4 նրբ."
        },
        {
          "label": "Վրացական փող.",
          "value": "Վրացական փող."
        },
        {
          "label": "Տոլբուխին փող.",
          "value": "Տոլբուխին փող."
        },
        {
          "label": "Քանաքեռ ՀԷԿ",
          "value": "Քանաքեռ ՀԷԿ"
        },
        {
          "label": "Քեռի փող.",
          "value": "Քեռի փող."
        },
        {
          "label": "Օրբելի եղբայրներ փող.",
          "value": "Օրբելի եղբայրներ փող."
        }
      ]
    },
    {
      communities: 'Դավթաշեն',
      info: [
        {
          "label": "Աղաբաբյան փող.",
          "value": "Աղաբաբյան փող."
        },
        {
          "label": "Ան. Միկոյան փող.",
          "value": "Ան. Միկոյան փող."
        },
        {
          "label": "Աշտարակի խճ.",
          "value": "Աշտարակի խճ."
        },
        {
          "label": "Դավթաշեն 1 թաղ.",
          "value": "Դավթաշեն 1 թաղ."
        },
        {
          "label": "Դավթաշեն 1 փող.",
          "value": "Դավթաշեն 1 փող."
        },
        {
          "label": "Դավթաշեն 1 փող. 1 նրբ.",
          "value": "Դավթաշեն 1 փող. 1 նրբ."
        },
        {
          "label": "Դավթաշեն 1 փող. 2 նրբ.",
          "value": "Դավթաշեն 1 փող. 2 նրբ."
        },
        {
          "label": "Դավթաշեն 2 թաղ.",
          "value": "Դավթաշեն 2 թաղ."
        },
        {
          "label": "Դավթաշեն 2 փող.",
          "value": "Դավթաշեն 2 փող."
        },
        {
          "label": "Դավթաշեն 3 թաղ.",
          "value": "Դավթաշեն 3 թաղ."
        },
        {
          "label": "Դավթաշեն 4 թաղ.",
          "value": "Դավթաշեն 4 թաղ."
        },
        {
          "label": "Դավթաշենի 10 փող.",
          "value": "Դավթաշենի 10 փող."
        },
        {
          "label": "Դավթաշենի 3 փող.",
          "value": "Դավթաշենի 3 փող."
        },
        {
          "label": "Դավթաշենի 4 փող.",
          "value": "Դավթաշենի 4 փող."
        },
        {
          "label": "Դավթաշենի 5 փող.",
          "value": "Դավթաշենի 5 փող."
        },
        {
          "label": "Դավթաշենի 6 փող.",
          "value": "Դավթաշենի 6 փող."
        },
        {
          "label": "Դավթաշենի 7 փող.",
          "value": "Դավթաշենի 7 փող."
        },
        {
          "label": "Դավթաշենի 8 փող.",
          "value": "Դավթաշենի 8 փող."
        },
        {
          "label": "Դավթաշենի 9 փող.",
          "value": "Դավթաշենի 9 փող."
        },
        {
          "label": "Եղվարդ խճ.",
          "value": "Եղվարդ խճ."
        },
        {
          "label": "Իոսիֆյան փող.",
          "value": "Իոսիֆյան փող."
        },
        {
          "label": "Ձոր-1 թաղ.",
          "value": "Ձոր-1 թաղ."
        },
        {
          "label": "Ձոր-2 թաղ.",
          "value": "Ձոր-2 թաղ."
        },
        {
          "label": "Մելքումով փող.",
          "value": "Մելքումով փող."
        },
        {
          "label": "Ս. Գևորգյան փող.",
          "value": "Ս. Գևորգյան փող."
        },
        {
          "label": "Սասնա Ծռեր փող.",
          "value": "Սասնա Ծռեր փող."
        },
        {
          "label": "Տ. Պետրոսյան փող.",
          "value": "Տ. Պետրոսյան փող."
        },
        {
          "label": "Փիրումյաններ փող.",
          "value": "Փիրումյաններ փող."
        }
      ]
    },
    {
      communities: 'Էրեբունի',
      info: [
        {
          "label": "Ազատամարտիկներ/Դիմիտրով/ 1 նրբ.",
          "value": "Ազատամարտիկներ/Դիմիտրով/ 1 նրբ."
        },
        {
          "label": "Ազատամարտիկներ/Դիմիտրով/ փող.",
          "value": "Ազատամարտիկներ/Դիմիտրով/ փող."
        },
        {
          "label": "Աթոյան անց.",
          "value": "Աթոյան անց."
        },
        {
          "label": "Աթոյան նրբ.",
          "value": "Աթոյան նրբ."
        },
        {
          "label": "Աթոյան փող.",
          "value": "Աթոյան փող."
        },
        {
          "label": "Աճեմյան 3 նրբ.",
          "value": "Աճեմյան 3 նրբ."
        },
        {
          "label": "Աճեմյան փակ.",
          "value": "Աճեմյան փակ."
        },
        {
          "label": "Աճեմյան/Անդերֆեդերացիա/ 1 նրբ.",
          "value": "Աճեմյան/Անդերֆեդերացիա/ 1 նրբ."
        },
        {
          "label": "Աճեմյան/Անդերֆեդերացիա/ 2 փակ.",
          "value": "Աճեմյան/Անդերֆեդերացիա/ 2 փակ."
        },
        {
          "label": "Աճեմյան/Անդերֆեդերացիա/ փող.",
          "value": "Աճեմյան/Անդերֆեդերացիա/ փող."
        },
        {
          "label": "Այվազովսկի փող.",
          "value": "Այվազովսկի փող."
        },
        {
          "label": "Ավանեսով անց.",
          "value": "Ավանեսով անց."
        },
        {
          "label": "Ավանեսով փող.",
          "value": "Ավանեսով փող."
        },
        {
          "label": "Ավանեսով փող., նրբ.",
          "value": "Ավանեսով փող., նրբ."
        },
        {
          "label": "Արին Բերդ փող.",
          "value": "Արին Բերդ փող."
        },
        {
          "label": "Արին Բերդի 5-րդ նրբ.",
          "value": "Արին Բերդի 5-րդ նրբ."
        },
        {
          "label": "Արին-բերդ 4-րդ նրբ.",
          "value": "Արին-բերդ 4-րդ նրբ."
        },
        {
          "label": "Արցախ թաղ.",
          "value": "Արցախ թաղ."
        },
        {
          "label": "Արցախ/Բաքվի/ պող.",
          "value": "Արցախ/Բաքվի/ պող."
        },
        {
          "label": "Բելինսկի փող.",
          "value": "Բելինսկի փող."
        },
        {
          "label": "Բուռնազյան փող.",
          "value": "Բուռնազյան փող."
        },
        {
          "label": "Գաջեգործներ 1 փող.",
          "value": "Գաջեգործներ 1 փող."
        },
        {
          "label": "Գաջեգործներ 2 փող.",
          "value": "Գաջեգործներ 2 փող."
        },
        {
          "label": "Գաջեգործներ 3 փող.",
          "value": "Գաջեգործներ 3 փող."
        },
        {
          "label": "Գաջեգործներ 4 փող.",
          "value": "Գաջեգործներ 4 փող."
        },
        {
          "label": "Գաջեգործներ փող.",
          "value": "Գաջեգործներ փող."
        },
        {
          "label": "Գլինկա փող.",
          "value": "Գլինկա փող."
        },
        {
          "label": "Դաշտենց փող.",
          "value": "Դաշտենց փող."
        },
        {
          "label": "Դավիթ Բեկ  2 թաղ.",
          "value": "Դավիթ Բեկ  2 թաղ."
        },
        {
          "label": "Դավիթ Բեկ 1 թաղ.",
          "value": "Դավիթ Բեկ 1 թաղ."
        },
        {
          "label": "Դավիթ Բեկ թաղ.",
          "value": "Դավիթ Բեկ թաղ."
        },
        {
          "label": "Դավիթ Բեկ փող.",
          "value": "Դավիթ Բեկ փող."
        },
        {
          "label": "Դավիթ Բեկ փող. 4 նրբ.",
          "value": "Դավիթ Բեկ փող. 4 նրբ."
        },
        {
          "label": "Դիմիտրով փող.",
          "value": "Դիմիտրով փող."
        },
        {
          "label": "Դիմիտրով փող. 3-րդ նրբ.",
          "value": "Դիմիտրով փող. 3-րդ նրբ."
        },
        {
          "label": "Երկաթգծի փող.",
          "value": "Երկաթգծի փող."
        },
        {
          "label": "Երկաթուղայիններ 1 փող.",
          "value": "Երկաթուղայիններ 1 փող."
        },
        {
          "label": "Երկաթուղայիններ 2 փող.",
          "value": "Երկաթուղայիններ 2 փող."
        },
        {
          "label": "Երկաթուղայիններ 3 փող.",
          "value": "Երկաթուղայիններ 3 փող."
        },
        {
          "label": "Երկաթուղայիններ 4 փող.",
          "value": "Երկաթուղայիններ 4 փող."
        },
        {
          "label": "Երկաթուղայիններ 5 փող.",
          "value": "Երկաթուղայիններ 5 փող."
        },
        {
          "label": "Երկաթուղայիններ 6 փող.",
          "value": "Երկաթուղայիններ 6 փող."
        },
        {
          "label": "Երկաթուղայիններ 7 փող.",
          "value": "Երկաթուղայիններ 7 փող."
        },
        {
          "label": "Երկաթուղայիններ 8 փող.",
          "value": "Երկաթուղայիններ 8 փող."
        },
        {
          "label": "Երկաթուղայիններ 9 փող.",
          "value": "Երկաթուղայիններ 9 փող."
        },
        {
          "label": "Էրեբունի փող.",
          "value": "Էրեբունի փող."
        },
        {
          "label": "Թեյշեբաինի փող.",
          "value": "Թեյշեբաինի փող."
        },
        {
          "label": "Թորամանյան փող.",
          "value": "Թորամանյան փող."
        },
        {
          "label": "Լ. Մելիքյան փող.",
          "value": "Լ. Մելիքյան փող."
        },
        {
          "label": "Լիսինյան փող.",
          "value": "Լիսինյան փող."
        },
        {
          "label": "Լոմոնոսով փող.",
          "value": "Լոմոնոսով փող."
        },
        {
          "label": "Խաղաղ Դոն փող.",
          "value": "Խաղաղ Դոն փող."
        },
        {
          "label": "Խորենացի 3 փակ.",
          "value": "Խորենացի 3 փակ."
        },
        {
          "label": "Խորենացի փող.",
          "value": "Խորենացի փող."
        },
        {
          "label": "Կլիլով փող.",
          "value": "Կլիլով փող."
        },
        {
          "label": "Կուստո փող.",
          "value": "Կուստո փող."
        },
        {
          "label": "Մահարի փող.",
          "value": "Մահարի փող."
        },
        {
          "label": "Մայակովսկի փող.",
          "value": "Մայակովսկի փող."
        },
        {
          "label": "Միչուրին փող.",
          "value": "Միչուրին փող."
        },
        {
          "label": "Մուշավան 1 փող.",
          "value": "Մուշավան 1 փող."
        },
        {
          "label": "Մուշավան 11 փող.",
          "value": "Մուշավան 11 փող."
        },
        {
          "label": "Մուշավան 4 փող.",
          "value": "Մուշավան 4 փող."
        },
        {
          "label": "Մուշավան 9 փող.",
          "value": "Մուշավան 9 փող."
        },
        {
          "label": "Մուշավան թաղ.",
          "value": "Մուշավան թաղ."
        },
        {
          "label": "Մուրացան 1 փակ.",
          "value": "Մուրացան 1 փակ."
        },
        {
          "label": "Մուրացան փող.",
          "value": "Մուրացան փող."
        },
        {
          "label": "Նեկրասովի փող.",
          "value": "Նեկրասովի փող."
        },
        {
          "label": "Նոր Արեշ 10 փող.",
          "value": "Նոր Արեշ 10 փող."
        },
        {
          "label": "Նոր Արեշ 11 փող.",
          "value": "Նոր Արեշ 11 փող."
        },
        {
          "label": "Նոր Արեշ 12 փող.",
          "value": "Նոր Արեշ 12 փող."
        },
        {
          "label": "Նոր Արեշ 13 փող.",
          "value": "Նոր Արեշ 13 փող."
        },
        {
          "label": "Նոր Արեշ 14 փող.",
          "value": "Նոր Արեշ 14 փող."
        },
        {
          "label": "Նոր Արեշ 15 փող.",
          "value": "Նոր Արեշ 15 փող."
        },
        {
          "label": "Նոր Արեշ 16 փող.",
          "value": "Նոր Արեշ 16 փող."
        },
        {
          "label": "Նոր Արեշ 17 փող.",
          "value": "Նոր Արեշ 17 փող."
        },
        {
          "label": "Նոր Արեշ 19 փող.",
          "value": "Նոր Արեշ 19 փող."
        },
        {
          "label": "Նոր Արեշ 2 փող.",
          "value": "Նոր Արեշ 2 փող."
        },
        {
          "label": "Նոր Արեշ 20 նրբ.",
          "value": "Նոր Արեշ 20 նրբ."
        },
        {
          "label": "Նոր Արեշ 20 փող.",
          "value": "Նոր Արեշ 20 փող."
        },
        {
          "label": "Նոր Արեշ 22 փող.",
          "value": "Նոր Արեշ 22 փող."
        },
        {
          "label": "Նոր Արեշ 24 փող.",
          "value": "Նոր Արեշ 24 փող."
        },
        {
          "label": "Նոր Արեշ 25 փող.",
          "value": "Նոր Արեշ 25 փող."
        },
        {
          "label": "Նոր Արեշ 26 փող.",
          "value": "Նոր Արեշ 26 փող."
        },
        {
          "label": "Նոր Արեշ 27 փող.",
          "value": "Նոր Արեշ 27 փող."
        },
        {
          "label": "Նոր Արեշ 28 փող.",
          "value": "Նոր Արեշ 28 փող."
        },
        {
          "label": "Նոր Արեշ 29 փող.",
          "value": "Նոր Արեշ 29 փող."
        },
        {
          "label": "Նոր Արեշ 3 փող.",
          "value": "Նոր Արեշ 3 փող."
        },
        {
          "label": "Նոր Արեշ 30 փող.",
          "value": "Նոր Արեշ 30 փող."
        },
        {
          "label": "Նոր Արեշ 31 փող.",
          "value": "Նոր Արեշ 31 փող."
        },
        {
          "label": "Նոր Արեշ 32 փող.",
          "value": "Նոր Արեշ 32 փող."
        },
        {
          "label": "Նոր Արեշ 33 փող.",
          "value": "Նոր Արեշ 33 փող."
        },
        {
          "label": "Նոր Արեշ 34 փող.",
          "value": "Նոր Արեշ 34 փող."
        },
        {
          "label": "Նոր Արեշ 35 փող.",
          "value": "Նոր Արեշ 35 փող."
        },
        {
          "label": "Նոր Արեշ 36 փող.",
          "value": "Նոր Արեշ 36 փող."
        },
        {
          "label": "Նոր Արեշ 37 փող.",
          "value": "Նոր Արեշ 37 փող."
        },
        {
          "label": "Նոր Արեշ 38 փող.",
          "value": "Նոր Արեշ 38 փող."
        },
        {
          "label": "Նոր Արեշ 39 փող.",
          "value": "Նոր Արեշ 39 փող."
        },
        {
          "label": "Նոր Արեշ 4 փող.",
          "value": "Նոր Արեշ 4 փող."
        },
        {
          "label": "Նոր Արեշ 40 փող.",
          "value": "Նոր Արեշ 40 փող."
        },
        {
          "label": "Նոր Արեշ 41 փող.",
          "value": "Նոր Արեշ 41 փող."
        },
        {
          "label": "Նոր Արեշ 42 փող.",
          "value": "Նոր Արեշ 42 փող."
        },
        {
          "label": "Նոր Արեշ 43 փող.",
          "value": "Նոր Արեշ 43 փող."
        },
        {
          "label": "Նոր Արեշ 44 փող.",
          "value": "Նոր Արեշ 44 փող."
        },
        {
          "label": "Նոր Արեշ 45 փող.",
          "value": "Նոր Արեշ 45 փող."
        },
        {
          "label": "Նոր Արեշ 46 փող.",
          "value": "Նոր Արեշ 46 փող."
        },
        {
          "label": "Նոր Արեշ 47 փող.",
          "value": "Նոր Արեշ 47 փող."
        },
        {
          "label": "Նոր Արեշ 48 փող.",
          "value": "Նոր Արեշ 48 փող."
        },
        {
          "label": "Նոր Արեշ 49 փող.",
          "value": "Նոր Արեշ 49 փող."
        },
        {
          "label": "Նոր Արեշ 50 փող.",
          "value": "Նոր Արեշ 50 փող."
        },
        {
          "label": "Նոր Արեշ 6 փող.",
          "value": "Նոր Արեշ 6 փող."
        },
        {
          "label": "Նոր Արեշ 7 փող.",
          "value": "Նոր Արեշ 7 փող."
        },
        {
          "label": "Նոր Արեշ 8 փող.",
          "value": "Նոր Արեշ 8 փող."
        },
        {
          "label": "Նոր Արեշ 9 փող.",
          "value": "Նոր Արեշ 9 փող."
        },
        {
          "label": "Նուբարաշեն 6 փող.",
          "value": "Նուբարաշեն 6 փող."
        },
        {
          "label": "Նուբարաշեն խճ.",
          "value": "Նուբարաշեն խճ."
        },
        {
          "label": "Նուբարաշեն փող.",
          "value": "Նուբարաշեն փող."
        },
        {
          "label": "Ողջաբերդ փող.",
          "value": "Ողջաբերդ փող."
        },
        {
          "label": "Որմնադիրներ 2 նրբ.",
          "value": "Որմնադիրներ 2 նրբ."
        },
        {
          "label": "Որմնադիրներ փող.",
          "value": "Որմնադիրներ փող."
        },
        {
          "label": "Չեռնիշևսկի փող.",
          "value": "Չեռնիշևսկի փող."
        },
        {
          "label": "Ջրաշեն 1-ին փող.",
          "value": "Ջրաշեն 1-ին փող."
        },
        {
          "label": "Ջրաշեն 2-ին փող.",
          "value": "Ջրաշեն 2-ին փող."
        },
        {
          "label": "Ռոստովյան փող.",
          "value": "Ռոստովյան փող."
        },
        {
          "label": "Սասունցի Դավիթ փող.",
          "value": "Սասունցի Դավիթ փող."
        },
        {
          "label": "Սասունցի Դավիթ փող., 1-ին նրբ.",
          "value": "Սասունցի Դավիթ փող., 1-ին նրբ."
        },
        {
          "label": "Սարի Թաղ",
          "value": "Սարի Թաղ"
        },
        {
          "label": "Սարի Թաղ 1 շարք",
          "value": "Սարի Թաղ 1 շարք"
        },
        {
          "label": "Սարի Թաղ 1 փող.",
          "value": "Սարի Թաղ 1 փող."
        },
        {
          "label": "Սարի Թաղ 10 շարք",
          "value": "Սարի Թաղ 10 շարք"
        },
        {
          "label": "Սարի Թաղ 10 փող.",
          "value": "Սարի Թաղ 10 փող."
        },
        {
          "label": "Սարի Թաղ 11 փող.",
          "value": "Սարի Թաղ 11 փող."
        },
        {
          "label": "Սարի Թաղ 12 փող.",
          "value": "Սարի Թաղ 12 փող."
        },
        {
          "label": "Սարի Թաղ 13 փող.",
          "value": "Սարի Թաղ 13 փող."
        },
        {
          "label": "Սարի Թաղ 14 փող.",
          "value": "Սարի Թաղ 14 փող."
        },
        {
          "label": "Սարի Թաղ 15 փող.",
          "value": "Սարի Թաղ 15 փող."
        },
        {
          "label": "Սարի Թաղ 16 փող.",
          "value": "Սարի Թաղ 16 փող."
        },
        {
          "label": "Սարի Թաղ 17 փող.",
          "value": "Սարի Թաղ 17 փող."
        },
        {
          "label": "Սարի Թաղ 18 փող.",
          "value": "Սարի Թաղ 18 փող."
        },
        {
          "label": "Սարի Թաղ 19 փող.",
          "value": "Սարի Թաղ 19 փող."
        },
        {
          "label": "Սարի Թաղ 2 շարք",
          "value": "Սարի Թաղ 2 շարք"
        },
        {
          "label": "Սարի Թաղ 2 փող.",
          "value": "Սարի Թաղ 2 փող."
        },
        {
          "label": "Սարի Թաղ 20 փող.",
          "value": "Սարի Թաղ 20 փող."
        },
        {
          "label": "Սարի Թաղ 21 փող.",
          "value": "Սարի Թաղ 21 փող."
        },
        {
          "label": "Սարի Թաղ 22 փող.",
          "value": "Սարի Թաղ 22 փող."
        },
        {
          "label": "Սարի Թաղ 23 փող.",
          "value": "Սարի Թաղ 23 փող."
        },
        {
          "label": "Սարի Թաղ 24 փող.",
          "value": "Սարի Թաղ 24 փող."
        },
        {
          "label": "Սարի Թաղ 25 փող.",
          "value": "Սարի Թաղ 25 փող."
        },
        {
          "label": "Սարի Թաղ 26 փող.",
          "value": "Սարի Թաղ 26 փող."
        },
        {
          "label": "Սարի Թաղ 27 փող.",
          "value": "Սարի Թաղ 27 փող."
        },
        {
          "label": "Սարի Թաղ 28 փող.",
          "value": "Սարի Թաղ 28 փող."
        },
        {
          "label": "Սարի Թաղ 3 շարք",
          "value": "Սարի Թաղ 3 շարք"
        },
        {
          "label": "Սարի Թաղ 3 փող.",
          "value": "Սարի Թաղ 3 փող."
        },
        {
          "label": "Սարի Թաղ 4 շարք",
          "value": "Սարի Թաղ 4 շարք"
        },
        {
          "label": "Սարի Թաղ 4 փող.",
          "value": "Սարի Թաղ 4 փող."
        },
        {
          "label": "Սարի Թաղ 5 շարք",
          "value": "Սարի Թաղ 5 շարք"
        },
        {
          "label": "Սարի Թաղ 5 փող.",
          "value": "Սարի Թաղ 5 փող."
        },
        {
          "label": "Սարի Թաղ 6 շարք",
          "value": "Սարի Թաղ 6 շարք"
        },
        {
          "label": "Սարի Թաղ 6 փող.",
          "value": "Սարի Թաղ 6 փող."
        },
        {
          "label": "Սարի Թաղ 7 շարք",
          "value": "Սարի Թաղ 7 շարք"
        },
        {
          "label": "Սարի Թաղ 7 փող.",
          "value": "Սարի Թաղ 7 փող."
        },
        {
          "label": "Սարի Թաղ 8 շարք",
          "value": "Սարի Թաղ 8 շարք"
        },
        {
          "label": "Սարի Թաղ 9 շարք",
          "value": "Սարի Թաղ 9 շարք"
        },
        {
          "label": "Սարի Թաղ 9 փող.",
          "value": "Սարի Թաղ 9 փող."
        },
        {
          "label": "Սարմի փող.",
          "value": "Սարմի փող."
        },
        {
          "label": "Սիտրոգրադյան փող.",
          "value": "Սիտրոգրադյան փող."
        },
        {
          "label": "Սուվորով փող.",
          "value": "Սուվորով փող."
        },
        {
          "label": "Սոֆիա փող.",
          "value": "Սոֆիա փող."
        },
        {
          "label": "Վարդաշեն 1 փող.",
          "value": "Վարդաշեն 1 փող."
        },
        {
          "label": "Վարդաշեն 10 փող.",
          "value": "Վարդաշեն 10 փող."
        },
        {
          "label": "Վարդաշեն 11 փող.",
          "value": "Վարդաշեն 11 փող."
        },
        {
          "label": "Վարդաշեն 12 փող.",
          "value": "Վարդաշեն 12 փող."
        },
        {
          "label": "Վարդաշեն 2 փող.",
          "value": "Վարդաշեն 2 փող."
        },
        {
          "label": "Վարդաշեն 3 փող.",
          "value": "Վարդաշեն 3 փող."
        },
        {
          "label": "Վարդաշեն 4 փող.",
          "value": "Վարդաշեն 4 փող."
        },
        {
          "label": "Վարդաշեն 5 փող.",
          "value": "Վարդաշեն 5 փող."
        },
        {
          "label": "Վարդաշեն 6 փող.",
          "value": "Վարդաշեն 6 փող."
        },
        {
          "label": "Վարդաշեն 7 փող.",
          "value": "Վարդաշեն 7 փող."
        },
        {
          "label": "Վարդաշեն 8 փող.",
          "value": "Վարդաշեն 8 փող."
        },
        {
          "label": "Վարդաշեն 9 փող.",
          "value": "Վարդաշեն 9 փող."
        },
        {
          "label": "Տիգրան Մեծ  նրբ.",
          "value": "Տիգրան Մեծ  նրբ."
        },
        {
          "label": "Տիգրան Մեծ պող.",
          "value": "Տիգրան Մեծ պող."
        },
        {
          "label": "Տիտոգրադյան փող.",
          "value": "Տիտոգրադյան փող."
        },
        {
          "label": "Օստրովսկի փող.",
          "value": "Օստրովսկի փող."
        }
      ]
    },
    {
      communities: 'Կենտրոն',
      info: [
        {
          "label": "Ա. Պետրոսյան փող.",
          "value": "Ա. Պետրոսյան փող."
        },
        {
          "label": "Աբովյան փող.",
          "value": "Աբովյան փող."
        },
        {
          "label": "Ագաթանգեղոս փող.",
          "value": "Ագաթանգեղոս փող."
        },
        {
          "label": "Ադանա փող.",
          "value": "Ադանա փող."
        },
        {
          "label": "Աթենք փող.",
          "value": "Աթենք փող."
        },
        {
          "label": "Ալեք Մանուկյան փող.",
          "value": "Ալեք Մանուկյան փող."
        },
        {
          "label": "Ալիխանյան փող.",
          "value": "Ալիխանյան փող."
        },
        {
          "label": "Աղայան փող.",
          "value": "Աղայան փող."
        },
        {
          "label": "Աղյուսագործներ 2 փող.",
          "value": "Աղյուսագործներ 2 փող."
        },
        {
          "label": "Աղյուսագործներ փող.",
          "value": "Աղյուսագործներ փող."
        },
        {
          "label": "Ամերիկյան փող.",
          "value": "Ամերիկյան փող."
        },
        {
          "label": "Ամիրյան փող.",
          "value": "Ամիրյան փող."
        },
        {
          "label": "Այաս փող.",
          "value": "Այաս փող."
        },
        {
          "label": "Այգեստան 1 փող.",
          "value": "Այգեստան 1 փող."
        },
        {
          "label": "Այգեստան 10 փող.",
          "value": "Այգեստան 10 փող."
        },
        {
          "label": "Այգեստան 11 փող.",
          "value": "Այգեստան 11 փող."
        },
        {
          "label": "Այգեստան 2 փող.",
          "value": "Այգեստան 2 փող."
        },
        {
          "label": "Այգեստան 3 նրբ.",
          "value": "Այգեստան 3 նրբ."
        },
        {
          "label": "Այգեստան 3 փող.",
          "value": "Այգեստան 3 փող."
        },
        {
          "label": "Այգեստան 4 փող.",
          "value": "Այգեստան 4 փող."
        },
        {
          "label": "Այգեստան 5 փող.",
          "value": "Այգեստան 5 փող."
        },
        {
          "label": "Այգեստան 6 փող.",
          "value": "Այգեստան 6 փող."
        },
        {
          "label": "Այգեստան 7 փող.",
          "value": "Այգեստան 7 փող."
        },
        {
          "label": "Այգեստան 8 փող.",
          "value": "Այգեստան 8 փող."
        },
        {
          "label": "Այգեստան 9 փող.",
          "value": "Այգեստան 9 փող."
        },
        {
          "label": "Անտառային փող.",
          "value": "Անտառային փող."
        },
        {
          "label": "Աստրովսկի փող.,",
          "value": "Աստրովսկի փող.,"
        },
        {
          "label": "Արամի նրբ.",
          "value": "Արամի նրբ."
        },
        {
          "label": "Արամի փող.",
          "value": "Արամի փող."
        },
        {
          "label": "Արգիշտի փող.",
          "value": "Արգիշտի փող."
        },
        {
          "label": "Արհեստավորներ 1 փող.",
          "value": "Արհեստավորներ 1 փող."
        },
        {
          "label": "Արհեստավորներ 2 փող.",
          "value": "Արհեստավորներ 2 փող."
        },
        {
          "label": "Արհեստավորներ փող.",
          "value": "Արհեստավորներ փող."
        },
        {
          "label": "Արշակունյաց 1 նրբ.",
          "value": "Արշակունյաց 1 նրբ."
        },
        {
          "label": "Արշակունյաց պող.",
          "value": "Արշակունյաց պող."
        },
        {
          "label": "Բաղրամյան 1 անց.",
          "value": "Բաղրամյան 1 անց."
        },
        {
          "label": "Բաղրամյան 1 նրբ.",
          "value": "Բաղրամյան 1 նրբ."
        },
        {
          "label": "Բաղրամյան 2 փակ.",
          "value": "Բաղրամյան 2 փակ."
        },
        {
          "label": "Բաղրամյան պող.",
          "value": "Բաղրամյան պող."
        },
        {
          "label": "Բայրոն փող.",
          "value": "Բայրոն փող."
        },
        {
          "label": "Բարձրաբերդ փող.",
          "value": "Բարձրաբերդ փող."
        },
        {
          "label": "Բարձրաբերդ փող.",
          "value": "Բարձրաբերդ փող."
        },
        {
          "label": "Բելյակով փող.",
          "value": "Բելյակով փող."
        },
        {
          "label": "Բեյրութ փող.",
          "value": "Բեյրութ փող."
        },
        {
          "label": "Բուդապեշտ փող",
          "value": "Բուդապեշտ փող"
        },
        {
          "label": "Բուզանդ փող.",
          "value": "Բուզանդ փող."
        },
        {
          "label": "Բրյուսով փող.",
          "value": "Բրյուսով փող."
        },
        {
          "label": "Գ. Էմին փող.",
          "value": "Գ. Էմին փող."
        },
        {
          "label": "Գետառ փող.",
          "value": "Գետառ փող."
        },
        {
          "label": "Գր. Լուսավորիչ փող.",
          "value": "Գր. Լուսավորիչ փող."
        },
        {
          "label": "Գր. Քոչար փող.",
          "value": "Գր. Քոչար փող."
        },
        {
          "label": "Դ. Դեմիրճյան փող.",
          "value": "Դ. Դեմիրճյան փող."
        },
        {
          "label": "Դեղատան փող.",
          "value": "Դեղատան փող."
        },
        {
          "label": "Ե. Կողբացի փող.",
          "value": "Ե. Կողբացի փող."
        },
        {
          "label": "Եկմալյան փող.",
          "value": "Եկմալյան փող."
        },
        {
          "label": "Եր. Քոչար փող.",
          "value": "Եր. Քոչար փող."
        },
        {
          "label": "Զավարյան փող.",
          "value": "Զավարյան փող."
        },
        {
          "label": "Զարոբյան /Պլեխանով/ փող.",
          "value": "Զարոբյան /Պլեխանով/ փող."
        },
        {
          "label": "Զաքյան 1 նրբ.",
          "value": "Զաքյան 1 նրբ."
        },
        {
          "label": "Զաքյան փող.",
          "value": "Զաքյան փող."
        },
        {
          "label": "Զորյան փող.",
          "value": "Զորյան փող."
        },
        {
          "label": "Էջմիածնի հին խճ.",
          "value": "Էջմիածնի հին խճ."
        },
        {
          "label": "Թաիրով փող.",
          "value": "Թաիրով փող."
        },
        {
          "label": "Թամանյան 2 անց.",
          "value": "Թամանյան 2 անց."
        },
        {
          "label": "Թամանյան փող.",
          "value": "Թամանյան փող."
        },
        {
          "label": "Թումանյան փող.",
          "value": "Թումանյան փող."
        },
        {
          "label": "Ի. Ալիխանյան /Վոդոպյանով/ փող.",
          "value": "Ի. Ալիխանյան /Վոդոպյանով/ փող."
        },
        {
          "label": "Իսակով պող.",
          "value": "Իսակով պող."
        },
        {
          "label": "Իսահակյան փող.",
          "value": "Իսահակյան փող."
        },
        {
          "label": "Իսրաելյան փող.",
          "value": "Իսրաելյան փող."
        },
        {
          "label": "Իտալիա փող.",
          "value": "Իտալիա փող."
        },
        {
          "label": "Լալայանց փող.",
          "value": "Լալայանց փող."
        },
        {
          "label": "Լամբրոն /Մակարենկո/ փող.",
          "value": "Լամբրոն /Մակարենկո/ փող."
        },
        {
          "label": "Լամբրոն փող.",
          "value": "Լամբրոն փող."
        },
        {
          "label": "Լեո փող.",
          "value": "Լեո փող."
        },
        {
          "label": "Լեռ Կամսար փող.",
          "value": "Լեռ Կամսար փող."
        },
        {
          "label": "Խանջյան փող.",
          "value": "Խանջյան փող."
        },
        {
          "label": "Խմելինսկի փող.",
          "value": "Խմելինսկի փող."
        },
        {
          "label": "Խորենացի /Մարքս/ փող.",
          "value": "Խորենացի /Մարքս/ փող."
        },
        {
          "label": "Խորենացի 1 փակ.",
          "value": "Խորենացի 1 փակ."
        },
        {
          "label": "Խորենացի 2 նրբ.",
          "value": "Խորենացի 2 նրբ."
        },
        {
          "label": "Խորենացի 2 փակ.",
          "value": "Խորենացի 2 փակ."
        },
        {
          "label": "Խորենացի 3 նրբ.",
          "value": "Խորենացի 3 նրբ."
        },
        {
          "label": "Խորհրդարանի փող.",
          "value": "Խորհրդարանի փող."
        },
        {
          "label": "Ծատուրյան փող.",
          "value": "Ծատուրյան փող."
        },
        {
          "label": "Ծիծեռնակաբերդի խճ.",
          "value": "Ծիծեռնակաբերդի խճ."
        },
        {
          "label": "Ծխախոտագործներ փող.",
          "value": "Ծխախոտագործներ փող."
        },
        {
          "label": "Կ. Դեմիրճյան փող.",
          "value": "Կ. Դեմիրճյան փող."
        },
        {
          "label": "Կաշեգործներ փող",
          "value": "Կաշեգործներ փող"
        },
        {
          "label": "Կիլիկիա թաղ.",
          "value": "Կիլիկիա թաղ."
        },
        {
          "label": "Կոզեռնի փող.",
          "value": "Կոզեռնի փող."
        },
        {
          "label": "Կոնդ թաղ.",
          "value": "Կոնդ թաղ."
        },
        {
          "label": "Կոնդ փող.",
          "value": "Կոնդ փող."
        },
        {
          "label": "Կորի փող.",
          "value": "Կորի փող."
        },
        {
          "label": "Կորյուն 1 նրբ.",
          "value": "Կորյուն 1 նրբ."
        },
        {
          "label": "Կորյուն 2 նրբ.",
          "value": "Կորյուն 2 նրբ."
        },
        {
          "label": "Կորյուն փող.",
          "value": "Կորյուն փող."
        },
        {
          "label": "Հ. Շահինյան փող./Այգեստան/8 փող.",
          "value": "Հ. Շահինյան փող./Այգեստան/8 փող."
        },
        {
          "label": "Հանրապետության/Ալավերդյան/ 3 նրբ.",
          "value": "Հանրապետության/Ալավերդյան/ 3 նրբ."
        },
        {
          "label": "Հանրապետության/Ալավերդյան/ փող.",
          "value": "Հանրապետության/Ալավերդյան/ փող."
        },
        {
          "label": "Հերացի փող.",
          "value": "Հերացի փող."
        },
        {
          "label": "Հին Երևանցի փող.",
          "value": "Հին Երևանցի փող."
        },
        {
          "label": "Հյուսիսային պող.",
          "value": "Հյուսիսային պող."
        },
        {
          "label": "Հրազդանի Կիրճ",
          "value": "Հրազդանի Կիրճ"
        },
        {
          "label": "Ձորափ փող.",
          "value": "Ձորափ փող."
        },
        {
          "label": "Ղազար Փարպեցի փող.",
          "value": "Ղազար Փարպեցի փող."
        },
        {
          "label": "Մ. Գրիգորյան փող.",
          "value": "Մ. Գրիգորյան փող."
        },
        {
          "label": "Մ. Մաշտոց պող.",
          "value": "Մ. Մաշտոց պող."
        },
        {
          "label": "Մ. Մկրտչյան փող.",
          "value": "Մ. Մկրտչյան փող."
        },
        {
          "label": "Մայիսյան փող.",
          "value": "Մայիսյան փող."
        },
        {
          "label": "Մելիք Ադամյան փող.",
          "value": "Մելիք Ադամյան փող."
        },
        {
          "label": "Մյասնիկյան պող.",
          "value": "Մյասնիկյան պող."
        },
        {
          "label": "Մոսկովյան փակ.",
          "value": "Մոսկովյան փակ."
        },
        {
          "label": "Մոսկովյան փող.",
          "value": "Մոսկովյան փող."
        },
        {
          "label": "Նալբանդյան փող.",
          "value": "Նալբանդյան փող."
        },
        {
          "label": "Նար-Դոս 1 նրբ.",
          "value": "Նար-Դոս 1 նրբ."
        },
        {
          "label": "Նար-Դոս 2 նրբ.",
          "value": "Նար-Դոս 2 նրբ."
        },
        {
          "label": "Նար-Դոս 3 նրբ.",
          "value": "Նար-Դոս 3 նրբ."
        },
        {
          "label": "Նար-Դոս փող.",
          "value": "Նար-Դոս փող."
        },
        {
          "label": "Նորագյուղ թաղ.",
          "value": "Նորագյուղ թաղ."
        },
        {
          "label": "Շահինյան փող.",
          "value": "Շահինյան փող."
        },
        {
          "label": "Շարա Տալյան փող.",
          "value": "Շարա Տալյան փող."
        },
        {
          "label": "Շարիմանյան փող.",
          "value": "Շարիմանյան փող."
        },
        {
          "label": "Չայկովսկի փող.",
          "value": "Չայկովսկի փող."
        },
        {
          "label": "Չարենց 1 նրբ.",
          "value": "Չարենց 1 նրբ."
        },
        {
          "label": "Չարենց 2 նրբ.",
          "value": "Չարենց 2 նրբ."
        },
        {
          "label": "Չարենց փող.",
          "value": "Չարենց փող."
        },
        {
          "label": "Պարոնյան փող.",
          "value": "Պարոնյան փող."
        },
        {
          "label": "Պետրոս Ադամյան փող.",
          "value": "Պետրոս Ադամյան փող."
        },
        {
          "label": "Պլեխանով փող.",
          "value": "Պլեխանով փող."
        },
        {
          "label": "Պուշկին փող.",
          "value": "Պուշկին փող."
        },
        {
          "label": "Պռոշյան 1 նրբ.",
          "value": "Պռոշյան 1 նրբ."
        },
        {
          "label": "Պռոշյան 1 փակ.",
          "value": "Պռոշյան 1 փակ."
        },
        {
          "label": "Պռոշյան փող.",
          "value": "Պռոշյան փող."
        },
        {
          "label": "Պրահա փող.",
          "value": "Պրահա փող."
        },
        {
          "label": "Ռաֆայել Լեմկին/Բաղրամյան 1 փակ.",
          "value": "Ռաֆայել Լեմկին/Բաղրամյան 1 փակ."
        },
        {
          "label": "Ռոստոմ փող.",
          "value": "Ռոստոմ փող."
        },
        {
          "label": "Ռուսթավելի փող.",
          "value": "Ռուսթավելի փող."
        },
        {
          "label": "Սախարովի փող.",
          "value": "Սախարովի փող."
        },
        {
          "label": "Սայաթ-Նովա պող.",
          "value": "Սայաթ-Նովա պող."
        },
        {
          "label": "Սարալանջ փող.",
          "value": "Սարալանջ փող."
        },
        {
          "label": "Սարմեն /Ջրաշատ/ փող.",
          "value": "Սարմեն /Ջրաշատ/ փող."
        },
        {
          "label": "Սարյան փող.",
          "value": "Սարյան փող."
        },
        {
          "label": "Սևանի փող.",
          "value": "Սևանի փող."
        },
        {
          "label": "Սիմեոն Երևանցի փող.",
          "value": "Սիմեոն Երևանցի փող."
        },
        {
          "label": "Սիսվան /Չայկինա/ փող.",
          "value": "Սիսվան /Չայկինա/ փող."
        },
        {
          "label": "Սիսվան փող.",
          "value": "Սիսվան փող."
        },
        {
          "label": "Սովխոզյան փող.",
          "value": "Սովխոզյան փող."
        },
        {
          "label": "Սուրբ Հովհաննես փող.",
          "value": "Սուրբ Հովհաննես փող."
        },
        {
          "label": "Սուրենյանց փող.",
          "value": "Սուրենյանց փող."
        },
        {
          "label": "Սպենդիարյան փող.",
          "value": "Սպենդիարյան փող."
        },
        {
          "label": "Վ. Սարգսյան փող.",
          "value": "Վ. Սարգսյան փող."
        },
        {
          "label": "Վարդանանց 2 նրբ.",
          "value": "Վարդանանց 2 նրբ."
        },
        {
          "label": "Վարդանանց 2 փակ.",
          "value": "Վարդանանց 2 փակ."
        },
        {
          "label": "Վարդանանց փող.",
          "value": "Վարդանանց փող."
        },
        {
          "label": "Վարդանանց փող. փակ.",
          "value": "Վարդանանց փող. փակ."
        },
        {
          "label": "Վերին Անտառային փող.",
          "value": "Վերին Անտառային փող."
        },
        {
          "label": "Վրացյան փող.",
          "value": "Վրացյան փող."
        },
        {
          "label": "Տարսոն փող.",
          "value": "Տարսոն փող."
        },
        {
          "label": "Տեր-Գրիգորյան փող.",
          "value": "Տեր-Գրիգորյան փող."
        },
        {
          "label": "Տերյան փող.",
          "value": "Տերյան փող."
        },
        {
          "label": "Տիգրան Մեծ 1 նրբ.",
          "value": "Տիգրան Մեծ 1 նրբ."
        },
        {
          "label": "Տիգրան Մեծ 2 նրբ.",
          "value": "Տիգրան Մեծ 2 նրբ."
        },
        {
          "label": "Տիգրան Մեծ 3 նրբ.",
          "value": "Տիգրան Մեծ 3 նրբ."
        },
        {
          "label": "Տիգրան Մեծ պող.",
          "value": "Տիգրան Մեծ պող."
        },
        {
          "label": "Տոլստոյ փող.",
          "value": "Տոլստոյ փող."
        },
        {
          "label": "Տպագրիչներ փող.",
          "value": "Տպագրիչներ փող."
        },
        {
          "label": "Քաջազնունի /Կուզնեցով/ փող.",
          "value": "Քաջազնունի /Կուզնեցով/ փող."
        },
        {
          "label": "Քրիստափոր 2 նրբ.",
          "value": "Քրիստափոր 2 նրբ."
        },
        {
          "label": "Քրիստափոր 3 նրբ.",
          "value": "Քրիստափոր 3 նրբ."
        },
        {
          "label": "Քրիստափոր փող.",
          "value": "Քրիստափոր փող."
        },
        {
          "label": "Օստրովսկի փող.",
          "value": "Օստրովսկի փող."
        },
        {
          "label": "Ֆիզկուլտուրիկներ փող.",
          "value": "Ֆիզկուլտուրիկներ փող."
        },
        {
          "label": "Ֆիրդուսի փող.",
          "value": "Ֆիրդուսի փող."
        },
        {
          "label": "Ֆրանց Վերֆել",
          "value": "Ֆրանց Վերֆել"
        },
        {
          "label": "Ֆրիկի փող.",
          "value": "Ֆրիկի փող."
        }
      ]
    },
    {
      communities: 'Մալաթիա-Սեբաստիա',
      info: [
        {
          "label": "Ա. Բաբաջանյան փող.",
          "value": "Ա. Բաբաջանյան փող."
        },
        {
          "label": "Ա. Սարգսյան",
          "value": "Ա. Սարգսյան"
        },
        {
          "label": "Աղաբաբյան փող.",
          "value": "Աղաբաբյան փող."
        },
        {
          "label": "Ամառանոցային փող.",
          "value": "Ամառանոցային փող."
        },
        {
          "label": "Արարատյան 1 զանգ.",
          "value": "Արարատյան 1 զանգ."
        },
        {
          "label": "Արարատյան 2 զանգ.",
          "value": "Արարատյան 2 զանգ."
        },
        {
          "label": "Արարատյան 2 նրբ.",
          "value": "Արարատյան 2 նրբ."
        },
        {
          "label": "Արմին Վեգներ փող.",
          "value": "Արմին Վեգներ փող."
        },
        {
          "label": "Բատիկյան փող.",
          "value": "Բատիկյան փող."
        },
        {
          "label": "Գ. Զոհրապ փող.",
          "value": "Գ. Զոհրապ փող."
        },
        {
          "label": "Գագարին փող.",
          "value": "Գագարին փող."
        },
        {
          "label": "Գոշ փող.",
          "value": "Գոշ փող."
        },
        {
          "label": "Գուսան Շերամ փող.",
          "value": "Գուսան Շերամ փող."
        },
        {
          "label": "Դ. Վարուժան փող.",
          "value": "Դ. Վարուժան փող."
        },
        {
          "label": "Դուրյան փող.",
          "value": "Դուրյան փող."
        },
        {
          "label": "Զորավար Անդրանիկ փող.",
          "value": "Զորավար Անդրանիկ փող."
        },
        {
          "label": "Իսակով պող.",
          "value": "Իսակով պող."
        },
        {
          "label": "Իսրայել Օրի փող.",
          "value": "Իսրայել Օրի փող."
        },
        {
          "label": "Լենինգրադյան փող.",
          "value": "Լենինգրադյան փող."
        },
        {
          "label": "Ծերենց 2 նրբ.",
          "value": "Ծերենց 2 նրբ."
        },
        {
          "label": "Ծերենց փող.",
          "value": "Ծերենց փող."
        },
        {
          "label": "Ծիծերնակաբեռդի խճ.",
          "value": "Ծիծերնակաբեռդի խճ."
        },
        {
          "label": "Կուրղինյան փող.",
          "value": "Կուրղինյան փող."
        },
        {
          "label": "Հ/Ա Ա-1 թաղ.",
          "value": "Հ/Ա Ա-1 թաղ."
        },
        {
          "label": "Հ/Ա Ա-2 թաղ.",
          "value": "Հ/Ա Ա-2 թաղ."
        },
        {
          "label": "Հ/Ա Ա-3 թաղ.",
          "value": "Հ/Ա Ա-3 թաղ."
        },
        {
          "label": "Հ/Ա Բ-1 թաղ.",
          "value": "Հ/Ա Բ-1 թաղ."
        },
        {
          "label": "Հ/Ա Բ-2 թաղ.",
          "value": "Հ/Ա Բ-2 թաղ."
        },
        {
          "label": "Հ/Ա Բ-3 թաղ.",
          "value": "Հ/Ա Բ-3 թաղ."
        },
        {
          "label": "Հ/Ա Բ-4 թաղ.",
          "value": "Հ/Ա Բ-4 թաղ."
        },
        {
          "label": "Հաղթանակ 1 փող.",
          "value": "Հաղթանակ 1 փող."
        },
        {
          "label": "Հաղթանակ 10 փող.",
          "value": "Հաղթանակ 10 փող."
        },
        {
          "label": "Հաղթանակ 11 փող.",
          "value": "Հաղթանակ 11 փող."
        },
        {
          "label": "Հաղթանակ 18 փող.",
          "value": "Հաղթանակ 18 փող."
        },
        {
          "label": "Հաղթանակ 2 փող.",
          "value": "Հաղթանակ 2 փող."
        },
        {
          "label": "Հաղթանակ 2 փող., 1 նրբ.",
          "value": "Հաղթանակ 2 փող., 1 նրբ."
        },
        {
          "label": "Հաղթանակ 3 փող.",
          "value": "Հաղթանակ 3 փող."
        },
        {
          "label": "Հաղթանակ 4 նրբ.",
          "value": "Հաղթանակ 4 նրբ."
        },
        {
          "label": "Հաղթանակ 4 փող.",
          "value": "Հաղթանակ 4 փող."
        },
        {
          "label": "Հաղթանակ 5 փող.",
          "value": "Հաղթանակ 5 փող."
        },
        {
          "label": "Հաղթանակ 6 փող.",
          "value": "Հաղթանակ 6 փող."
        },
        {
          "label": "Հաղթանակ 7 փող.",
          "value": "Հաղթանակ 7 փող."
        },
        {
          "label": "Հաղթանակ 8 փող.",
          "value": "Հաղթանակ 8 փող."
        },
        {
          "label": "Հաղթանակ 9 փող.",
          "value": "Հաղթանակ 9 փող."
        },
        {
          "label": "Հաղթանակ թաղ.",
          "value": "Հաղթանակ թաղ."
        },
        {
          "label": "Հաղթանակ տեղ.",
          "value": "Հաղթանակ տեղ."
        },
        {
          "label": "Հովնաթան փող.",
          "value": "Հովնաթան փող."
        },
        {
          "label": "Ճառագայթային փող.",
          "value": "Ճառագայթային փող."
        },
        {
          "label": "Մալաթիա փող.",
          "value": "Մալաթիա փող."
        },
        {
          "label": "Մամիկոնյան փող.",
          "value": "Մամիկոնյան փող."
        },
        {
          "label": "Մանուկյան փող.",
          "value": "Մանուկյան փող."
        },
        {
          "label": "Մարտիկյան փող.",
          "value": "Մարտիկյան փող."
        },
        {
          "label": "Մելիքյան փող.",
          "value": "Մելիքյան փող."
        },
        {
          "label": "Մեծարենց փող.",
          "value": "Մեծարենց փող."
        },
        {
          "label": "Միրաքյան փող.",
          "value": "Միրաքյան փող."
        },
        {
          "label": "Միքայելյան փող.",
          "value": "Միքայելյան փող."
        },
        {
          "label": "Մուշ թաղ.",
          "value": "Մուշ թաղ."
        },
        {
          "label": "Նոյ թաղ.",
          "value": "Նոյ թաղ."
        },
        {
          "label": "Շահումյան 1 փող.",
          "value": "Շահումյան 1 փող."
        },
        {
          "label": "Շահումյան 14 փող.",
          "value": "Շահումյան 14 փող."
        },
        {
          "label": "Շահումյան 16 փող.",
          "value": "Շահումյան 16 փող."
        },
        {
          "label": "Շահումյան 17 փող.",
          "value": "Շահումյան 17 փող."
        },
        {
          "label": "Շահումյան 18 փող.",
          "value": "Շահումյան 18 փող."
        },
        {
          "label": "Շահումյան 2 փող.",
          "value": "Շահումյան 2 փող."
        },
        {
          "label": "Շահումյան 3 փող.",
          "value": "Շահումյան 3 փող."
        },
        {
          "label": "Շահումյան 4 փող.",
          "value": "Շահումյան 4 փող."
        },
        {
          "label": "Շահումյան 5 փող.",
          "value": "Շահումյան 5 փող."
        },
        {
          "label": "Շահումյան 6 փող.",
          "value": "Շահումյան 6 փող."
        },
        {
          "label": "Շահումյան 7 փող.",
          "value": "Շահումյան 7 փող."
        },
        {
          "label": "Շահումյան 8 փող.",
          "value": "Շահումյան 8 փող."
        },
        {
          "label": "Շահումյան 9 փող.",
          "value": "Շահումյան 9 փող."
        },
        {
          "label": "Շրջանային փող.",
          "value": "Շրջանային փող."
        },
        {
          "label": "Ջիվանի 1 նրբ.",
          "value": "Ջիվանի 1 նրբ."
        },
        {
          "label": "Ջիվանի փող.",
          "value": "Ջիվանի փող."
        },
        {
          "label": "Ռ. Մելիքյան փող.",
          "value": "Ռ. Մելիքյան փող."
        },
        {
          "label": "Սեբաստիա փող.",
          "value": "Սեբաստիա փող."
        },
        {
          "label": "Սվաճյան փող.",
          "value": "Սվաճյան փող."
        },
        {
          "label": "Վանթյան փող.",
          "value": "Վանթյան փող."
        },
        {
          "label": "Տիչինա փող.",
          "value": "Տիչինա փող."
        },
        {
          "label": "Տիչինայի 1 նրբ.",
          "value": "Տիչինայի 1 նրբ."
        },
        {
          "label": "Տիտով փող.",
          "value": "Տիտով փող."
        },
        {
          "label": "Րաֆֆի փող.",
          "value": "Րաֆֆի փող."
        },
        {
          "label": "Քեսաբցիներ փող.",
          "value": "Քեսաբցիներ փող."
        },
        {
          "label": "Քուչակ փող.",
          "value": "Քուչակ փող."
        },
        {
          "label": "Օհանով փող.",
          "value": "Օհանով փող."
        },
        {
          "label": "Օտյան փող.",
          "value": "Օտյան փող."
        }
      ]
    },
    {
      communities: 'Նոր-Նորք',
      info: [
        {
          "label": "Ա. Հովհաննիսյան փող.",
          "value": "Ա. Հովհաննիսյան փող."
        },
        {
          "label": "Բ. Մուրադյան փող.",
          "value": "Բ. Մուրադյան փող."
        },
        {
          "label": "Բագրևանդ թաղ.",
          "value": "Բագրևանդ թաղ."
        },
        {
          "label": "Բագրևանդ փող.",
          "value": "Բագրևանդ փող."
        },
        {
          "label": "Բակունց 2 նրբ.",
          "value": "Բակունց 2 նրբ."
        },
        {
          "label": "Բակունց փող.",
          "value": "Բակունց փող."
        },
        {
          "label": "Բաղյան 1 նրբ.",
          "value": "Բաղյան 1 նրբ."
        },
        {
          "label": "Բաղյան 2 նրբ.",
          "value": "Բաղյան 2 նրբ."
        },
        {
          "label": "Բաղյան փող.",
          "value": "Բաղյան փող."
        },
        {
          "label": "Բանավան թաղ.",
          "value": "Բանավան թաղ."
        },
        {
          "label": "Բարյան փող.",
          "value": "Բարյան փող."
        },
        {
          "label": "Բորյան փող.",
          "value": "Բորյան փող."
        },
        {
          "label": "Գալշոյան փող.",
          "value": "Գալշոյան փող."
        },
        {
          "label": "Գայի պող.",
          "value": "Գայի պող."
        },
        {
          "label": "Գյուլիքևխյան նրբ.",
          "value": "Գյուլիքևխյան նրբ."
        },
        {
          "label": "Գյուլիքևխյան փող.",
          "value": "Գյուլիքևխյան փող."
        },
        {
          "label": "Գյուրջյան փող.",
          "value": "Գյուրջյան փող."
        },
        {
          "label": "Դ. Մալյան նրբ",
          "value": "Դ. Մալյան նրբ"
        },
        {
          "label": "Դ. Մալյան/Ավան 9/ նրբ.",
          "value": "Դ. Մալյան/Ավան 9/ նրբ."
        },
        {
          "label": "Դ. Մալյան/Ավան 9/ փող.",
          "value": "Դ. Մալյան/Ավան 9/ փող."
        },
        {
          "label": "Դավիթ Բեկ փող.",
          "value": "Դավիթ Բեկ փող."
        },
        {
          "label": "Թևոսյան փող.",
          "value": "Թևոսյան փող."
        },
        {
          "label": "Թոթովենց նրբ.",
          "value": "Թոթովենց նրբ."
        },
        {
          "label": "Թոթովենց փող.",
          "value": "Թոթովենց փող."
        },
        {
          "label": "Լ. Սարգսյան փող.",
          "value": "Լ. Սարգսյան փող."
        },
        {
          "label": "Լյուքսեմբուրգ 2 նրբ.",
          "value": "Լյուքսեմբուրգ 2 նրբ."
        },
        {
          "label": "Լյուքսեմբուրգ փող.",
          "value": "Լյուքսեմբուրգ փող."
        },
        {
          "label": "Լյուքսեմբուրգի 1 նրբ.",
          "value": "Լյուքսեմբուրգի 1 նրբ."
        },
        {
          "label": "Լվովյան փող.",
          "value": "Լվովյան փող."
        },
        {
          "label": "Կարախանյան փող.",
          "value": "Կարախանյան փող."
        },
        {
          "label": "Հ. Ավետիսյան փող.",
          "value": "Հ. Ավետիսյան փող."
        },
        {
          "label": "Հովսեփ Էմին փող.",
          "value": "Հովսեփ Էմին փող."
        },
        {
          "label": "Մայակ թաղ.",
          "value": "Մայակ թաղ."
        },
        {
          "label": "Մառ նրբ.",
          "value": "Մառ նրբ."
        },
        {
          "label": "Մառ փող.",
          "value": "Մառ փող."
        },
        {
          "label": "Միկոյան փող.",
          "value": "Միկոյան փող."
        },
        {
          "label": "Մինսկ փող.",
          "value": "Մինսկ փող."
        },
        {
          "label": "Մյասնիկյան պող.",
          "value": "Մյասնիկյան պող."
        },
        {
          "label": "Մոլդովական փող.",
          "value": "Մոլդովական փող."
        },
        {
          "label": "Նանսեն փող.",
          "value": "Նանսեն փող."
        },
        {
          "label": "Նորքի 1 զանգ.",
          "value": "Նորքի 1 զանգ."
        },
        {
          "label": "Նորքի 17 փող.",
          "value": "Նորքի 17 փող."
        },
        {
          "label": "Նորքի 17 փող. նրբ.",
          "value": "Նորքի 17 փող. նրբ."
        },
        {
          "label": "Նորքի 2 զանգ.",
          "value": "Նորքի 2 զանգ."
        },
        {
          "label": "Նորքի 3 զանգ.",
          "value": "Նորքի 3 զանգ."
        },
        {
          "label": "Նորքի 4 զանգ.",
          "value": "Նորքի 4 զանգ."
        },
        {
          "label": "Նորքի 5 զանգ.",
          "value": "Նորքի 5 զանգ."
        },
        {
          "label": "Նորքի 6 զանգ.",
          "value": "Նորքի 6 զանգ."
        },
        {
          "label": "Նորքի 7 զանգ.",
          "value": "Նորքի 7 զանգ."
        },
        {
          "label": "Նորքի 8 զանգ.",
          "value": "Նորքի 8 զանգ."
        },
        {
          "label": "Նորքի 9 զանգ.",
          "value": "Նորքի 9 զանգ."
        },
        {
          "label": "Շոպրոն 1 նրբ.",
          "value": "Շոպրոն 1 նրբ."
        },
        {
          "label": "Շոպրոն 2 նրբ.",
          "value": "Շոպրոն 2 նրբ."
        },
        {
          "label": "Շոպրոն 3 նրբ.",
          "value": "Շոպրոն 3 նրբ."
        },
        {
          "label": "Շոպրոն փող.",
          "value": "Շոպրոն փող."
        },
        {
          "label": "Ջուղա փող.",
          "value": "Ջուղա փող."
        },
        {
          "label": "Ս. Սաֆարյան փող.",
          "value": "Ս. Սաֆարյան փող."
        },
        {
          "label": "Ստեփանյան փող.",
          "value": "Ստեփանյան փող."
        },
        {
          "label": "Վախթանգով փող.",
          "value": "Վախթանգով փող."
        },
        {
          "label": "Վիլնյուս փող.",
          "value": "Վիլնյուս փող."
        },
        {
          "label": "Քոչարյան փող.",
          "value": "Քոչարյան փող."
        },
        {
          "label": "Քոչինյան փող.",
          "value": "Քոչինյան փող."
        }
      ]
    },
    {
      communities: 'Նորք-Մարաշ',
      info: [
        {
          "label": "Այգեստան 11 փող., 1 նրբ.",
          "value": "Այգեստան 11 փող., 1 նրբ."
        },
        {
          "label": "Արմենակյան /Ամառանոցային/ 1 նրբ.",
          "value": "Արմենակյան /Ամառանոցային/ 1 նրբ."
        },
        {
          "label": "Արմենակյան /Ամառանոցային/ 1 փակ.",
          "value": "Արմենակյան /Ամառանոցային/ 1 փակ."
        },
        {
          "label": "Արմենակյան /Ամառանոցային/ փող.",
          "value": "Արմենակյան /Ամառանոցային/ փող."
        },
        {
          "label": "Գ. Հովսեփյան փող.",
          "value": "Գ. Հովսեփյան փող."
        },
        {
          "label": "Դավիթ-Բեկ փող.",
          "value": "Դավիթ-Բեկ փող."
        },
        {
          "label": "Նորք թաղ.",
          "value": "Նորք թաղ."
        },
        {
          "label": "Նորք Մարաշ",
          "value": "Նորք Մարաշ"
        },
        {
          "label": "Նորքի 1 նրբ.",
          "value": "Նորքի 1 նրբ."
        },
        {
          "label": "Նորքի 1 փող.",
          "value": "Նորքի 1 փող."
        },
        {
          "label": "Նորքի 10 փող.",
          "value": "Նորքի 10 փող."
        },
        {
          "label": "Նորքի 11 փող.",
          "value": "Նորքի 11 փող."
        },
        {
          "label": "Նորքի 13 փող.",
          "value": "Նորքի 13 փող."
        },
        {
          "label": "Նորքի 15 փող.",
          "value": "Նորքի 15 փող."
        },
        {
          "label": "Նորքի 17 փող.",
          "value": "Նորքի 17 փող."
        },
        {
          "label": "Նորքի 2 նրբ.",
          "value": "Նորքի 2 նրբ."
        },
        {
          "label": "Նորքի 2 փող.",
          "value": "Նորքի 2 փող."
        },
        {
          "label": "Նորքի 3 փող.",
          "value": "Նորքի 3 փող."
        },
        {
          "label": "Նորքի 4 փող.",
          "value": "Նորքի 4 փող."
        },
        {
          "label": "Նորքի 5 փող.",
          "value": "Նորքի 5 փող."
        },
        {
          "label": "Նորքի 6 փող.",
          "value": "Նորքի 6 փող."
        },
        {
          "label": "Նորքի 7 փող.",
          "value": "Նորքի 7 փող."
        },
        {
          "label": "Նորքի 8 փող.",
          "value": "Նորքի 8 փող."
        },
        {
          "label": "Նորքի 9 փող.",
          "value": "Նորքի 9 փող."
        },
        {
          "label": "Նորքի այգիներ",
          "value": "Նորքի այգիներ"
        },
        {
          "label": "Չարենց 1 նրբ.",
          "value": "Չարենց 1 նրբ."
        },
        {
          "label": "Ս. Խանզադյան փող.",
          "value": "Ս. Խանզադյան փող."
        }
      ]
    },
    {
      communities: 'Նուբարաշեն',
      info: [
        {
          "label": "Մասիս -Ա թաղ.",
          "value": "Մասիս -Ա թաղ."
        },
        {
          "label": "Նուբարաշեն  Բ-8",
          "value": "Նուբարաշեն  Բ-8"
        },
        {
          "label": "Նուբարաշեն 10 փող.",
          "value": "Նուբարաշեն 10 փող."
        },
        {
          "label": "Նուբարաշեն 11 փող.",
          "value": "Նուբարաշեն 11 փող."
        },
        {
          "label": "Նուբարաշեն 12 փող.",
          "value": "Նուբարաշեն 12 փող."
        },
        {
          "label": "Նուբարաշեն 13 փող.",
          "value": "Նուբարաշեն 13 փող."
        },
        {
          "label": "Նուբարաշեն 15 փող.",
          "value": "Նուբարաշեն 15 փող."
        },
        {
          "label": "Նուբարաշեն 16 փող.",
          "value": "Նուբարաշեն 16 փող."
        },
        {
          "label": "Նուբարաշեն 17 փող.",
          "value": "Նուբարաշեն 17 փող."
        },
        {
          "label": "Նուբարաշեն 2 փող.",
          "value": "Նուբարաշեն 2 փող."
        },
        {
          "label": "Նուբարաշեն 20 փող.",
          "value": "Նուբարաշեն 20 փող."
        },
        {
          "label": "Նուբարաշեն 3 փող.",
          "value": "Նուբարաշեն 3 փող."
        },
        {
          "label": "Նուբարաշեն 5 փող.",
          "value": "Նուբարաշեն 5 փող."
        },
        {
          "label": "Նուբարաշեն 6 փող.",
          "value": "Նուբարաշեն 6 փող."
        },
        {
          "label": "Նուբարաշեն 7 փող.",
          "value": "Նուբարաշեն 7 փող."
        },
        {
          "label": "Նուբարաշեն 8 թաղ.",
          "value": "Նուբարաշեն 8 թաղ."
        },
        {
          "label": "Նուբարաշեն 8 փող.",
          "value": "Նուբարաշեն 8 փող."
        },
        {
          "label": "Նուբարաշեն 9 փող.",
          "value": "Նուբարաշեն 9 փող."
        },
        {
          "label": "Նուբարաշեն Ա-2, 13 թաղ.",
          "value": "Նուբարաշեն Ա-2, 13 թաղ."
        },
        {
          "label": "Նուբարաշեն Ա-6 թաղ",
          "value": "Նուբարաշեն Ա-6 թաղ"
        },
        {
          "label": "Նուբարաշեն Ա-8 թաղ.",
          "value": "Նուբարաշեն Ա-8 թաղ."
        },
        {
          "label": "Նուբարաշեն Բ - 10 թաղ.",
          "value": "Նուբարաշեն Բ - 10 թաղ."
        },
        {
          "label": "Նուբարաշեն Բ-1 թաղ.",
          "value": "Նուբարաշեն Բ-1 թաղ."
        },
        {
          "label": "Նուբարաշեն Բ-6 թաղ.",
          "value": "Նուբարաշեն Բ-6 թաղ."
        },
        {
          "label": "Նուբարաշեն Բ-7 թաղ.",
          "value": "Նուբարաշեն Բ-7 թաղ."
        },
        {
          "label": "Նուբարաշենի Գ-10 թաղ.",
          "value": "Նուբարաշենի Գ-10 թաղ."
        },
        {
          "label": "Նուբարաշենի Գ-7 թաղ.",
          "value": "Նուբարաշենի Գ-7 թաղ."
        },
        {
          "label": "Չմշկածագ փող.",
          "value": "Չմշկածագ փող."
        },
        {
          "label": "Չնքուշ փող.",
          "value": "Չնքուշ փող."
        }
      ]
    },
    {
      communities: 'Շենգավիթ',
      info: [
        {
          "label": "Աէրացիա թաղ.",
          "value": "Աէրացիա թաղ."
        },
        {
          "label": "Արագած փող.",
          "value": "Արագած փող."
        },
        {
          "label": "Արարատյան փող.",
          "value": "Արարատյան փող."
        },
        {
          "label": "Արին Բերդ փող.",
          "value": "Արին Բերդ փող."
        },
        {
          "label": "Արշակունյաց պող.",
          "value": "Արշակունյաց պող."
        },
        {
          "label": "Արտաշատի խճ.",
          "value": "Արտաշատի խճ."
        },
        {
          "label": "Արտաշատի խճ. 9 փող.",
          "value": "Արտաշատի խճ. 9 փող."
        },
        {
          "label": "Արտաշիսյան 2 նրբ.",
          "value": "Արտաշիսյան 2 նրբ."
        },
        {
          "label": "Արտաշիսյան փող.",
          "value": "Արտաշիսյան փող."
        },
        {
          "label": "Արցախ փող.",
          "value": "Արցախ փող."
        },
        {
          "label": "Բ. Պետրոսյան փող.",
          "value": "Բ. Պետրոսյան փող."
        },
        {
          "label": "Բագրատունյաց 1 նրբ.",
          "value": "Բագրատունյաց 1 նրբ."
        },
        {
          "label": "Բագրատունյաց 2 նրբ.",
          "value": "Բագրատունյաց 2 նրբ."
        },
        {
          "label": "Բագրատունյաց 3 նրբ.",
          "value": "Բագրատունյաց 3 նրբ."
        },
        {
          "label": "Բագրատունյաց 4 նրբ.",
          "value": "Բագրատունյաց 4 նրբ."
        },
        {
          "label": "Բագրատունյաց 6 փակ.",
          "value": "Բագրատունյաց 6 փակ."
        },
        {
          "label": "Բագրատունյաց փող.",
          "value": "Բագրատունյաց փող."
        },
        {
          "label": "Բայդուկով փող.",
          "value": "Բայդուկով փող."
        },
        {
          "label": "Բունյաթյան փող.",
          "value": "Բունյաթյան փող."
        },
        {
          "label": "Գ. Նժդեհ փող.",
          "value": "Գ. Նժդեհ փող."
        },
        {
          "label": "Գորգագործներ փող.",
          "value": "Գորգագործներ փող."
        },
        {
          "label": "Գրաբարներ փող.",
          "value": "Գրաբարներ փող."
        },
        {
          "label": "Դոդոխյան փող.",
          "value": "Դոդոխյան փող."
        },
        {
          "label": "Եղբայրության 1 նրբ.",
          "value": "Եղբայրության 1 նրբ."
        },
        {
          "label": "Եղբայրության փող.",
          "value": "Եղբայրության փող."
        },
        {
          "label": "Եսայան փող.",
          "value": "Եսայան փող."
        },
        {
          "label": "Թ. Սարյան փող.",
          "value": "Թ. Սարյան փող."
        },
        {
          "label": "Թադևոսյան նրբ.",
          "value": "Թադևոսյան նրբ."
        },
        {
          "label": "Թադևոսյան փող.",
          "value": "Թադևոսյան փող."
        },
        {
          "label": "Թեյշեբան փող.",
          "value": "Թեյշեբան փող."
        },
        {
          "label": "Իսակով պող.",
          "value": "Իսակով պող."
        },
        {
          "label": "Լ. Խաչիկյան փող.",
          "value": "Լ. Խաչիկյան փող."
        },
        {
          "label": "Լազո փող.",
          "value": "Լազո փող."
        },
        {
          "label": "Կաշեգործներ փող.",
          "value": "Կաշեգործներ փող."
        },
        {
          "label": "Կատովսկի փող.",
          "value": "Կատովսկի փող."
        },
        {
          "label": "Կարա Մուրզա փող.",
          "value": "Կարա Մուրզա փող."
        },
        {
          "label": "Կարմիր Բլուր փող.",
          "value": "Կարմիր Բլուր փող."
        },
        {
          "label": "Կողբ 1 փող.",
          "value": "Կողբ 1 փող."
        },
        {
          "label": "Կողբ 2 փող.",
          "value": "Կողբ 2 փող."
        },
        {
          "label": "Հ. Հովհաննիսյան փող.",
          "value": "Հ. Հովհաննիսյան փող."
        },
        {
          "label": "Հ. Հովսեփյան փող.",
          "value": "Հ. Հովսեփյան փող."
        },
        {
          "label": "Հրազդան փող.",
          "value": "Հրազդան փող."
        },
        {
          "label": "Ղարիբջանյան փող.",
          "value": "Ղարիբջանյան փող."
        },
        {
          "label": "Մադոյան փող.",
          "value": "Մադոյան փող."
        },
        {
          "label": "Մայիսի 9 փող.",
          "value": "Մայիսի 9 փող."
        },
        {
          "label": "Մանանդյան /Թելման/ փող.",
          "value": "Մանանդյան /Թելման/ փող."
        },
        {
          "label": "Մանթաշյան 1 նրբ.",
          "value": "Մանթաշյան 1 նրբ."
        },
        {
          "label": "Մանթաշյան փող.",
          "value": "Մանթաշյան փող."
        },
        {
          "label": "Մասիս փող.",
          "value": "Մասիս փող."
        },
        {
          "label": "Մասիսի 1 նրբ.",
          "value": "Մասիսի 1 նրբ."
        },
        {
          "label": "Մարկվարտի փող.",
          "value": "Մարկվարտի փող."
        },
        {
          "label": "Մխչյան փող.",
          "value": "Մխչյան փող."
        },
        {
          "label": "Մուսաելյան փող.",
          "value": "Մուսաելյան փող."
        },
        {
          "label": "Ն Շենգավիթ 11 փող.",
          "value": "Ն Շենգավիթ 11 փող."
        },
        {
          "label": "Ն. Բունիաթյան փող.",
          "value": "Ն. Բունիաթյան փող."
        },
        {
          "label": "Ն. Շենգավիթ 1 փող.",
          "value": "Ն. Շենգավիթ 1 փող."
        },
        {
          "label": "Ն. Շենգավիթ 10 փող.",
          "value": "Ն. Շենգավիթ 10 փող."
        },
        {
          "label": "Ն. Շենգավիթ 11 փող.",
          "value": "Ն. Շենգավիթ 11 փող."
        },
        {
          "label": "Ն. Շենգավիթ 11 փող., 1 նրբ.",
          "value": "Ն. Շենգավիթ 11 փող., 1 նրբ."
        },
        {
          "label": "Ն. Շենգավիթ 12 փող.",
          "value": "Ն. Շենգավիթ 12 փող."
        },
        {
          "label": "Ն. Շենգավիթ 13 փող.",
          "value": "Ն. Շենգավիթ 13 փող."
        },
        {
          "label": "Ն. Շենգավիթ 14 փող.",
          "value": "Ն. Շենգավիթ 14 փող."
        },
        {
          "label": "Ն. Շենգավիթ 15 փող.",
          "value": "Ն. Շենգավիթ 15 փող."
        },
        {
          "label": "Ն. Շենգավիթ 16 փող.",
          "value": "Ն. Շենգավիթ 16 փող."
        },
        {
          "label": "Ն. Շենգավիթ 2 փող.",
          "value": "Ն. Շենգավիթ 2 փող."
        },
        {
          "label": "Ն. Շենգավիթ 3 փող.",
          "value": "Ն. Շենգավիթ 3 փող."
        },
        {
          "label": "Ն. Շենգավիթ 4 փող.",
          "value": "Ն. Շենգավիթ 4 փող."
        },
        {
          "label": "Ն. Շենգավիթ 5 փող.",
          "value": "Ն. Շենգավիթ 5 փող."
        },
        {
          "label": "Ն. Շենգավիթ 6 փող.",
          "value": "Ն. Շենգավիթ 6 փող."
        },
        {
          "label": "Ն. Շենգավիթ 7 փող.",
          "value": "Ն. Շենգավիթ 7 փող."
        },
        {
          "label": "Ն. Շենգավիթ 8 փող.",
          "value": "Ն. Շենգավիթ 8 փող."
        },
        {
          "label": "Ն. Շենգավիթ 9 փող.",
          "value": "Ն. Շենգավիթ 9 փող."
        },
        {
          "label": "Ն. Չարբախ 1 փող.",
          "value": "Ն. Չարբախ 1 փող."
        },
        {
          "label": "Ն. Չարբախ 11 փող.",
          "value": "Ն. Չարբախ 11 փող."
        },
        {
          "label": "Ն. Չարբախ 12 փող.",
          "value": "Ն. Չարբախ 12 փող."
        },
        {
          "label": "Ն. Չարբախ 12 փող. 4-ռդ նռբ.",
          "value": "Ն. Չարբախ 12 փող. 4-ռդ նռբ."
        },
        {
          "label": "Ն. Չարբախ 14 փող.",
          "value": "Ն. Չարբախ 14 փող."
        },
        {
          "label": "Ն. Չարբախ 2 փող.",
          "value": "Ն. Չարբախ 2 փող."
        },
        {
          "label": "Ն. Չարբախ 3 փող.",
          "value": "Ն. Չարբախ 3 փող."
        },
        {
          "label": "Ն. Չարբախ 4 փող.",
          "value": "Ն. Չարբախ 4 փող."
        },
        {
          "label": "Ն. Չարբախ 5 փող.",
          "value": "Ն. Չարբախ 5 փող."
        },
        {
          "label": "Ն. Չարբախ 5 փող. 3 նրբ.",
          "value": "Ն. Չարբախ 5 փող. 3 նրբ."
        },
        {
          "label": "Ն. Չարբախ 6 փող.",
          "value": "Ն. Չարբախ 6 փող."
        },
        {
          "label": "Ն. Չարբախ 7 փող.",
          "value": "Ն. Չարբախ 7 փող."
        },
        {
          "label": "Ն. Չարբախ 8 փող.",
          "value": "Ն. Չարբախ 8 փող."
        },
        {
          "label": "Ն. Չարբախ 9 փող.",
          "value": "Ն. Չարբախ 9 փող."
        },
        {
          "label": "Ն.Խարբերդ 15 փող.",
          "value": "Ն.Խարբերդ 15 փող."
        },
        {
          "label": "Ներքին Շենգավիթ",
          "value": "Ներքին Շենգավիթ"
        },
        {
          "label": "Ներքին Չարբախ",
          "value": "Ներքին Չարբախ"
        },
        {
          "label": "Նիզամի փող.",
          "value": "Նիզամի փող."
        },
        {
          "label": "Նոր Խարբերդ",
          "value": "Նոր Խարբերդ"
        },
        {
          "label": "Նոր Խարբերդ 12 փող.",
          "value": "Նոր Խարբերդ 12 փող."
        },
        {
          "label": "Նոր Խարբերդ 13 փող.",
          "value": "Նոր Խարբերդ 13 փող."
        },
        {
          "label": "Նոր Խարբերդ 18 փող.",
          "value": "Նոր Խարբերդ 18 փող."
        },
        {
          "label": "Նոր Խարբերդ 19 փող.",
          "value": "Նոր Խարբերդ 19 փող."
        },
        {
          "label": "Նոր Խարբերդ 2 թաղ.",
          "value": "Նոր Խարբերդ 2 թաղ."
        },
        {
          "label": "Նոր Խարբերդ 2 փող.",
          "value": "Նոր Խարբերդ 2 փող."
        },
        {
          "label": "Նոր Խարբերդ 20 փող.",
          "value": "Նոր Խարբերդ 20 փող."
        },
        {
          "label": "Նոր Խարբերդ 21 փող.",
          "value": "Նոր Խարբերդ 21 փող."
        },
        {
          "label": "Նոր Խարբերդ 22 փող.",
          "value": "Նոր Խարբերդ 22 փող."
        },
        {
          "label": "Նոր Խարբերդ 23 փող.",
          "value": "Նոր Խարբերդ 23 փող."
        },
        {
          "label": "Նոր Խարբերդ 24 փող.",
          "value": "Նոր Խարբերդ 24 փող."
        },
        {
          "label": "Նոր Խարբերդ 25 փող.",
          "value": "Նոր Խարբերդ 25 փող."
        },
        {
          "label": "Նոր Խարբերդ 27 փող.",
          "value": "Նոր Խարբերդ 27 փող."
        },
        {
          "label": "Նոր Խարբերդ 28 փող.",
          "value": "Նոր Խարբերդ 28 փող."
        },
        {
          "label": "Նոր Խարբերդ 7 փող.",
          "value": "Նոր Խարբերդ 7 փող."
        },
        {
          "label": "Նոր Խարբերդ 8 փող.",
          "value": "Նոր Խարբերդ 8 փող."
        },
        {
          "label": "Նոր Խարբերդ 9 փող.",
          "value": "Նոր Խարբերդ 9 փող."
        },
        {
          "label": "Նորագավիթ",
          "value": "Նորագավիթ"
        },
        {
          "label": "Նորագավիթ 1 փող.",
          "value": "Նորագավիթ 1 փող."
        },
        {
          "label": "Նորագավիթ 1 փող. 2 փակ.",
          "value": "Նորագավիթ 1 փող. 2 փակ."
        },
        {
          "label": "Նորագավիթ 1 փող. 5 փակ.",
          "value": "Նորագավիթ 1 փող. 5 փակ."
        },
        {
          "label": "Նորագավիթ 10 փող.",
          "value": "Նորագավիթ 10 փող."
        },
        {
          "label": "Նորագավիթ 10 փող. 1 նրբ.",
          "value": "Նորագավիթ 10 փող. 1 նրբ."
        },
        {
          "label": "Նորագավիթ 11 փող.",
          "value": "Նորագավիթ 11 փող."
        },
        {
          "label": "Նորագավիթ 12 փող.",
          "value": "Նորագավիթ 12 փող."
        },
        {
          "label": "Նորագավիթ 13 փող.",
          "value": "Նորագավիթ 13 փող."
        },
        {
          "label": "Նորագավիթ 2 փող.",
          "value": "Նորագավիթ 2 փող."
        },
        {
          "label": "Նորագավիթ 3 փող.",
          "value": "Նորագավիթ 3 փող."
        },
        {
          "label": "Նորագավիթ 4 փող.",
          "value": "Նորագավիթ 4 փող."
        },
        {
          "label": "Նորագավիթ 5 փող.",
          "value": "Նորագավիթ 5 փող."
        },
        {
          "label": "Նորագավիթ 6 փող.",
          "value": "Նորագավիթ 6 փող."
        },
        {
          "label": "Նորագավիթ 7 փող.",
          "value": "Նորագավիթ 7 փող."
        },
        {
          "label": "Նորագավիթ 8 փող.",
          "value": "Նորագավիթ 8 փող."
        },
        {
          "label": "Նորագավիթ 9 փող.",
          "value": "Նորագավիթ 9 փող."
        },
        {
          "label": "Շահամիրյաններ փող.",
          "value": "Շահամիրյաններ փող."
        },
        {
          "label": "Շարուր /Շահվերդյան/ փող.",
          "value": "Շարուր /Շահվերդյան/ փող."
        },
        {
          "label": "Շևչենկո փող.",
          "value": "Շևչենկո փող."
        },
        {
          "label": "Շիրակ 2 նրբ.",
          "value": "Շիրակ 2 նրբ."
        },
        {
          "label": "Շիրակ 5 նրբ",
          "value": "Շիրակ 5 նրբ"
        },
        {
          "label": "Շիրակ փող.",
          "value": "Շիրակ փող."
        },
        {
          "label": "Շիրակի 1 նրբ.",
          "value": "Շիրակի 1 նրբ."
        },
        {
          "label": "Շիրակի 4 նրբ.",
          "value": "Շիրակի 4 նրբ."
        },
        {
          "label": "Չեխով փող.",
          "value": "Չեխով փող."
        },
        {
          "label": "Պատկանյան փող.",
          "value": "Պատկանյան փող."
        },
        {
          "label": "Պոպով փող.",
          "value": "Պոպով փող."
        },
        {
          "label": "Ս. Զորավար փող.",
          "value": "Ս. Զորավար փող."
        },
        {
          "label": "Ս. Սարգսյան փող.",
          "value": "Ս. Սարգսյան փող."
        },
        {
          "label": "Սևան փող.",
          "value": "Սևան փող."
        },
        {
          "label": "Վ. Շենգավիթ 1 փող.",
          "value": "Վ. Շենգավիթ 1 փող."
        },
        {
          "label": "Վ. Շենգավիթ 10 փող.",
          "value": "Վ. Շենգավիթ 10 փող."
        },
        {
          "label": "Վ. Շենգավիթ 11 փող.",
          "value": "Վ. Շենգավիթ 11 փող."
        },
        {
          "label": "Վ. Շենգավիթ 2 փող.",
          "value": "Վ. Շենգավիթ 2 փող."
        },
        {
          "label": "Վ. Շենգավիթ 3 փող.",
          "value": "Վ. Շենգավիթ 3 փող."
        },
        {
          "label": "Վերին Չարբախ",
          "value": "Վերին Չարբախ"
        },
        {
          "label": "Տարոնց նրբ.",
          "value": "Տարոնց նրբ."
        },
        {
          "label": "Տարոնց փող.",
          "value": "Տարոնց փող."
        },
        {
          "label": "Տիմիրազև փող.",
          "value": "Տիմիրազև փող."
        },
        {
          "label": "Տրանսպորտայիններ փող.",
          "value": "Տրանսպորտայիններ փող."
        },
        {
          "label": "Օդեսա փող.",
          "value": "Օդեսա փող."
        },
        {
          "label": "Ֆրունզե փող.",
          "value": "Ֆրունզե փող."
        }
      ]
    },
    {
      communities: 'Վահագնի թաղամաս',
      info: [
        {
          "label": "Ալիշան փող.",
          "value": "Ալիշան փող."
        },
        {
          "label": "Արա-Լեռ փող.",
          "value": "Արա-Լեռ փող."
        },
        {
          "label": "Արաքսի փող.",
          "value": "Արաքսի փող."
        },
        {
          "label": "Արփա փող.",
          "value": "Արփա փող."
        },
        {
          "label": "Թայմ Շեր փող.",
          "value": "Թայմ Շեր փող."
        },
        {
          "label": "Հատիս փող.",
          "value": "Հատիս փող."
        },
        {
          "label": "Հրազդան փող.",
          "value": "Հրազդան փող."
        },
        {
          "label": "Ձորափ փող.",
          "value": "Ձորափ փող."
        },
        {
          "label": "Մեղրի փող.",
          "value": "Մեղրի փող."
        },
        {
          "label": "Մուշ փող.",
          "value": "Մուշ փող."
        },
        {
          "label": "Ն. Շնորհալի փող.",
          "value": "Ն. Շնորհալի փող."
        },
        {
          "label": "Նարեկացի փող.",
          "value": "Նարեկացի փող."
        },
        {
          "label": "Որոտան փող.",
          "value": "Որոտան փող."
        },
        {
          "label": "Սևակ փող.",
          "value": "Սևակ փող."
        },
        {
          "label": "Վահագնի 3 տեղ.",
          "value": "Վահագնի 3 տեղ."
        },
        {
          "label": "Տերյան փող.",
          "value": "Տերյան փող."
        }
      ]
    },
    {
      communities: 'Քանաքեռ-Զեյթուն',
      info: [
        {
          "label": "Ա.Տիգրանյան նրբ.",
          "value": "Ա.Տիգրանյան նրբ."
        },
        {
          "label": "Ա.Տիգրանյան փող.",
          "value": "Ա.Տիգրանյան փող."
        },
        {
          "label": "Ադոնց փող.",
          "value": "Ադոնց փող."
        },
        {
          "label": "Ազատության նրբ.",
          "value": "Ազատության նրբ."
        },
        {
          "label": "Ազատության պող.",
          "value": "Ազատության պող."
        },
        {
          "label": "Ահարոնյան փող.",
          "value": "Ահարոնյան փող."
        },
        {
          "label": "Աղասի փող.",
          "value": "Աղասի փող."
        },
        {
          "label": "Բաբայան փող.",
          "value": "Բաբայան փող."
        },
        {
          "label": "Բուսաբանական փող.",
          "value": "Բուսաբանական փող."
        },
        {
          "label": "Գաստելլո փող.",
          "value": "Գաստելլո փող."
        },
        {
          "label": "Գերցեն փող.",
          "value": "Գերցեն փող."
        },
        {
          "label": "Գոգոլ նրբ.",
          "value": "Գոգոլ նրբ."
        },
        {
          "label": "Գոգոլ փող.",
          "value": "Գոգոլ փող."
        },
        {
          "label": "Դալլաքյան փող.",
          "value": "Դալլաքյան փող."
        },
        {
          "label": "Դավիթ Անհաղթ փող.",
          "value": "Դավիթ Անհաղթ փող."
        },
        {
          "label": "Դրոի փող.",
          "value": "Դրոի փող."
        },
        {
          "label": "Զեյթուն 10 փող.",
          "value": "Զեյթուն 10 փող."
        },
        {
          "label": "Էմինեսկի 1 փող.",
          "value": "Էմինեսկի 1 փող."
        },
        {
          "label": "Էմինեսկի 2 փող.",
          "value": "Էմինեսկի 2 փող."
        },
        {
          "label": "Էմինեսկի փող.",
          "value": "Էմինեսկի փող."
        },
        {
          "label": "Թբիլիսյան Խճ.",
          "value": "Թբիլիսյան Խճ."
        },
        {
          "label": "Լեփսիուս 1 փող.",
          "value": "Լեփսիուս 1 փող."
        },
        {
          "label": "Լեփսիուս 2 փող.",
          "value": "Լեփսիուս 2 փող."
        },
        {
          "label": "Լեփսիուս 3 փող.",
          "value": "Լեփսիուս 3 փող."
        },
        {
          "label": "Լեփսիուս 4 փող.",
          "value": "Լեփսիուս 4 փող."
        },
        {
          "label": "Լեփսիուս 6 փող.",
          "value": "Լեփսիուս 6 փող."
        },
        {
          "label": "Լեփսիուս փող.",
          "value": "Լեփսիուս փող."
        },
        {
          "label": "Ծարավ Աղբյուր /Իլիչ/ փող.",
          "value": "Ծարավ Աղբյուր /Իլիչ/ փող."
        },
        {
          "label": "Կ. Ուլնեցի 1 փակ.",
          "value": "Կ. Ուլնեցի 1 փակ."
        },
        {
          "label": "Կ. Ուլնեցի 2 փակ.",
          "value": "Կ. Ուլնեցի 2 փակ."
        },
        {
          "label": "Կ. Ուլնեցի 3 փակ.",
          "value": "Կ. Ուլնեցի 3 փակ."
        },
        {
          "label": "Կ. Ուլնեցի փող.",
          "value": "Կ. Ուլնեցի փող."
        },
        {
          "label": "Կամարակ 16 փող.",
          "value": "Կամարակ 16 փող."
        },
        {
          "label": "Կամարակ նրբ.",
          "value": "Կամարակ նրբ."
        },
        {
          "label": "Կամարակ փող.",
          "value": "Կամարակ փող."
        },
        {
          "label": "Կամարիկ փող.",
          "value": "Կամարիկ փող."
        },
        {
          "label": "Կամո փող.",
          "value": "Կամո փող."
        },
        {
          "label": "Հասրաթյան փող.",
          "value": "Հասրաթյան փող."
        },
        {
          "label": "Հր. Ներսիսյան փող.",
          "value": "Հր. Ներսիսյան փող."
        },
        {
          "label": "Մ. Ավետիսյան 1 փող.",
          "value": "Մ. Ավետիսյան 1 փող."
        },
        {
          "label": "Մ. Ավետիսյան 2 փող.",
          "value": "Մ. Ավետիսյան 2 փող."
        },
        {
          "label": "Մ. Ավետիսյան 3 փող.",
          "value": "Մ. Ավետիսյան 3 փող."
        },
        {
          "label": "Մ. Ավետիսյան 4 փող.",
          "value": "Մ. Ավետիսյան 4 փող."
        },
        {
          "label": "Մ. Ավետիսյան փող.",
          "value": "Մ. Ավետիսյան փող."
        },
        {
          "label": "Մ.Մելիքյան փող.",
          "value": "Մ.Մելիքյան փող."
        },
        {
          "label": "Մյասնիկյան փող.",
          "value": "Մյասնիկյան փող."
        },
        {
          "label": "Յաղուբյան փող.",
          "value": "Յաղուբյան փող."
        },
        {
          "label": "Ն. Սուրենյան /Զեյթուն 8-րդ/ փող.",
          "value": "Ն. Սուրենյան /Զեյթուն 8-րդ/ փող."
        },
        {
          "label": "Նորաշխարհյան փող.",
          "value": "Նորաշխարհյան փող."
        },
        {
          "label": "Շովրոյան փող.",
          "value": "Շովրոյան փող."
        },
        {
          "label": "Չոլաքյան փող.",
          "value": "Չոլաքյան փող."
        },
        {
          "label": "Պարույր Սևակ փող.",
          "value": "Պարույր Սևակ փող."
        },
        {
          "label": "Ռայնիս փող.",
          "value": "Ռայնիս փող."
        },
        {
          "label": "Ռուբինյանց փող.",
          "value": "Ռուբինյանց փող."
        },
        {
          "label": "Սարկավագ 3 նրբ.",
          "value": "Սարկավագ 3 նրբ."
        },
        {
          "label": "Սարկավագ 4 նրբ.",
          "value": "Սարկավագ 4 նրբ."
        },
        {
          "label": "Սարկավագ փող.",
          "value": "Սարկավագ փող."
        },
        {
          "label": "Վ. Զեյթուն 5 փող. 4 նրբ.",
          "value": "Վ. Զեյթուն 5 փող. 4 նրբ."
        },
        {
          "label": "Վ. Փափազյան փող.",
          "value": "Վ. Փափազյան փող."
        },
        {
          "label": "Վահե Վահյան փող.",
          "value": "Վահե Վահյան փող."
        },
        {
          "label": "Վարշավյան փող.",
          "value": "Վարշավյան փող."
        },
        {
          "label": "Տոնյան փող.",
          "value": "Տոնյան փող."
        },
        {
          "label": "Քանաքեռի 1 փող.",
          "value": "Քանաքեռի 1 փող."
        },
        {
          "label": "Քանաքեռի 10 փող.",
          "value": "Քանաքեռի 10 փող."
        },
        {
          "label": "Քանաքեռի 11 փող.",
          "value": "Քանաքեռի 11 փող."
        },
        {
          "label": "Քանաքեռի 12 փող.",
          "value": "Քանաքեռի 12 փող."
        },
        {
          "label": "Քանաքեռի 13 փող.",
          "value": "Քանաքեռի 13 փող."
        },
        {
          "label": "Քանաքեռի 14 փող.",
          "value": "Քանաքեռի 14 փող."
        },
        {
          "label": "Քանաքեռի 15 փող.",
          "value": "Քանաքեռի 15 փող."
        },
        {
          "label": "Քանաքեռի 16 փող.",
          "value": "Քանաքեռի 16 փող."
        },
        {
          "label": "Քանաքեռի 17 փող.",
          "value": "Քանաքեռի 17 փող."
        },
        {
          "label": "Քանաքեռի 2 փող.",
          "value": "Քանաքեռի 2 փող."
        },
        {
          "label": "Քանաքեռի 3 փող.",
          "value": "Քանաքեռի 3 փող."
        },
        {
          "label": "Քանաքեռի 5 փող.",
          "value": "Քանաքեռի 5 փող."
        },
        {
          "label": "Քանաքեռի 6 փող.",
          "value": "Քանաքեռի 6 փող."
        },
        {
          "label": "Քանաքեռի 8 փող.",
          "value": "Քանաքեռի 8 փող."
        },
        {
          "label": "Քանաքեռի 9 փող.",
          "value": "Քանաքեռի 9 փող."
        },
        {
          "label": "Քանաքեռցի փող.",
          "value": "Քանաքեռցի փող."
        },
        {
          "label": "Ֆանարջյան փող.",
          "value": "Ֆանարջյան փող."
        },
        {
          "label": "Ֆանարջյան փող. 2 նրբ.",
          "value": "Ֆանարջյան փող. 2 նրբ."
        }
      ]
    }
  ];

  numberOfRooms = [
    {
      "label": "1",
      "value": "1"
    },
    {
      "label": "2",
      "value": "2"
    },
    {
      "label": "3",
      "value": "3"
    },
    {
      "label": "4+",
      "value": "4+"
    }
  ];
  interiorDecorations = [
    {
      "label": "Իրականացված չէ",
      "value": "Իրականացված չէ"
    },
    {
      "label": "Գերմաշված է",
      "value": "Գերմաշված է"
    },
    {
      "label": "Վատթար",
      "value": "Վատթար"
    },
    {
      "label": "Անբավարար",
      "value": "Անբավարար"
    },
    {
      "label": "Բավարար",
      "value": "Բավարար"
    },
    {
      "label": "Բարվոք",
      "value": "Բարվոք"
    },
    {
      "label": "Լավ",
      "value": "Լավ"
    },
    {
      "label": "Շատ լավ",
      "value": "Շատ լավ"
    },
    {
      "label": "Գերազանց",
      "value": "Գերազանց"
    }
  ];
  projects = [
    {
      "label": "16 Հարկանի",
      "value": "16 Հարկանի"
    },
    {
      "label": "Բադալյան",
      "value": "Բադալյան"
    },
    {
      "label": "Դյուպլեքս",
      "value": "Դյուպլեքս"
    },
    {
      "label": "Երևանյան ՏՇԿ",
      "value": "Երևանյան ՏՇԿ"
    },
    {
      "label": "Թամանյանական",
      "value": "Թամանյանական"
    },
    {
      "label": "Թաունհաուս",
      "value": "Թաունհաուս"
    },
    {
      "label": "Լոջիայով",
      "value": "Լոջիայով"
    },
    {
      "label": "Խոշորապանելային",
      "value": "Խոշորապանելային"
    },
    {
      "label": "Խրուշչովյան",
      "value": "Խրուշչովյան"
    },
    {
      "label": "Կասետային",
      "value": "Կասետային"
    },
    {
      "label": "Կիսակառույց",
      "value": "Կիսակառույց"
    },
    {
      "label": "Հատուկ",
      "value": "Հատուկ"
    },
    {
      "label": "Հետխրուշչովյան",
      "value": "Հետխրուշչովյան"
    },
    {
      "label": "Մոսկովյան ՏՇԿ",
      "value": "Մոսկովյան ՏՇԿ"
    },
    {
      "label": "Նախաստալինյան",
      "value": "Նախաստալինյան"
    },
    {
      "label": "Նախկին հանրակացարան",
      "value": "Նախկին հանրակացարան"
    },
    {
      "label": "Նախկին վարչական",
      "value": "Նախկին վարչական"
    },
    {
      "label": "Նորակառույց",
      "value": "Նորակառույց"
    },
    {
      "label": "Չեխական",
      "value": "Չեխական"
    },
    {
      "label": "Ստալինյան",
      "value": "Ստալինյան"
    }
  ];
  buildingTypes = [
    {
      "label": "Քարե",
      "value": "Քարե"
    },
    {
      "label": "Պանել",
      "value": "Պանել"
    },
    {
      "label": "Միաձույլ",
      "value": "Միաձույլ"
    }
  ];
  covers = [
    {
      "label": "Երկաթ/բետոն",
      "value": "Երկաթ/բետոն"
    },
    {
      "label": "Երկաթ/բետոն և փայտե",
      "value": "Երկաթ/բետոն և փայտե"
    },
    {
      "label": "Փայտե",
      "value": "Փայտե"
    }
  ];
  additionalInfoFieldsApartment = [
    {
      "label": "Ջուր",
      "value": "Ջուր"
    },
    {
      "label": "Կոյուղի",
      "value": "Կոյուղի"
    },
    {
      "label": "Էլ. հոսանք",
      "value": "Էլ. հոսանք"
    },
    {
      "label": "Գազ",
      "value": "Գազ"
    },
    {
      "label": "Վերելակ",
      "value": "Վերելակ"
    },
    {
      "label": "Ավտոտնակ",
      "value": "Ավտոտնակ"
    },
    {
      "label": "Ավտոկայանատեղի",
      "value": "Ավտոկայանատեղի"
    },
    {
      "label": "Անվտանգության համակարգ",
      "value": "Անվտանգության համակարգ"
    },
    {
      "label": "Դոմոֆոն",
      "value": "Դոմոֆոն"
    },
    {
      "label": "Օդորոկիչ",
      "value": "Օդորոկիչ"
    },
    {
      "label": "Ջեռուցման համակարգ",
      "value": "Ջեռուցման համակարգ"
    },
    {
      "label": "Տաքացվող  հատակ",
      "value": "Տաքացվող  հատակ"
    },
    {
      "label": "Բաց պատշգամբ",
      "value": "Բաց պատշգամբ"
    },
    {
      "label": "Փակ  պատշգամբ",
      "value": "Փակ  պատշգամբ"
    },
    {
      "label": "Շքապատշգամբ",
      "value": "Շքապատշգամբ"
    },
    {
      "label": "Լոջիա",
      "value": "Լոջիա"
    },
    {
      "label": "Խորդանոց",
      "value": "Խորդանոց"
    }
  ];
  additionalInfoFieldsHouse = [
    {
      "label": "Ջուր",
      "value": "Ջուր"
    },
    {
      "label": "Կոյուղի",
      "value": "Կոյուղի"
    },
    {
      "label": "Էլ. հոսանք",
      "value": "Էլ. հոսանք"
    },
    {
      "label": "Գազ",
      "value": "Գազ"
    },
    {
      "label": "Ավտոտնակ",
      "value": "Ավտոտնակ"
    },
    {
      "label": "Ավտոկայանատեղի",
      "value": "Ավտոկայանատեղի"
    },
    {
      "label": "Անվտանգության համակարգ",
      "value": "Անվտանգության համակարգ"
    },
    {
      "label": "Օդորոկիչ",
      "value": "Օդորոկիչ"
    },
    {
      "label": "Ջեռուցման համակարգ",
      "value": "Ջեռուցման համակարգ"
    },
    {
      "label": "Տաքացվող  հատակ",
      "value": "Տաքացվող  հատակ"
    },
    {
      "label": "Բաց պատշգամբ",
      "value": "Բաց պատշգամբ"
    },
    {
      "label": "Փակ  պատշգամբ",
      "value": "Փակ  պատշգամբ"
    },
    {
      "label": "Շքապատշգամբ",
      "value": "Շքապատշգամբ"
    },
    {
      "label": "Լոջիա",
      "value": "Լոջիա"
    },
    {
      "label": "Խորդանոց",
      "value": "Խորդանոց"
    },
    {
      "label": "Պարիսպ",
      "value": "Պարիսպ"
    },
    {
      "label": "Պտղատու այգի",
      "value": "Պտղատու այգի"
    },
    {
      "label": "Լողավազան",
      "value": "Լողավազան"
    }
  ];
  actualUseCommercial = [
    {
      "label": "Հասարակական",
      "value": "Հասարակական"
    },
    {
      "label": "Առևտրային",
      "value": "Առևտրային"
    },
    {
      "label": "Տեխսպասարկում",
      "value": "Տեխսպասարկում"
    },
    {
      "label": "Բենզալցակայան",
      "value": "Բենզալցակայան"
    },
    {
      "label": "ԱԳԼՃԿ",
      "value": "ԱԳԼՃԿ"
    },
    {
      "label": "Արտադրական",
      "value": "Արտադրական"
    },
    {
      "label": "Հիդրոէլեկտրակայան",
      "value": "Հիդրոէլեկտրակայան"
    },
    {
      "label": "Ընդերքի օգտագործում",
      "value": "Ընդերքի օգտագործում"
    },
    {
      "label": "Անասնապահական",
      "value": "Անասնապահական"
    },
    {
      "label": "Թռչնաֆաբրիկա",
      "value": "Թռչնաֆաբրիկա"
    },
    {
      "label": "Ձկնաբուծարան",
      "value": "Ձկնաբուծարան"
    },
    {
      "label": "Ջերմոց",
      "value": "Ջերմոց"
    },
    {
      "label": "Հյուրանոց հոթել, մոթել",
      "value": "Հյուրանոց հոթել, մոթել"
    },
    {
      "label": "Ռեստորան-հյուրանոց",
      "value": "Ռեստորան-հյուրանոց"
    },
    {
      "label": "Ռեստորան",
      "value": "Ռեստորան"
    },
    {
      "label": "Սրճարան",
      "value": "Սրճարան"
    },
    {
      "label": "Նկուղ կիսանկուղ",
      "value": "Նկուղ կիսանկուղ"
    },
    {
      "label": "Սառնարան",
      "value": "Սառնարան"
    },
    {
      "label": "Սպորտային Համալիր",
      "value": "Սպորտային Համալիր"
    }
  ];
  additionalInfoFieldsCommercial = [
    {
      "label": "Ջուր",
      "value": "Ջուր"
    },
    {
      "label": "Կոյուղի",
      "value": "Կոյուղի"
    },
    {
      "label": "Էլ. հոսանք",
      "value": "Էլ. հոսանք"
    },
    {
      "label": "Գազ",
      "value": "Գազ"
    },
    {
      "label": "Ավտոկայանատեղի",
      "value": "Ավտոկայանատեղի"
    },
    {
      "label": "Անվտանգության համակարգ",
      "value": "Անվտանգության համակարգ"
    },
    {
      "label": "Տեսահսկման համակարգ",
      "value": "Տեսահսկման համակարգ"
    },
    {
      "label": "Օդորոկիչ",
      "value": "Օդորոկիչ"
    },
    {
      "label": "Ջեռուցման համակարգ",
      "value": "Ջեռուցման համակարգ"
    },
    {
      "label": "Տաքացվող  հատակ",
      "value": "Տաքացվող  հատակ"
    },
    {
      "label": "1-ին գիծ",
      "value": "1-ին գիծ"
    },
    {
      "label": "0-ական հարթություն",
      "value": "0-ական հարթություն"
    },
    {
      "label": "Եռաֆազ հոսանք",
      "value": "Եռաֆազ հոսանք"
    },
    {
      "label": "Վիտրաժ",
      "value": "Վիտրաժ"
    }
  ];
  actualUseLand = [
    {
      "label": "Բնակելի կառուցապատման",
      "value": "Բնակելի կառուցապատման"
    },
    {
      "label": "Բազմահարկի շին. փաթեթով",
      "value": "Բազմահարկի շին. փաթեթով"
    },
    {
      "label": "Հասարակական",
      "value": "Հասարակական"
    },
    {
      "label": "խառը կառուցապատման",
      "value": "խառը կառուցապատման"
    },
    {
      "label": "Ընդհանուր օգտագործման",
      "value": "Ընդհանուր օգտագործման"
    },
    {
      "label": "Այլ հողեր",
      "value": "Այլ հողեր"
    },
    {
      "label": "Հատուկ պահպանվող",
      "value": "Հատուկ պահպանվող"
    },
    {
      "label": "Այգի",
      "value": "Այգի"
    },
    {
      "label": "Վարելահող",
      "value": "Վարելահող"
    },
    {
      "label": "խոտհարք",
      "value": "խոտհարք"
    },
    {
      "label": "Արոտավայր",
      "value": "Արոտավայր"
    },
    {
      "label": "Հատուկ նշանակության",
      "value": "Հատուկ նշանակության"
    },
    {
      "label": "Անտառներ",
      "value": "Անտառներ"
    },
    {
      "label": "Ջրային",
      "value": "Ջրային"
    },
    {
      "label": "Պահուստային հողեր",
      "value": "Պահուստային հողեր"
    },
    {
      "label": "Արդյունաբերական",
      "value": "Արդյունաբերական"
    },
    {
      "label": "Գյուղ. արտադրական",
      "value": "Գյուղ. արտադրական"
    },
    {
      "label": "Ընդերքի օգտագործման",
      "value": "Ընդերքի օգտագործման"
    },
    {
      "label": "էներգետիկա, կապ",
      "value": "էներգետիկա, կապ"
    }
  ];
  additionalInfoFieldsLand = [
    {
      "label": "Ջուր",
      "value": "Ջուր"
    },
    {
      "label": "Կոյուղի",
      "value": "Կոյուղի"
    },
    {
      "label": "Էլ. հոսանք",
      "value": "Էլ. հոսանք"
    },
    {
      "label": "Եռաֆազ",
      "value": "Եռաֆազ"
    },
    {
      "label": "Որոգման ջուր",
      "value": "Որոգման ջուր"
    },
    {
      "label": "Գազ",
      "value": "Գազ"
    },
    {
      "label": "Ճանապարհամերձ",
      "value": "Ճանապարհամերձ"
    },
    {
      "label": "Պարիսպ",
      "value": "Պարիսպ"
    },
    {
      "label": "Ընդլայնման  հնարավորություն",
      "value": "Ընդլայնման  հնարավորություն"
    },
    {
      "label": "Բարեկարգ",
      "value": "Բարեկարգ"
    },
    {
      "label": "Այգի",
      "value": "Այգի"
    },
    {
      "label": "Հողամասը հարթ է",
      "value": "Հողամասը հարթ է"
    },
    {
      "label": "Հողամասը ունի ակնհայտ թեքություն",
      "value": "Հողամասը ունի ակնհայտ թեքություն"
    }
  ];
  sectorsBusiness = [
    {
      "label": "Հասարակական",
      "value": "Հասարակական"
    },
    {
      "label": "Առևտրային",
      "value": "Առևտրային"
    },
    {
      "label": "Տեխսպասարկում",
      "value": "Տեխսպասարկում"
    },
    {
      "label": "Բենզալցակայան",
      "value": "Բենզալցակայան"
    },
    {
      "label": "ԱԳԼՃԿ",
      "value": "ԱԳԼՃԿ"
    },
    {
      "label": "Արտադրական",
      "value": "Արտադրական"
    },
    {
      "label": "Հիդրոէլեկտրակայան",
      "value": "Հիդրոէլեկտրակայան"
    },
    {
      "label": "Ընդերքի օգտագործում",
      "value": "Ընդերքի օգտագործում"
    },
    {
      "label": "Անասնապահական",
      "value": "Անասնապահական"
    },
    {
      "label": "Թռչնաֆաբրիկա",
      "value": "Թռչնաֆաբրիկա"
    },
    {
      "label": "Ձկնաբուծարան",
      "value": "Ձկնաբուծարան"
    },
    {
      "label": "Ջերմոց",
      "value": "Ջերմոց"
    },
    {
      "label": "Հյուրանոց հոթել, մոթել",
      "value": "Հյուրանոց հոթել, մոթել"
    },
    {
      "label": "Ռեստորան-հյուրանոց",
      "value": "Ռեստորան-հյուրանոց"
    },
    {
      "label": "Ռեստորան",
      "value": "Ռեստորան"
    },
    {
      "label": "Սրճարան",
      "value": "Սրճարան"
    },
    {
      "label": "Նկուղ կիսանկուղ",
      "value": "Նկուղ կիսանկուղ"
    },
    {
      "label": "Սառնարան",
      "value": "Սառնարան"
    },
    {
      "label": "Սպորտային Համալիր",
      "value": "Սպորտային Համալիր"
    }
  ];
  additionalInfoFieldsBusiness = [
    {
      "label": "Ջուր",
      "value": "Ջուր"
    },
    {
      "label": "Կոյուղի",
      "value": "Կոյուղի"
    },
    {
      "label": "Էլ. հոսանք",
      "value": "Էլ. հոսանք"
    },
    {
      "label": "Գազ",
      "value": "Գազ"
    },
    {
      "label": "Ավտոկայանատեղի",
      "value": "Ավտոկայանատեղի"
    },
    {
      "label": "Անվտանգության համակարգ",
      "value": "Անվտանգության համակարգ"
    },
    {
      "label": "Տեսահսկման համակարգ",
      "value": "Տեսահսկման համակարգ"
    },
    {
      "label": "Օդորոկիչ",
      "value": "Օդորոկիչ"
    },
    {
      "label": "Ջեռուցման համակարգ",
      "value": "Ջեռուցման համակարգ"
    },
    {
      "label": "Տաքացվող  հատակ",
      "value": "Տաքացվող  հատակ"
    },
    {
      "label": "1-ին գիծ",
      "value": "1-ին գիծ"
    },
    {
      "label": "0-ական հարթություն",
      "value": "0-ական հարթություն"
    },
    {
      "label": "Եռաֆազ հոսանք",
      "value": "Եռաֆազ հոսանք"
    },
    {
      "label": "Վիտրաժ",
      "value": "Վիտրաժ"
    }
  ];
  currencies = [
    {label: 'USD', value: 'USD'},
    {label: 'AMD', value: 'AMD'}
  ];



  // costsystem publicdata added
  actuallyBestUseLands = [
    {label: 'Բնակելի կառուցապատման', value: 'Բնակելի կառուցապատման'},
    {label: 'Բազմահարկի շին. փաթեթով', value: 'Բազմահարկի շին. փաթեթով'},
    {label: 'Հասարակական', value: 'Հասարակական'},
    {label: 'Խառը կառուցապատման', value: 'Խառը կառուցապատման'},
    {label: 'Ընդհանուր օգտագործման', value: 'Ընդհանուր օգտագործման'},
    {label: 'Այլ հողեր բնակավայրերի', value: 'Այլ հողեր բնակավայրերի'},
    {label: 'Բնապահպանական', value: 'Բնապահպանական'},
    {label: 'Առողջարարական', value: 'Առողջարարական'},
    {label: 'Հանգստի համար', value: 'Հանգստի համար'},
    {label: 'Պատմական և մշակութային', value: 'Պատմական և մշակութային'},
    {label: 'Բազմամյա տնկարկ', value: 'Բազմամյա տնկարկ'},
    {label: 'Վարելահող', value: 'Վարելահող'},
    {label: 'Խոտհարք', value: 'Խոտհարք'},
    {label: 'Արոտավայր', value: 'Արոտավայր'},
    {label: 'Այլ հողատեսք', value: 'Այլ հողատեսք'},
    {label: 'Հատուկ նշանակության', value: 'Հատուկ նշանակության'},
    {label: 'Անտառներ', value: 'Անտառներ'},
    {label: 'Վարելահողեր', value: 'Վարելահողեր'},
    {label: 'Խոտհարքներ', value: 'Խոտհարքներ'},
    {label: 'Արոտներ', value: 'Արոտներ'},
    {label: 'Թփուտներ', value: 'Թփուտներ'},
    {label: 'Այլ հողեր գյուղատնտեսական', value: 'Այլ հողեր գյուղատնտեսական'},
    {label: 'Ջրային', value: 'Ջրային'},
    {label: 'Պահուստային հողեր', value: 'Պահուստային հողեր'},
    {label: 'Արդյունաբերական', value: 'Արդյունաբերական'},
    {label: 'Գյուղ. արտադրական', value: 'Գյուղ. արտադրական'},
    {label: 'Պահեստարաններ', value: 'Պահեստարաններ'},
    {label: 'Ընդերքի օգտագործման', value: 'Ընդերքի օգտագործման'},
    {label: 'էներգետիկա, կապի', value: 'էներգետիկա, կապի'},
    {label: 'Պահուստային հողեր', value: 'Պահուստային հողեր'}
  ];
  actuallyBestUseAppartment = [
    {label: 'Բնակարան', value: 'Բնակարան'},
    {label: 'Հանրակացարան', value: 'Հանրակացարան'},
    {label: 'Նորակառույց', value: 'Նորակառույց'},
    {label: 'Ձեղնահարկ', value: 'Ձեղնահարկ'},
    {label: 'Առանձնատուն', value: 'Առանձնատուն'},
    {label: 'Այգետնակ /Ամառանոց/', value: 'Այգետնակ /Ամառանոց/'},
    {label: 'Հասարակական Տարածք', value: 'Հասարակական Տարածք'},
    {label: 'Առևտրային Տարածք', value: 'Առևտրային Տարածք'},
    {label: 'Արտադրական Տարածք', value: 'Արտադրական Տարածք'},
    {label: 'Նկուղ Կիսանկուղ', value: 'Նկուղ Կիսանկուղ'},
    {label: 'Ավտոկայանատեղի Ստորգետնյա', value: 'Ավտոկայանատեղի Ստորգետնյա'},
    {label: 'Ավտոկայանատեղի Վերգետնյա', value: 'Ավտոկայանատեղի Վերգետնյա'},
    {label: 'Հասարակական Համալիր', value: 'Հասարակական Համալիր'},
    {label: 'Առևտրային Համալիր', value: 'Առևտրային Համալիր'},
    {label: 'Տեխսպասարկում', value: 'Տեխսպասարկում'},
    {label: 'Բենզալցակայան', value: 'Բենզալցակայան'},
    {label: 'ԱԳԼՃԿ', value: 'ԱԳԼՃԿ'},
    {label: 'Արտադրական Համալիր', value: 'Արտադրական Համալիր'},
    {label: 'Սառնարան', value: 'Սառնարան'},
    {label: 'Հիդրոէլեկտրակայան', value: 'Հիդրոէլեկտրակայան'},
    {label: 'Անասնապահական', value: 'Անասնապահական'},
    {label: 'Թռչնաֆաբրիկա', value: 'Թռչնաֆաբրիկա'},
    {label: 'Ձկնաբուծարան', value: 'Ձկնաբուծարան'},
    {label: 'Ընդերքի Օգտագործման', value: 'Ընդերքի Օգտագործման'},
    {label: 'Ջերմոց', value: 'Ջերմոց'},
    {label: 'Հյուրանոց հոթել, մոթել', value: 'Հյուրանոց հոթել, մոթել'},
    {label: 'Ռեստորան-Հյուրանոց', value: 'Ռեստորան-Հյուրանոց'},
    {label: 'Ռեստորան', value: 'Ռեստորան'},
    {label: 'Սրճարան', value: 'Սրճարան'},
    {label: 'Ավտոտնակ Ստորգետնյա', value: 'Ավտոտնակ Ստորգետնյա'},
    {label: 'Ավտոտնակ Վերգետնյա', value: 'Ավտոտնակ Վերգետնյա'},
    {label: 'Բազմաֆունկցիոնալ', value: 'Բազմաֆունկցիոնալ'},
    {label: 'Բնակելի Շենք', value: 'Բնակելի Շենք'},
    {label: 'Պատմամշակութային', value: 'Պատմամշակութային'},
    {label: 'Սպորտային Համալիր', value: 'Սպորտային Համալիր'},
    {label: 'Անօգտագործելի', value: 'Անօգտագործելի'},
    {label: 'Կիսակառույց', value: 'Կիսակառույց'},
  ];
  // costsystem publicdata added

  constructor() {
  }

  randomSumArray(): any {
    let len = 5,
      sum = 8,
      d;
    let _sum = 0;
    let arr = [];
    let n, i;

    if (!d && d !== 0) {
      d = 100;
    }

    for (i = 0; i < len; i++) {
      let from = (100 - d) * 1000,
        to = (100 + d) * 1000,
        n = Math.floor(Math.random() * (to - from + 1) + from); //random integer between from..to

      _sum += n;
      arr.push(n);
    }

    let x = sum / _sum;

    _sum = 0; //count sum (again)
    for (let i = 0; i < len; i++) {
      arr[i] = Math.round(arr[i] * x);
      _sum += arr[i];
    }

    let diff = sum - _sum;

    // Correct the array if its sum does not match required sum (usually by a small bit)
    if (diff) {
      x = diff / Math.abs(diff); //x will be 1 or -1
      let j = 0;
      while (diff && j < 1000) { //limit to a finite number of 'corrections'
        i = Math.floor(Math.random() * (len + 1)); //random index in the array
        if (arr[i] + x >= 0) {
          arr[i] += x;
          diff -= x;
        }
        j++;
      }
    }

    let obj = {
      apartments: arr[0],
      houses: arr[1],
      commercials: arr[2],
      businesses: arr[3],
      lands: arr[4]
    };
    return obj;
  }

}
