import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-our-team-page',
  templateUrl: './our-team-page.component.html',
  styleUrls: ['./our-team-page.component.css']
})
export class OurTeamPageComponent implements OnInit {
  user: string;
  constructor(public router: Router,
              private titleService: Title,
              private metaTagService: Meta) {
    const language = localStorage.getItem('language');

    const amTitle = "ՄԵՐ ՄԱՍԻՆ | Consult"
    const enTitle = "ABOUT US | Consult"
    const ruTitle = "О НАС | Консульт"
    const amDesc = 'Կոստ Կոնսալտ ՍՊԸ |  Երևան 0010, Բուզանդ 1 | +374 10 580606 /  +374 10 544882 |  info@cost.am/  cost@consultant.com | cost.am'
    const enDesc = 'Cost Consult LLC |: Yerevan 0010, Buzand 1 |: +374 10 580606 / +374 10 544882 | info@cost.am/ cost@consultant.com |: cost.am'
    const ruDesc = 'ООО «Кост Консалт» |: Ереван 0010, Бузанд 1 |: +374 10 580606 / +374 10 544882 | info@cost.am/ cost@consultant.com |: cost.am'

    let title = ''
    let description = ''
    if(language === 'am') {
      title = amTitle
      description = amDesc
    }
    if(language === 'en') {
      title = enTitle
      description = enDesc
    }
    if(language === 'ru') {
      title = ruTitle
      description = ruDesc
    }
    this.titleService.setTitle(title);

    this.metaTagService.addTag(
      { name: 'description', content: description },
    );

    this.router.events.subscribe((val) => {
      const lang = localStorage.getItem('language');
      if(lang === 'am') {
        title = amTitle
        description = amDesc
      }
      if(lang === 'en') {
        title = enTitle
        description = enDesc
      }
      if(lang === 'ru') {
        title = ruTitle
        description = ruDesc
      }

      this.titleService.setTitle(title);
      this.metaTagService.addTag(
        { name: 'description', content: description },
      );
    });
  }

  ngOnInit() {
    this.goToTopInit()
  }

  goToTopInit() {
    window.scroll({
      top: 0,
      left: 0
    });
  }

}
