import { Component, OnInit } from '@angular/core';
import {PublicDataService} from "../../shared/services/public-data.service";
import {AppGlobals} from "../../app.globals";
import { Title, Meta } from '@angular/platform-browser';
import {Router} from "@angular/router";
import {BannersService} from "../../shared/services/banners.service";

@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.css']
})
export class EvaluationComponent implements OnInit {

  activeAccordion = 1;

  localStorageLanguage;
  urlForBanners;
  banners = [];

  user: string;
  data = [
    {
      id: 1,
      title: 'evaluation_accordion.line1.title',
      content: [
        'evaluation_accordion.line1.content1',
        'evaluation_accordion.line1.content2',
        'evaluation_accordion.line1.content3',
        'evaluation_accordion.line1.content4',
        'evaluation_accordion.line1.content5'
      ]
    },
    {
      id: 2,
      title: 'evaluation_accordion.line2.title',
      content: [
        'evaluation_accordion.line2.content1',
        'evaluation_accordion.line2.content2',
        'evaluation_accordion.line2.content3',
        'evaluation_accordion.line2.content4',
       ]
    },
    {
      id: 3,
      title: 'evaluation_accordion.line3.title',
      content: [
        'evaluation_accordion.line3.content1',
        'evaluation_accordion.line3.content2'
        ]
    },
    {
      id: 4,
      title: 'evaluation_accordion.line4.title',
      content: [
        'evaluation_accordion.line4.content1',
        'evaluation_accordion.line4.content2',
        'evaluation_accordion.line4.content3',
        'evaluation_accordion.line4.content4',
        ]
    },
    {
      id: 5,
      title: 'evaluation_accordion.line5.title',
      content: [
        'evaluation_accordion.line5.content1',
        'evaluation_accordion.line5.content2'
         ]
    },
    {
      id: 6,
      title: 'evaluation_accordion.line6.title',
      content: [
        'evaluation_accordion.line6.content1',
        'evaluation_accordion.line6.content2'
         ]
    },
    {
      id: 7,
      title: 'evaluation_accordion.line7.title',
      content: [
        'evaluation_accordion.line7.content1',
        'evaluation_accordion.line7.content2',
        'evaluation_accordion.line7.content3',
        'evaluation_accordion.line7.content4',
        'evaluation_accordion.line7.content5',
         ]
    },
    {
      id: 8,
      title: 'evaluation_accordion.line8.title',
      content: [
       'evaluation_accordion.line8.content1',
       'evaluation_accordion.line8.content2',
        ]
    },
    {
      id: 9,
      title: 'evaluation_accordion.line9.title',
      content: [
        'evaluation_accordion.line9.content1',
        'evaluation_accordion.line9.content2'
         ]
    },
    {
      id: 10,
      title: 'evaluation_accordion.line10.title',
      content: [
        'evaluation_accordion.line10.content1',
        'evaluation_accordion.line10.content2',
        'evaluation_accordion.line10.content3'
         ]
    },
    {
      id: 11,
      title: 'evaluation_accordion.line11.title',
      content: [
        'evaluation_accordion.line11.content1',
        'evaluation_accordion.line11.content2'
        ]
    },
    {
      id: 12,
      title: 'evaluation_accordion.line12.title',
      content: [
        'evaluation_accordion.line12.content1',
        'evaluation_accordion.line12.content2',
        'evaluation_accordion.line12.content3'
        ]
    },
    {
      id: 13,
      title: 'evaluation_accordion.line13.title',
      content: [
        'evaluation_accordion.line13.content1',
        'evaluation_accordion.line13.content2',
        'evaluation_accordion.line13.content3'
        ]
    },
    {
      id: 14,
      title: 'evaluation_accordion.line14.title',
      content: [
        'evaluation_accordion.line14.content1',
        'evaluation_accordion.line14.content2',
        'evaluation_accordion.line14.content3'
         ]
    },
    {
      id: 15,
      title: 'evaluation_accordion.line15.title',
      content: [
        'evaluation_accordion.line15.content1',
        'evaluation_accordion.line15.content2'
         ]
    },
    {
      id: 16,
      title: 'evaluation_accordion.line16.title',
      content: [
       'evaluation_accordion.line16.content1',
       'evaluation_accordion.line16.content2'
        ]
    },
    {
      id: 17,
      title: 'evaluation_accordion.line17.title',
      content: [
        'evaluation_accordion.line17.content1',
        'evaluation_accordion.line17.content2',
        'evaluation_accordion.line17.content3'
         ]
    },
    {
      id: 18,
      title: 'evaluation_accordion.line18.title',
      content: [
        'evaluation_accordion.line18.content1',
        'evaluation_accordion.line18.content2'
        ]
    }
  ];

  constructor( public publicData: PublicDataService,
               public globals: AppGlobals,
               public router: Router,
               private titleService: Title,
               private metaTagService: Meta,
               private bannersService: BannersService) {
    const language = localStorage.getItem('language');

    const amTitle = "Գրավի գնահատում - Անշարժ գույք Հայաստանում | cost.am"
    const enTitle = "Collateral valuation - Real estate in Armenia cost.am"
    const ruTitle = "Оценка залога - Недвижимость в Армении cost.am"
    const amDesc = 'Կոնսալտ ընկերությունն առաջարկում է անշարժ գույքի գնահատման ծառայություն, որը ներառում է առուվաճառքի, վարձավճարի և գրավի գնահատում'
    const enDesc = 'The consulting company offers a real estate appraisal service that includes sales, rent and mortgage appraisals.'
    const ruDesc = 'Консалтинговая компания предлагает услуги по оценке недвижимости, включая продажу, аренду и оценку ипотеки.'

    let title = ''
    let description = ''
    if(language === 'am') {
      title = amTitle
      description = amDesc
    }
    if(language === 'en') {
      title = enTitle
      description = enDesc
    }
    if(language === 'ru') {
      title = ruTitle
      description = ruDesc
    }
    this.titleService.setTitle(title);

    this.metaTagService.addTag(
      { name: 'description', content: description },
    );

    this.router.events.subscribe((val) => {
      const lang = localStorage.getItem('language');
      if(lang === 'am') {
        title = amTitle
        description = amDesc
      }
      if(lang === 'en') {
        title = enTitle
        description = enDesc
      }
      if(lang === 'ru') {
        title = ruTitle
        description = ruDesc
      }

      this.titleService.setTitle(title);
      this.metaTagService.addTag(
        { name: 'description', content: description },
      );
    });
  }

  ngOnInit() {
    this.urlForBanners = this.globals.url;
    this.localStorageLanguage = localStorage.getItem('language');

    if(this.localStorageLanguage == 'am'){
      this.publicData.language = 'am'
    }

    if(this.localStorageLanguage == 'en'){
      this.publicData.language = 'en'
    }

    if(this.localStorageLanguage == 'ru'){
      this.publicData.language = 'ru'
    }

    this.bannersService.getBanners().subscribe((data: any) => {
      this.banners = [data[0], data[1]];
    });

    this.goToTopInit();
  }

  toggleAccordion(id) {
    if(this.activeAccordion === id) {
      this.activeAccordion = null;
    } else {
      this.activeAccordion = id;
    }
  }

  goToTopInit() {
    window.scroll({
      top: 0,
      left: 0
    });
  }

}
