import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {MapComponent} from './components/map/map.component';
import {HomeComponent} from './pages/home/home.component';
import {SliderComponent} from './components/slider/slider.component';
import {ApartmentsComponent} from './components/searchForms/apartments/apartments.component';
import {HousesComponent} from './components/searchForms/houses/houses.component';
import {BusinessesComponent} from './components/searchForms/businesses/businesses.component';
import {LandsComponent} from './components/searchForms/lands/lands.component';
import {CommercialsComponent} from './components/searchForms/commercials/commercials.component';
import {CodesComponent} from './components/searchForms/codes/codes.component';
import {NewlybuiltsComponent} from './components/searchForms/newlybuilts/newlybuilts.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {AgmCoreModule} from '@agm/core';

import {AccordionModule} from 'primeng/accordion';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { DetailsComponent } from './pages/details/details.component';
import {CheckboxModule, FileUploadModule, GalleriaModule, SpinnerModule} from 'primeng/primeng';
import { DetailsSliderComponent } from './components/details-slider/details-slider.component';
import {SortablejsModule} from 'angular-sortablejs';
import { OpenMarketComponent } from './pages/open-market/open-market.component';
import { EvaluationComponent } from './pages/evaluation/evaluation.component';
import {MultiSelect, MultiSelectModule} from './components/searchForms/multiselect/multiselect.component';
import {DropdownModule} from './components/dropdown/dropdown.component';
import {TabViewModule} from 'primeng/tabview';
import {NgxPaginationModule} from 'ngx-pagination';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { BanksPageComponent } from './pages/co-workers-page/banks-page/banks-page.component';
import { CreditOrganizationsPageComponent } from './pages/co-workers-page/credit-organizations-page/credit-organizations-page.component';
import { BuildersPageComponent } from './pages/co-workers-page/builders-page/builders-page.component';
import { OurWelcomePageComponent } from './pages/about-page/our-welcome-page/our-welcome-page.component';
import { OurHistoryPageComponent } from './pages/about-page/our-history-page/our-history-page.component';
import { OurTeamPageComponent } from './pages/about-page/our-team-page/our-team-page.component';
import { OurAdvantagesPageComponent } from './pages/about-page/our-advantages-page/our-advantages-page.component';
import { OurMissionPageComponent } from './pages/about-page/our-mission-page/our-mission-page.component';
import { HomePageSliderComponent } from './components/home-page-slider/home-page-slider.component';
import { FooterSliderComponent } from './components/footer-slider/footer-slider.component';
import { DetailsPageComponent } from './pages/details-page/details-page.component';
import { DetailsPageSliderComponent } from './components/details-page-slider/details-page-slider.component';
import { NewsPageComponent } from './pages/news-page/news-page.component';
import { NewsSinglePageComponent } from './pages/news-single-page/news-single-page.component';
import { ServicesComponent } from './pages/services/services.component';
import { MarketEvaluationComponent } from './pages/services/market-evaluation/market-evaluation.component';
import { RealEstateComponent } from './pages/services/real-estate/real-estate.component';
import { CadastralMeasurementComponent } from './pages/services/cadastral-measurement/cadastral-measurement.component';
import { ToBuildersComponent } from './pages/services/to-builders/to-builders.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import {ErrorInterceptor} from "./shared/classes/ErrorInterceptor";
import { ImageTagComponent } from './components/image-tag/image-tag.component';
import {BannersComponent} from "./components/banners/banners.component";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent,
    DetailsComponent,
    HeaderComponent,
    FooterComponent,
    MapComponent,
    HomeComponent,
    SliderComponent,
    ApartmentsComponent,
    HousesComponent,
    BusinessesComponent,
    LandsComponent,
    CommercialsComponent,
    CodesComponent,
    NewlybuiltsComponent,
    DetailsSliderComponent,
    OpenMarketComponent,
    EvaluationComponent,
    ContactPageComponent,
    AboutPageComponent,
    BanksPageComponent,
    CreditOrganizationsPageComponent,
    BuildersPageComponent,
    OurWelcomePageComponent,
    OurHistoryPageComponent,
    OurTeamPageComponent,
    OurAdvantagesPageComponent,
    OurMissionPageComponent,
    HomePageSliderComponent,
    FooterSliderComponent,
    DetailsPageComponent,
    DetailsPageSliderComponent,
    NewsPageComponent,
    NewsSinglePageComponent,
    ServicesComponent,
    MarketEvaluationComponent,
    RealEstateComponent,
    CadastralMeasurementComponent,
    ToBuildersComponent,
    NotFoundComponent,
    ImageTagComponent,
    BannersComponent
  ],
  imports: [
    BrowserModule,
    SlickCarouselModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SlickCarouselModule,
    HttpClientModule,
    AccordionModule,
    MultiSelectModule,
    FormsModule,
    FileUploadModule,
    ReactiveFormsModule,
    SpinnerModule,
    GalleriaModule,
    DropdownModule,
    SortablejsModule.forRoot({animation: 150}),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBrq8jkgCgDa3AvM5EmGPc39t1l_sVNdOk',
      libraries: ['places']
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CheckboxModule,
    MultiSelectModule,
    TabViewModule,
    NgxPaginationModule
  ],
providers: [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
  }
],
  bootstrap: [AppComponent]
})

export class AppModule {
}
