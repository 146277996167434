import {Component, ElementRef, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {MapInfoService} from '../../shared/services/map-info.service';
import {PublicDataService} from '../../shared/services/public-data.service';
import {Router} from '@angular/router';
import {MapsAPILoader, AgmMap} from '@agm/core';
import {AppGlobals} from "../../app.globals";

declare var google;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  user: string;
  _id;
  apartmentsIs = false;
  hausesIs = false;
  newlyBuiltsIs = false;
  areasIs = false;
  complexsIs = false;
  complexAreaAllIs = false;
  landsIs = false;
  businessesIs = false;
  myActualUse;
  transactions;
  transactions2;
  transactions3;
  markers: any = [];
  selectedActualUse;
  selectedTransactions;
  selectedData;
  myMarkersApartments: any = [];
  myMarkersHauses: any = [];
  myMarkersNewlyBuilts: any = [];
  myMarkersAreas: any = [];
  myMarkersLands: any = [];
  myMarkersBusinesses: any = [];
  markersTransaction: any = [];
  myActualUseMarkers: any = [];
  myActualChangeEvent;
  myTransactionsChngeEvent: any = [];
  myArrayChangeData: any = [];
  myMarkers: any = [];
  years = [];
  newData = new Date().getFullYear();
  landArea;
  landAreaArray = [];
  livingSpace;
  areaValue;
  filterActualUseMarkers = [];
  urlForFiles;
  lastLat;
  lastLng;

  lat = 40.17874215913784;
  lng = 44.51616397323682;
  zoom: number;
  address: string;
  private geoCoder: any;
  @ViewChild('search', {static: false}) public searchElement: ElementRef
  ;
  iconUrl: string;
  openedWindow : number = 0;
  isFulled = true;
  screenOptions = {
    position:  3
  };

  count = 0;
  m1;
  m2;

  public mapStyles = [
    {
      "featureType": "poi",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ];

  globalUrl;
  globalSrc;
  globalSrcRouteLanguage = 'apartment';

  // map search input start --
  bounds: google.maps.LatLngBounds;
  places: any;
  searchBox: any;
  agmMap: any;
  @ViewChild('agmMap', {static: false}) set content(map: AgmMap) {
    if (map) {
      map.mapReady.subscribe(map => {
        this.agmMap = map;
      });
    }
  }
  // map search input end --

  constructor(private appGlobal: AppGlobals,private  publicDataService: PublicDataService, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private mapService: MapInfoService, private router: Router, private globals: AppGlobals) {
    this.globalUrl = appGlobal.urlForFiles;
    this.urlForFiles = globals.urlForFiles;
    this.transactions = publicDataService.transactions;
    this.transactions2 = publicDataService.transactionsCommercial;
    this.transactions3 = publicDataService.transactionOnlySale;

    // kept as draft (old logic where we get all type-s on init) start
    // Բնակարան +
    // this.mapService.getMarkers('Բնակարան').subscribe((data) => {
    //
    //   this.myMarkersApartments = data;
    //   this.myMarkersApartments = this.myMarkersApartments.filter(item => item.toShow == true);
    //
    //   this.globalSrcRouteLanguage = 'apartment';
    //   if(!this.publicDataService.initialMapClicker) {
    //     setTimeout(()=>{
    //       // console.log('start !!!')
    //       this.myApartament()
    //     },500);
    //   }
    //
    // });

    // Առանձնատուն +
    // this.mapService.getMarkers('Առանձնատուն').subscribe((data) => {
    //   this.myMarkersHauses = data;
    //   this.myMarkersHauses = this.myMarkersHauses.filter(item => item.toShow == true);
    //   this.globalSrcRouteLanguage = 'house';
    // });

    // Տարածք +
    // this.mapService.getMarkers('Տարածք').subscribe((data) => {
    //   this.myMarkersAreas = data;
    //   this.myMarkersAreas = this.myMarkersAreas.filter(item => item.toShow == true);
    //   this.myMarkersAreas = this.myMarkersAreas.filter(item => (item.actualUse == ("Հասարակական") || item.actualUse == ("Առևտրային") || item.actualUse == ("Տեխսպասարկում") ||
    //   item.actualUse == ("Բենզալցակայան") || item.actualUse == ("ԱԳԼՃԿ") || item.actualUse == ("Արտադրական") || item.actualUse == ("Հիդրոէլեկտրակայան") || item.actualUse == ("Ընդերքի օգտագործում") ||
    //   item.actualUse == ("Անասնապահական") || item.actualUse == ("Թռչնաֆաբրիկա") || item.actualUse == ("Ձկնաբուծարան") || item.actualUse == ("Ջերմոց") || item.actualUse == ("Հյուրանոց հոթել, մոթել") ||
    //   item.actualUse == ("Ռեստորան-հյուրանոց") || item.actualUse == ("Ռեստորան") || item.actualUse == ("Սրճարան") || item.actualUse == ("Նկուղ կիսանկուղ")  || item.actualUse == ("Սառնարան") ||
    //   item.actualUse == ("Սպորտային Համալիր")));
    //
    //   this.globalSrcRouteLanguage = 'area';
    // });

    // Հողամաս +
    // this.mapService.getMarkers('Հողամաս').subscribe((data) => {
    //   this.myMarkersLands = data;
    //   this.myMarkersLands = this.myMarkersLands.filter(item => item.toShow == true);
    //
    //   this.globalSrcRouteLanguage = 'land';
    // });

    // Բիզնես +
    // this.mapService.getMarkers('Բիզնես').subscribe((data) => {
    //   this.myMarkersBusinesses = data;
    //   this.myMarkersBusinesses = this.myMarkersBusinesses.filter(item => item.toShow == true);
    //
    //   this.globalSrcRouteLanguage = 'business';
    // });

    // Նորակառույց +
    // this.mapService.getMarkers('Նորակառույց').subscribe((data) => {
    //   this.myMarkersNewlyBuilts = data;
    //   this.myMarkersNewlyBuilts = this.myMarkersNewlyBuilts.filter(item => item.toShow == true);
    //
    //   this.globalSrcRouteLanguage = 'complex';
    // });
    // kept as draft (old logic where we get all type-s on init) end


    this.globalSrc = this.globalUrl + "_uploads/" + this.globalSrcRouteLanguage  + "/images/";
  }

  ngOnInit() {
    for (let i = 2017; i < this.newData + 1; i++) {
      this.years = this.years.concat({label: i, value: i});
    }
    this.selectedData = new Date().getFullYear();
    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
    });


    // map search input start --
    setTimeout(()=> {
      this.places = this.searchElement;
      this.bounds = new google.maps.LatLngBounds();
      this.searchBox = new google.maps.places.SearchBox(
        this.searchElement.nativeElement
      );
      this.searchBox.addListener('places_changed', () => {
        const places = this.searchBox.getPlaces();
        if (places.length == 0) {
          return;
        } else {
          this.zoom = 18;
        }

        this.bounds = new google.maps.LatLngBounds();
        places.forEach(place => {
          if (!place.geometry) {
            return;
          }
          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            this.bounds.union(place.geometry.viewport);
          } else {
            this.bounds.extend(place.geometry.location);
          }
          this.zoom = 18;
        });
        this.agmMap.fitBounds(this.bounds);
      });
    },1500)
    // map search input end --

  }

  openWindow(m, _id) {
    let lang = ''
    const rout = this.router.url.substring(1)
    // console.log('rout ', rout)
    if(rout.startsWith('en')) {
      lang = 'en'
    }
    if(rout.startsWith('ru')) {
      lang = 'ru'
    }
    if(rout.startsWith('am') || rout === '') {
      lang = ''
    }

    this.openedWindow = _id; // alternative: push to array of numbers
    this.count++;
    if(this.count == 1){
      this.m1 = m;
    }
    if(this.count == 2){
      this.m2 = m;
      if(this.m2 === this.m1){
        // go to details markers
        let data = m;
        window.open(window.location.origin + `${lang !== '' ? '/' : ''}${lang}/details/${data._id}/${data.announcementType == 'Բնակարան' ? 'apartments' :
          data.announcementType == 'Առանձնատուն' ? 'houses' :
            // cart.announcementType == 'Կոմերցիոն' ? 'commercials' :
            data.announcementType == 'Տարածք' ? 'commercials' :
              (data.announcementType == 'Համալիր' && data.purpose == 'Բազմաֆունկցիոնալ' && data.operational == 'Բազմաֆունկցիոնալ' && (data.actualUse == 'Բազմաֆունկցիոնալ' || data.actualUse == 'Նորակառույց')) ? 'newlyBuilds' :
                data.announcementType == 'Համալիր' ? 'commercials' :
                  data.announcementType == 'Հողամաս' ? 'lands' :
                    data.announcementType == 'Բիզնես' ? 'businesses' : null
          }`, '_blank');
        // go to details markers
        this.count = 0;
      }else{
        this.m1 = this.m2;
        this.count = 1;
      }
    }
  }
  isInfoWindowOpen(_id) {
    return this.openedWindow == _id; // alternative: check if id is in array
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    // this.zoom = 15;
    this.zoom = 18;
  }

  myChangeFilter() {
    this.markersTransaction = [];
    this.myActualUseMarkers = [];
    this.markers = [];
    this.myArrayChangeData = [];
    this.landAreaArray = [];
    this.landAreaArray = this.myMarkers;
    return this.landAreaArray;
  }

  selectTransactions(event) {

    this.myTransactionsChngeEvent = event;
     this.landAreaArray =  this.myChangeFilter();

    if (this.hausesIs === true || this.apartmentsIs === true ||  this.newlyBuiltsIs === true) {
      if (event.length > 0) {
        for (let i = 0; i < event.length; i++) {
          this.markersTransaction = this.markersTransaction.concat(this.landAreaArray.filter(m => m.transactions.indexOf(event[i]) > -1));
        }
        for (let i = 0; i < this.markersTransaction.length; i++) {
          for (let j = 0; j < event.length; j++) {
            if (this.markersTransaction[i].transactions.indexOf(event[j]) > -1) {
              if (this.newlyBuiltsIs !== true) {
                this.markersTransaction[i].iconUrl = {
                  url: "../../../assets/images/mapMarkers/" + this.markersTransaction[i].announcementType + event[j] + ".png",
                  scaledSize: {
                    height: 24,
                    width: 24
                  }
                };
              } else {
                this.markersTransaction[i].iconUrl = {
                  url: "../../../assets/images/mapMarkers/" + "Նորակառույց" + event[j] + ".png",
                  scaledSize: {
                    height: 24,
                    width: 24
                  }
                };
              }
            }
          }
        }
      }else{
        this.markersTransaction = this.landAreaArray;
      }
    }else if(this.areasIs == true || this.complexsIs == true || this.businessesIs == true || this.landsIs){
      if (this.myActualChangeEvent != null) {
        this.myActualUseMarkers = this.myActualUseMarkers.concat(this.landAreaArray.filter(m => m.actualUse == this.myActualChangeEvent));
      } else {
        this.myActualUseMarkers = this.landAreaArray;
      }
      if(event.length > 0) {
        for (let i = 0; i < event.length; i++) {
          this.markersTransaction = this.markersTransaction.concat(this.myActualUseMarkers.filter(m => m.transactions.indexOf(event[i]) > -1));
        }
        for (let i = 0; i < this.markersTransaction.length; i++) {
          for (let j = 0; j < event.length; j++) {
            if (this.markersTransaction[i].transactions.indexOf(event[j]) > -1) {
              this.markersTransaction[i].iconUrl = {
                url: "../../../assets/images/mapMarkers/Commercial/"  +  this.markersTransaction[i].announcementType + this.markersTransaction[i].actualUse + event[j] + ".png",
                scaledSize: {
                  height: 24,
                  width: 24
                }
              };
            }
          }
        }

      }else {
        this.markersTransaction = this.myActualUseMarkers
      }
    }
    this.markers = this.markersTransaction;
  }
  errorImageLoad(e) {
    e.target.src = 'https://via.placeholder.com/150';
  }
  selectActualUse() {
    let event = this.publicDataService.complexIsDropdown;

    this.myActualChangeEvent = event;
    this.landAreaArray =  this.myChangeFilter();
    this.myActualUseMarkers = this.myActualUseMarkers.concat(this.landAreaArray.filter(m => m.actualUse == event));

    if (this.myTransactionsChngeEvent.length > 0) {
      this.filterActualUseMarkers = [];
      for (let i = 0; i < this.myActualUseMarkers.length; i++) {
        for (let j = 0; j < this.myActualUseMarkers[i].transactions.length; j++) {
          for (let k = 0; k < this.myTransactionsChngeEvent.length; k++) {
            if (this.myTransactionsChngeEvent[k] == this.myActualUseMarkers[i].transactions[j]) {
             // console.log("../../../../assets/images/mapMarkers/Commercial/" +  this.myActualUseMarkers[i].announcementType  + this.myActualUseMarkers[i].actualUse + this.myTransactionsChngeEvent[k] + ".png")
              this.filterActualUseMarkers.push(this.myActualUseMarkers[i]);
              this.myActualUseMarkers[i].iconUrl = {
                url: "../../../../assets/images/mapMarkers/Commercial/" +  this.myActualUseMarkers[i].announcementType  + this.myActualUseMarkers[i].actualUse  + this.myTransactionsChngeEvent[k] + ".png",
                scaledSize: {
                  height: 24,
                  width: 24
                }
              };
            }
          }

        }
      }
      this.markers =  this.filterActualUseMarkers;
    } else {
      this.markers = this.myActualUseMarkers;
    }
    if (event == undefined){
      this.markers = this.landAreaArray;
    }


  }

  // map marker filers
  filters = {
    lat: '0,0',
    lng: '0,0',
  }
  handleBoundsChange(latLngBounds){
    // console.log('latLngBounds ', latLngBounds)

    const latBound_g = latLngBounds[Object.keys(latLngBounds)[0]];
    const latBound_i = latLngBounds[Object.keys(latLngBounds)[0]];
    const lngBound_g = latLngBounds[Object.keys(latLngBounds)[1]];
    const lngBound_i = latLngBounds[Object.keys(latLngBounds)[1]];

    const latBound_g_value = latBound_g[Object.keys(latBound_g)[0]]
    const latBound_i_value = latBound_i[Object.keys(latBound_i)[1]]
    const lngBound_g_value = lngBound_g[Object.keys(lngBound_g)[0]]
    const lngBound_i_value = lngBound_i[Object.keys(lngBound_i)[1]]

    // console.log('1 ', latBound_g_value)
    // console.log('2 ', latBound_i_value)
    // console.log('3 ', lngBound_g_value)
    // console.log('4 ', lngBound_i_value)

    this.filters = {
      ...this.filters,
      lat: `${latBound_g_value},${latBound_i_value}`,
      lng: `${lngBound_g_value},${lngBound_i_value}`,
    }

  }
  getData() {
    // console.log('idle getData ', this.filters)
    if(this.apartmentsIs) {
      this.myApartament()
    }
    if(this.hausesIs) {
      this.myHauses()
    }
    if(this.newlyBuiltsIs) {
      this.myNewlyBuilts()
    }
    if(this.areasIs) {
      this.myAreas()
    }
    if(this.businessesIs) {
      this.myBusinesses()
    }
    if(this.landsIs) {
      this.myLands()
    }
  }
  // map marker filers

  ////////////////// Բնակարան +
  myApartament(){
    const requestData = {
      ...this.filters,
      lastLat: this.lastLat,
      lastLng: this.lastLng
    };

    this.mapService.getMapMarkers('Բնակարան', requestData).subscribe((data) => {
      this.lastLat = this.filters.lat;
      this.lastLng = this.filters.lng;
      this.myMarkersApartments = data;
      this.myMarkersApartments = this.myMarkersApartments.filter(item => item.toShow == true);

      this.globalSrcRouteLanguage = 'apartment';
      // if(!this.publicDataService.initialMapClicker) {
      //   setTimeout(()=>{
      //     // console.log('start !!!')
      //     this.myApartament()
      //   },500);
      // }
      // start +

    this.selectedTransactions = null;
    this.selectedData = new Date().getFullYear();
    this.myMarkers = [];
    this.apartmentsIs = true;
    this.hausesIs = false;
    this.newlyBuiltsIs = false;
    this.areasIs = false;
    this.complexsIs = false;
    this.landsIs = false;
    this.businessesIs = false;
    this.myMarkers = this.myMarkersApartments;
    for (let i = 0; i < this.myMarkers.length; i++) {
      console.log(this.myMarkers[i].created);
      this.myMarkers[i].iconUrl = {
        url: "../../../assets/images/mapMarkers/" + this.myMarkers[i].announcementType + this.myMarkers[i].transactions[0] +".png",
        scaledSize: {
          height: 24,
          width: 24
        }
      };
    }
    this.markers = [...this.markers, ...this.myMarkers];
    return [...this.markers, ...this.myMarkers];

    }); // end +
  }
  ////////////////// Առանձնատուն +
  myHauses(){
    const requestData = {
      ...this.filters,
      lastLat: this.lastLat,
      lastLng: this.lastLng
    };
    this.mapService.getMapMarkers('Առանձնատուն', requestData).subscribe((data) => {
      this.myMarkersHauses = data;
      this.myMarkersHauses = this.myMarkersHauses.filter(item => item.toShow == true);
      this.globalSrcRouteLanguage = 'house';
      // start +

    this.myMarkers = [];
    this.selectedTransactions = null;
    this.selectedData = new Date().getFullYear();
    this.apartmentsIs = false;
    this.hausesIs = true;
    this.newlyBuiltsIs = false;
    this.areasIs = false;
    this.complexsIs = false;
    this.landsIs = false;
    this.businessesIs = false;
    this.myMarkers = this.myMarkersHauses;
    for (let i = 0; i < this.myMarkers.length; i++) {
      this.myMarkers[i].iconUrl = {
        url: "../../../assets/images/mapMarkers/" + this.myMarkers[i].announcementType + this.myMarkers[i].transactions[0] +".png",
        scaledSize: {
          height: 24,
          width: 24
        }
      };
    }
      this.markers = [...this.markers, ...this.myMarkers];

    }); // end +
  }
  ///////////////////////////////// Նորակառույց +
  myNewlyBuilts(){
    const requestData = {
      ...this.filters,
      lastLat: this.lastLat,
      lastLng: this.lastLng
    };
    this.mapService.getMapMarkers('Նորակառույց', requestData).subscribe((data) => {
      this.myMarkersNewlyBuilts = data;
      this.myMarkersNewlyBuilts = this.myMarkersNewlyBuilts.filter(item => item.toShow == true);

      this.globalSrcRouteLanguage = 'complex';
      // start +

    this.selectedTransactions = null;
    this.selectedData = new Date().getFullYear();
    this.myMarkers = [];
    this.apartmentsIs = false;
    this.hausesIs = false;
    this.newlyBuiltsIs = true;
    this.areasIs = false;
    this.complexsIs = false;
    this.landsIs = false;
    this.businessesIs = false;
    this.myMarkers = this.myMarkersNewlyBuilts;

    for (let i = 0; i < this.myMarkers.length; i++) {
      this.myMarkers[i].iconUrl = {
        url: "../../../assets/images/mapMarkers/" + "Նորակառույց"  +  this.myMarkers[i].transactions[0] + ".png",
        scaledSize: {
          height: 24,
          width: 24
        }
      };
    }
      this.markers = [...this.markers, ...this.myMarkers];

    }); // end +
  }
  /////////////////////// Տարածք +
  myAreas(){
    const requestData = {
      ...this.filters,
      lastLat: this.lastLat,
      lastLng: this.lastLng
    };
    this.mapService.getMapMarkers('Տարածք', requestData).subscribe((data) => {
      this.myMarkersAreas = data;
      this.myMarkersAreas = this.myMarkersAreas.filter(item => item.toShow == true);
      this.myMarkersAreas = this.myMarkersAreas.filter(item => (item.actualUse == ("Հասարակական") || item.actualUse == ("Առևտրային") || item.actualUse == ("Տեխսպասարկում") ||
        item.actualUse == ("Բենզալցակայան") || item.actualUse == ("ԱԳԼՃԿ") || item.actualUse == ("Արտադրական") || item.actualUse == ("Հիդրոէլեկտրակայան") || item.actualUse == ("Ընդերքի օգտագործում") ||
        item.actualUse == ("Անասնապահական") || item.actualUse == ("Թռչնաֆաբրիկա") || item.actualUse == ("Ձկնաբուծարան") || item.actualUse == ("Ջերմոց") || item.actualUse == ("Հյուրանոց հոթել, մոթել") ||
        item.actualUse == ("Ռեստորան-հյուրանոց") || item.actualUse == ("Ռեստորան") || item.actualUse == ("Սրճարան") || item.actualUse == ("Նկուղ կիսանկուղ")  || item.actualUse == ("Սառնարան") ||
        item.actualUse == ("Սպորտային Համալիր")));

      this.globalSrcRouteLanguage = 'area';
      // start +

    this.selectedTransactions = null;
    this.selectedActualUse = null;
    this.selectedData = new Date().getFullYear();
    this.myMarkers = [];
    this.apartmentsIs = false;
    this.hausesIs = false;
    this.newlyBuiltsIs = false;
    this.areasIs = true;
    this.complexsIs = false;
    this.complexAreaAllIs = true;
    this.landsIs = false;
    this.businessesIs = false;
    this.myActualUse = this.publicDataService.actualUseCommercial;
    this.myMarkers = this.myMarkersAreas;
    for (let i = 0; i < this.myMarkers.length; i++) {
      this.myMarkers[i].iconUrl = {
        url: "../../../assets/images/mapMarkers/Commercial/"  +  this.myMarkers[i].announcementType  + (this.myMarkers[i].actualUse == 'Հյուրանոց /հոթել, մոթել' ? 'Հյուրանոցհոթելմոթել' : this.myMarkers[i].actualUse) + this.myMarkers[i].transactions[0] +".png",
        scaledSize: {
          height: 24,
          width: 24
        }
      };
    }
      this.markers = [...this.markers, ...this.myMarkers];

    }); // end +
  }
  /////////////////// Բիզնես +
  myBusinesses(){
    const requestData = {
      ...this.filters,
      lastLat: this.lastLat,
      lastLng: this.lastLng
    };
    this.mapService.getMapMarkers('Բիզնես', requestData).subscribe((data) => {
      this.myMarkersBusinesses = data;
      this.myMarkersBusinesses = this.myMarkersBusinesses.filter(item => item.toShow == true);

      this.globalSrcRouteLanguage = 'business';
      // start +

    this.selectedTransactions = null;
    this.selectedActualUse = null;
    this.selectedData = new Date().getFullYear();
    this.myMarkers = [];
    this.apartmentsIs = false;
    this.hausesIs = false;
    this.newlyBuiltsIs = false;
    this.areasIs = false;
    this.complexsIs = false;
    this.landsIs = false;
    this.businessesIs = true;
    this.myMarkers = this.myMarkersBusinesses;
    for (let i = 0; i < this.myMarkers.length; i++) {
      this.myMarkers[i].iconUrl = {
        url: "../../../assets/images/mapMarkers/Commercial/" +  this.myMarkers[i].announcementType + this.myMarkers[i].transactions[0] +".png",
        scaledSize: {
          height: 24,
          width: 24
        }
      };
    }
      this.markers = [...this.markers, ...this.myMarkers];

    }); // end +
  }
  /////////////////////// Հող +
  myLands(){
    const requestData = {
      ...this.filters,
      lastLat: this.lastLat,
      lastLng: this.lastLng
    };
    this.mapService.getMapMarkers('Հողամաս', requestData).subscribe((data) => {
      this.myMarkersLands = data;
      this.myMarkersLands = this.myMarkersLands.filter(item => item.toShow == true);

      this.globalSrcRouteLanguage = 'land';
      // start +

    this.selectedTransactions = null;
    this.selectedActualUse = null;
    this.selectedData = new Date().getFullYear();
    this.myMarkers = [];
    this.apartmentsIs = false;
    this.hausesIs = false;
    this.newlyBuiltsIs = false;
    this.areasIs = false;
    this.complexsIs = false;
    this.landsIs = true;
    this.businessesIs = false;
    this.myMarkers = this.myMarkersLands;
    for (let i = 0; i < this.myMarkers.length; i++) {
      this.myMarkers[i].iconUrl = {
        url: "../../../assets/images/mapMarkers/Commercial/" + this.myMarkers[i].announcementType + this.myMarkers[i].transactions[0] +".png",
        scaledSize: {
          height: 24,
          width: 24
        }
      };
    }
      this.markers = [...this.markers, ...this.myMarkers];

    }); // end +
  }

  myCommercial(){
    this.myAreas();
    this.myActualChangeEvent = null;
  }

  // go to details
  goToDetails(m) {
    // router language
    let lang = ''
    const rout = this.router.url.substring(1)
    // console.log('rout ', rout)
    if(rout.startsWith('en')) {
      lang = 'en'
    }
    if(rout.startsWith('ru')) {
      lang = 'ru'
    }
    if(rout.startsWith('am') || rout === '') {
      lang = ''
    }

    let data = m;
    // go to details markers
    window.open(window.location.origin + `${lang !== '' ? '/' : ''}${lang}/details/${data._id}/${data.announcementType == 'Բնակարան' ? 'apartments' :
      data.announcementType == 'Առանձնատուն' ? 'houses' :
        // cart.announcementType == 'Կոմերցիոն' ? 'commercials' :
        data.announcementType == 'Տարածք' ? 'commercials' :
          (data.announcementType == 'Համալիր' && data.purpose == 'Բազմաֆունկցիոնալ' && data.operational == 'Բազմաֆունկցիոնալ' && (data.actualUse == 'Բազմաֆունկցիոնալ' || data.actualUse == 'Նորակառույց')) ? 'newlyBuilds' :
            data.announcementType == 'Համալիր' ? 'commercials' :
              data.announcementType == 'Հողամաս' ? 'lands' :
                data.announcementType == 'Բիզնես' ? 'businesses' : null
    }`, '_blank');
    // go to details markers
  }

  transactionCheckboxBg(){
    let event = this.publicDataService.markers;
    this.myTransactionsChngeEvent = event;
    this.landAreaArray =  this.myChangeFilter();
    if (this.hausesIs === true || this.apartmentsIs === true ||  this.newlyBuiltsIs === true) {
      if (event.length > 0) {
        for (let i = 0; i < event.length; i++) {
          this.markersTransaction = this.markersTransaction.concat(this.landAreaArray.filter(m => m.transactions.indexOf(event[i]) > -1));
        }
        for (let i = 0; i < this.markersTransaction.length; i++) {
          for (let j = 0; j < event.length; j++) {
            if (this.markersTransaction[i].transactions.indexOf(event[j]) > -1) {
              if (this.newlyBuiltsIs !== true) {
                this.markersTransaction[i].iconUrl = {
                  url: "../../../assets/images/mapMarkers/" + this.markersTransaction[i].announcementType + event[j] + ".png",
                  scaledSize: {
                    height: 24,
                    width: 24
                  }
                };
              } else {
                this.markersTransaction[i].iconUrl = {
                  url: "../../../assets/images/mapMarkers/" + "Նորակառույց" + event[j] + ".png",
                  scaledSize: {
                    height: 24,
                    width: 24
                  }
                };
              }
            }
          }
        }
      }else{
        this.markersTransaction = this.landAreaArray;
      }
    }else if(this.areasIs == true || this.complexsIs == true || this.businessesIs == true || this.landsIs){
      if (this.myActualChangeEvent != null) {
        this.myActualUseMarkers = this.myActualUseMarkers.concat(this.landAreaArray.filter(m => m.actualUse == this.myActualChangeEvent));
      } else {
        this.myActualUseMarkers = this.landAreaArray;
      }
      if(event.length > 0) {
        for (let i = 0; i < event.length; i++) {
          this.markersTransaction = this.markersTransaction.concat(this.myActualUseMarkers.filter(m => m.transactions.indexOf(event[i]) > -1));
        }
        for (let i = 0; i < this.markersTransaction.length; i++) {
          for (let j = 0; j < event.length; j++) {
            if (this.markersTransaction[i].transactions.indexOf(event[j]) > -1 && this.complexAreaAllIs !== true) {
              this.markersTransaction[i].iconUrl = {
                url: "../../../assets/images/mapMarkers/Commercial/"  +  this.markersTransaction[i].announcementType + event[j] + ".png",
                scaledSize: {
                  height: 24,
                  width: 24
                }
              };
              //
            }else if (this.markersTransaction[i].transactions.indexOf(event[j]) > -1) {
              this.markersTransaction[i].iconUrl = {
                url: "../../../assets/images/mapMarkers/Commercial/"  +  this.markersTransaction[i].announcementType + this.markersTransaction[i].actualUse + event[j] + ".png",
                scaledSize: {
                  height: 24,
                  width: 24
                }
              };
            }
          }
        }
      }else {
        this.markersTransaction = this.myActualUseMarkers
      }
    }
    this.markers = this.markersTransaction;
  }

}

