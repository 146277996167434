import {Component, OnInit} from '@angular/core';
import {AppGlobals} from "../../app.globals";
import {PublicDataService} from "../../shared/services/public-data.service";

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {
  slides = [];

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: '<div class=\'nav-btn next-slide\'></div>',
    prevArrow: '<div class=\'nav-btn prev-slide\'></div>',
    dots: true,
    infinite: false,
    autoplay: true
  };

  url;

  localStorageLanguage;

  constructor( private appGlobals: AppGlobals, public publicData: PublicDataService) {
  }

  ngOnInit() {
    this.localStorageLanguage = localStorage.getItem('language');
    this.url = this.appGlobals.url;
    if(!localStorage.getItem('language')){
      this.slides = [
        {img: this.url + '/uploads/home-slider/slide1-am.jpg'},
        {img: this.url + '/uploads/home-slider/slide2-am.jpg'},
        {img: this.url + '/uploads/home-slider/slide3-am.jpg'}
      ]
    }

    if(this.localStorageLanguage == 'am'){
      this.slides = [
        {img: this.url + '/uploads/home-slider/slide1-am.jpg'},
        {img: this.url + '/uploads/home-slider/slide2-am.jpg'},
        {img: this.url + '/uploads/home-slider/slide3-am.jpg'}
      ]
    }

    if(this.localStorageLanguage == 'en'){
      this.slides = [
        {img: this.url + '/uploads/home-slider/slide1-en.jpg'},
        {img: this.url + '/uploads/home-slider/slide2-en.jpg'},
        {img: this.url + '/uploads/home-slider/slide3-en.jpg'}
      ]
    }

    if(this.localStorageLanguage == 'ru'){
      this.slides = [
        {img: this.url + '/uploads/home-slider/slide1-ru.jpg'},
        {img: this.url + '/uploads/home-slider/slide2-ru.jpg'},
        {img: this.url + '/uploads/home-slider/slide3-ru.jpg'}
      ]
    }
  }

  ngDoCheck(){
    if(this.localStorageLanguage != localStorage.getItem('language')){
      this.localStorageLanguage = localStorage.getItem('language')
      if(this.localStorageLanguage == 'am'){
        this.slides = [
          {img: this.url + '/uploads/home-slider/slide1-am.jpg'},
          {img: this.url + '/uploads/home-slider/slide2-am.jpg'},
          {img: this.url + '/uploads/home-slider/slide3-am.jpg'}
        ]
      }

      if(this.localStorageLanguage == 'en'){
        this.slides = [
          {img: this.url + '/uploads/home-slider/slide1-en.jpg'},
          {img: this.url + '/uploads/home-slider/slide2-en.jpg'},
          {img: this.url + '/uploads/home-slider/slide3-en.jpg'}
        ]
      }

      if(this.localStorageLanguage == 'ru'){
        this.slides = [
          {img: this.url + '/uploads/home-slider/slide1-ru.jpg'},
          {img: this.url + '/uploads/home-slider/slide2-ru.jpg'},
          {img: this.url + '/uploads/home-slider/slide3-ru.jpg'}
        ]
      }
    }
  }

}
