import { Component, OnInit } from '@angular/core';
import {DataService} from "../../shared/services/data.service";
import {PublicDataService} from "../../shared/services/public-data.service";
import {EventsService} from "../../shared/services/events.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  subscribeForm: FormGroup;

  user: string;
  topCarts = [];

  constructor(private service: DataService, private publicData: PublicDataService, private events: EventsService, private fb: FormBuilder, private dataService: DataService) { }

  ngOnInit() {
    this.service.getRandoms(this.publicData.randomSumArray()).subscribe((data: any) => {
      this.topCarts = data;
    });
    this.formControl();
  }

  formControl(){
    this.subscribeForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]]
    })
  }

  submitSubscribeForm() {
    this.dataService.sendSubscribeEmail(this.subscribeForm.value).subscribe(data => {})
    setTimeout(() => {
      this.subscribeForm.reset();
    }, 200)
  }

  onChangeFooterSlider(value) {
    this.events.ChangeFooterSlider$.next(value);
  }

}
