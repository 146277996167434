import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {HomeComponent} from './pages/home/home.component';
import {ApartmentsComponent} from './components/searchForms/apartments/apartments.component';
import {HousesComponent} from './components/searchForms/houses/houses.component';
import {CommercialsComponent} from './components/searchForms/commercials/commercials.component';
import {LandsComponent} from './components/searchForms/lands/lands.component';
import {BusinessesComponent} from './components/searchForms/businesses/businesses.component';
import {CodesComponent} from './components/searchForms/codes/codes.component';
import {NewlybuiltsComponent} from './components/searchForms/newlybuilts/newlybuilts.component';
import {OpenMarketComponent} from "./pages/open-market/open-market.component";
import {EvaluationComponent} from "./pages/evaluation/evaluation.component";
import {ContactPageComponent} from "./pages/contact-page/contact-page.component";
import {BanksPageComponent} from "./pages/co-workers-page/banks-page/banks-page.component";
import {CreditOrganizationsPageComponent} from "./pages/co-workers-page/credit-organizations-page/credit-organizations-page.component";
import {BuildersPageComponent} from "./pages/co-workers-page/builders-page/builders-page.component";
import {OurWelcomePageComponent} from "./pages/about-page/our-welcome-page/our-welcome-page.component";
import {OurHistoryPageComponent} from "./pages/about-page/our-history-page/our-history-page.component";
import {OurTeamPageComponent} from "./pages/about-page/our-team-page/our-team-page.component";
import {OurAdvantagesPageComponent} from "./pages/about-page/our-advantages-page/our-advantages-page.component";
import {OurMissionPageComponent} from "./pages/about-page/our-mission-page/our-mission-page.component";
import {DetailsPageComponent} from "./pages/details-page/details-page.component";
import {NewsPageComponent} from "./pages/news-page/news-page.component";
import {NewsSinglePageComponent} from "./pages/news-single-page/news-single-page.component";
import {ServicesComponent} from "./pages/services/services.component";
import {MarketEvaluationComponent} from "./pages/services/market-evaluation/market-evaluation.component";
import {RealEstateComponent} from "./pages/services/real-estate/real-estate.component";
import {CadastralMeasurementComponent} from "./pages/services/cadastral-measurement/cadastral-measurement.component";
import {ToBuildersComponent} from "./pages/services/to-builders/to-builders.component";
import {NotFoundComponent} from "./pages/not-found/not-found.component";
import {LanguageService} from "./shared/services/language.service";

const activeLanguage = LanguageService.getLanguage()

const routes: Routes = [
  {path: 'details/:id/:id', component: DetailsPageComponent},
  {path: 'en/details/:id/:id', component: DetailsPageComponent},
  {path: 'ru/details/:id/:id', component: DetailsPageComponent},
  {
    path: '', component: HomeComponent, children: [
      {path: '', component: ApartmentsComponent},
      {path: 'en', component: ApartmentsComponent},
      {path: 'ru', component: ApartmentsComponent},

      {path: 'houses', component: HousesComponent},
      {path: 'en/houses', component: HousesComponent},
      {path: 'ru/houses', component: HousesComponent},

      {path: 'commercials', component: CommercialsComponent},
      {path: 'en/commercials', component: CommercialsComponent},
      {path: 'ru/commercials', component: CommercialsComponent},

      {path: 'lands', component: LandsComponent},
      {path: 'en/lands', component: LandsComponent},
      {path: 'ru/lands', component: LandsComponent},

      {path: 'businesses', component: BusinessesComponent},
      {path: 'en/businesses', component: BusinessesComponent},
      {path: 'ru/businesses', component: BusinessesComponent},

      {path: 'codes', component: CodesComponent},
      {path: 'en/codes', component: CodesComponent},
      {path: 'ru/codes', component: CodesComponent},

      {path: 'newlyBuilds', component: NewlybuiltsComponent},
      {path: 'en/newlyBuilds', component: NewlybuiltsComponent},
      {path: 'ru/newlyBuilds', component: NewlybuiltsComponent},
    ]
  },
  {path: 'openMarket', component: OpenMarketComponent},
  {path: 'en/openMarket', component: OpenMarketComponent},
  {path: 'ru/openMarket', component: OpenMarketComponent},

  {path: 'evaluation', component: EvaluationComponent},
  {path: 'en/evaluation', component: EvaluationComponent},
  {path: 'ru/evaluation', component: EvaluationComponent},

  {path: 'contact-us', component: ContactPageComponent},
  {path: 'en/contact-us', component: ContactPageComponent},
  {path: 'ru/contact-us', component: ContactPageComponent},

  {path: 'about/our-welcome', component: OurWelcomePageComponent},
  {path: 'en/about/our-welcome', component: OurWelcomePageComponent},
  {path: 'ru/about/our-welcome', component: OurWelcomePageComponent},

  {path: 'about/our-history', component: OurHistoryPageComponent},
  {path: 'en/about/our-history', component: OurHistoryPageComponent},
  {path: 'ru/about/our-history', component: OurHistoryPageComponent},

  {path: 'about/our-team', component: OurTeamPageComponent},
  {path: 'en/about/our-team', component: OurTeamPageComponent},
  {path: 'ru/about/our-team', component: OurTeamPageComponent},

  {path: 'about/our-advantages', component: OurAdvantagesPageComponent},
  {path: 'en/about/our-advantages', component: OurAdvantagesPageComponent},
  {path: 'ru/about/our-advantages', component: OurAdvantagesPageComponent},

  {path: 'about/our-mission', component: OurMissionPageComponent},
  {path: 'en/about/our-mission', component: OurMissionPageComponent},
  {path: 'ru/about/our-mission', component: OurMissionPageComponent},

  {path: 'co-workers/banks', component: BanksPageComponent},
  {path: 'en/co-workers/banks', component: BanksPageComponent},
  {path: 'ru/co-workers/banks', component: BanksPageComponent},

  {path: 'co-workers/credit-organizations', component: CreditOrganizationsPageComponent},
  {path: 'en/co-workers/credit-organizations', component: CreditOrganizationsPageComponent},
  {path: 'ru/co-workers/credit-organizations', component: CreditOrganizationsPageComponent},

  {path: 'co-workers/builders', component: BuildersPageComponent},
  {path: 'en/co-workers/builders', component: BuildersPageComponent},
  {path: 'ru/co-workers/builders', component: BuildersPageComponent},

  {path: 'news', component: NewsPageComponent},
  {path: 'en/news', component: NewsPageComponent},
  {path: 'ru/news', component: NewsPageComponent},

  {path: 'news-single/:id', component: NewsSinglePageComponent},
  {path: 'en/news-single/:id', component: NewsSinglePageComponent},
  {path: 'ru/news-single/:id', component: NewsSinglePageComponent},

  {path: 'services', component: ServicesComponent, children: [
      {path: 'market-evaluation', component: MarketEvaluationComponent},
      {path: 'real-estate', component: RealEstateComponent},
      {path: 'cadastral-measurement', component: CadastralMeasurementComponent},
      {path: 'to-builders', component: ToBuildersComponent},
      {path: '', redirectTo: '/services/market-evaluation', pathMatch: 'full'}
    ]},

  {path: 'en/services', component: ServicesComponent, children: [
      {path: 'market-evaluation', component: MarketEvaluationComponent},
      {path: 'real-estate', component: RealEstateComponent},
      {path: 'cadastral-measurement', component: CadastralMeasurementComponent},
      {path: 'to-builders', component: ToBuildersComponent},
      {path: '', redirectTo: '/en/services/market-evaluation', pathMatch: 'full'}
    ]},

  {path: 'ru/services', component: ServicesComponent, children: [
      {path: 'market-evaluation', component: MarketEvaluationComponent},
      {path: 'real-estate', component: RealEstateComponent},
      {path: 'cadastral-measurement', component: CadastralMeasurementComponent},
      {path: 'to-builders', component: ToBuildersComponent},
      {path: '', redirectTo: '/ru/services/market-evaluation', pathMatch: 'full'}
    ]},
  {path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
