import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {AppGlobals} from '../../app.globals';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  detailsGetOneRoute;
  url;

  urlForFiles;
  constructor(private http: HttpClient, private globals: AppGlobals) {
     this.url = this.globals.url;
  }

  postData(data, link) {
    return this.http.post(this.url + '/api/' + link, data);
  }
  getRandoms(item) {
    return this.http.post(this.url + '/api/random', item);
  }

  getFiltredApartmens(data) {
    return this.http.post(this.url + '/api/apartments/filtered', data);
  }

  getFiltredHouses(data) {
    return this.http.post(this.url + '/api/houses/filtered', data);
  }
  getFiltredCommercials(data) {
    return this.http.post(this.url + '/api/commercials/filtered', data);
  }
  getFiltredLands(data) {
    return this.http.post(this.url + '/api/lands/filtered', data);
  }
  getFiltredBusinesses(data) {
    return this.http.post(this.url + '/api/businesses/filtered', data);
  }
  getFiltredNewlyBuilds(data) {
    return this.http.post(this.url + '/api/newlyBuilds/filtered', data);
  }

  // API FILTERS
  getFiltredData(data, limit = 12, skip = 0) {
    // console.log('BACKEND.................................')
    return this.http.post(this.url + `/api/filter?limit=${limit}&skip=${skip}`, data)
  }
  // API FILTERS

  getFiltredCode(data) {
    return this.http.get(this.url + `/api/codes/?code=${data}`);
  }
  getOneApartments(type, id) {
    return this.http.get(this.url + `/api/${type}/${id}`);
  }

  getOneAnnoucement(id, type, purpose, actualUse, operational) {
    // router backend ....
    if (type == 'Բնակարան'){
      this.detailsGetOneRoute = 'apartments';
    }
    if(type == 'Առանձնատուն'){
      this.detailsGetOneRoute = 'houses';
    }
    if(type == 'Տարածք'){
      this.detailsGetOneRoute = 'commercials';
    }
    if(type == 'Համալիր'){
      this.detailsGetOneRoute = 'commercials';
    }
    if(type == 'Համալիր' && (actualUse === 'Բազմաֆունկցիոնալ' || actualUse === 'Նորակառույց')){
      this.detailsGetOneRoute = 'commercials';
    }
    if(type == 'Հողամաս'){
      this.detailsGetOneRoute = 'lands';
    }
    if(type == 'Բիզնես'){
      this.detailsGetOneRoute = 'businesses';
    }
    // router backend ....
    return this.http.get(this.url + `/api/` + this.detailsGetOneRoute + `/` + `${id}`);
  }

  sendEmail(data){
    return this.http.post(this.url + '/api/sendEmail', data);
  }

  sendSubscribeEmail(data){
    return this.http.post(this.url + '/api/sendEmail/subscribe', data);
  }


  getPartners(data) {
    return this.http.post(this.url + `/api/partners`, data);
  }

}
