import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-market-evaluation',
  templateUrl: './market-evaluation.component.html',
  styleUrls: ['./market-evaluation.component.css']
})
export class MarketEvaluationComponent implements OnInit {

  user: string;

  localStorageLanguage

  constructor( public router: Router,
               private titleService: Title,
               private metaTagService: Meta ) {
    const language = localStorage.getItem('language');

    const amTitle = "Գնահատում | Consult"
    const enTitle = "Evaluation | Consult"
    const ruTitle = "Оценка | Консульт"
    const amDesc = 'Տարբեր ֆինանսական կազմակերպությունների հետ համագործակցությամբ անշարժ և շարժական գույքի, բիզնեսի և այլ գնահատման ծառայություններ '
    const enDesc = 'Real and movable property, business and other valuation services in cooperation with various financial institutions'
    const ruDesc = 'Недвижимое и движимое имущество, бизнес и другие услуги по оценке в сотрудничестве с различными финансовыми учреждениями'

    let title = ''
    let description = ''
    if(language === 'am') {
      title = amTitle
      description = amDesc
    }
    if(language === 'en') {
      title = enTitle
      description = enDesc
    }
    if(language === 'ru') {
      title = ruTitle
      description = ruDesc
    }
    this.titleService.setTitle(title);

    this.metaTagService.addTag(
      { name: 'description', content: description },
    );

    this.router.events.subscribe((val) => {
      const lang = localStorage.getItem('language');
      if(lang === 'am') {
        title = amTitle
        description = amDesc
      }
      if(lang === 'en') {
        title = enTitle
        description = enDesc
      }
      if(lang === 'ru') {
        title = ruTitle
        description = ruDesc
      }

      this.titleService.setTitle(title);
      this.metaTagService.addTag(
        { name: 'description', content: description },
      );
    });
  }

  ngOnInit() {
    this.localStorageLanguage = localStorage.getItem('language');
  }

}
