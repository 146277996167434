import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {MapsAPILoader, AgmMap} from '@agm/core';
import {DataService} from "../../shared/services/data.service";
import {PublicDataService} from "../../shared/services/public-data.service";
import {Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.css']
})
export class ContactPageComponent implements OnInit {

  user: string;
  messageForm: FormGroup;

  lat = 40.178233;
  lng = 44.519199;
  zoom: number;
  address: string;
  private geoCoder: any;

  constructor( private mapsAPILoader: MapsAPILoader, private fb: FormBuilder, private dataService: DataService, public publicData: PublicDataService,
               public router: Router,
               private titleService: Title,
               private metaTagService: Meta) {
    const language = localStorage.getItem('language');

    const amTitle = "Հետադարձ կապ | Consult"
    const enTitle = "Contact Us | Consult"
    const ruTitle = "Обратная связь | Консульт"

    let title = ''
    if(language === 'am') {
      title = amTitle
    }
    if(language === 'en') {
      title = enTitle
    }
    if(language === 'ru') {
      title = ruTitle
    }
    this.titleService.setTitle(title);

    this.router.events.subscribe((val) => {
      const lang = localStorage.getItem('language');
      if(lang === 'am') {
        title = amTitle
      }
      if(lang === 'en') {
        title = enTitle
      }
      if(lang === 'ru') {
        title = ruTitle
      }

      this.titleService.setTitle(title);
    });
  }

  ngOnInit() {
    this.goToTopInit()
    this.formControl();
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
    });
  }

  goToTopInit() {
    window.scroll({
      top: 0,
      left: 0
    });
  }

  formControl(){
    this.messageForm = this.fb.group({
      nameSurname: [null, [Validators.required]],
      phone1: [null, [Validators.required]],
      phone2: [null],
      email: [null, [Validators.required]],
      topic: [null, [Validators.required]],
      message: [null, [Validators.required]]
    })
  }

  private setCurrentLocation() {
    this.zoom = 17;
  }

  cancelMessageForm(){
    this.messageForm.reset();
  }

  submitMessageForm(){
    this.dataService.sendEmail(this.messageForm.value).subscribe(data => {})
    this.messageForm.reset();
  }

}
