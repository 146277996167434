import {Component, Input, OnInit, HostListener} from '@angular/core';
import {AppGlobals} from "../../app.globals";
import {ImagesApiService} from "../../shared/services/images-api.service";
import {BehaviorSubject, Subject} from "rxjs";

@Component({
  selector: 'app-details-page-slider',
  templateUrl: './details-page-slider.component.html',
  styleUrls: ['./details-page-slider.component.css']
})
export class DetailsPageSliderComponent implements OnInit {

  currentMainImageVisible = true
  changeMainImageVisible = true

  @Input()images: any[];
  imagesSrcForModal = [];
  imagesSrcForModal2 = [];
  url;
  urlForFiles;
  modal;
  span;

  slideIndex = 1;
  slides;
  dots;

  imgForSlider = [];

  currentMainImage;
  currentMainImageIndex = 0
  currentMainImageModal;
  currentMainImageModalIndex = 0

  constructor(private globals: AppGlobals, private imagesApiService: ImagesApiService) {
    this.url = this.globals.url + '/uploads/';
    this.urlForFiles = this.globals.urlForFiles;
  }

  ngOnInit() {
    this.images.forEach(img => {
      if(this.imgForSlider.includes(img) == false){
        this.imgForSlider.push(img);
      }
    })
    // console.log('imgForSlider >> ', this.imgForSlider)
    this.images = this.imgForSlider;

    this.images.forEach(item => {
      this.imagesSrcForModal.push({
        // source: this.urlForFiles + item,
        source: item,
        alt: 'Description for Image 1',
        title: 'Title 1'
      });
    });

    for (let i = 0; i < this.imagesSrcForModal.length; i++) {
      this.imagesSrcForModal2.push(this.imagesSrcForModal[i].source);
    }
    // console.log('IMAGE !!! ', this.imagesSrcForModal2)
    this.currentMainImage = this.imagesSrcForModal2[0]


    this.modal = document.getElementById("myModal");
    this.span = document.getElementsByClassName("close")[0];

    this.span.addEventListener('click', () => {
      this.modal.style.display = "none";
    });

    window.addEventListener('click', (event) => {
      if (event.target == this.modal) {
        this.modal.style.display = "none";
      }
    });

    this.slides = document.getElementsByClassName("mySlides");
  }

  onImageClicked($event: any, src: any) {
    // console.log('$event ', $event)
    // console.log('$event.index ', this.slideIndex)
    this.modal.style.display = "flex";
    this.slideIndex = $event.index + 1;
    this.currentMainImageModal = this.currentMainImage;

    // console.log('$event.index ', this.slideIndex)
    // this.showSlides(this.slideIndex);

    this.currentMainImageVisible = false
    setTimeout(() => {
      this.currentMainImageVisible = true
    }, )
    // this.imagesApiService.changeSubject.next(src)
  }

  // zoom popup image start
  onImageClickedZoom(){
    let elementsZoomAll = document.getElementsByClassName('ui-panel-images');
    for(let i = 0;i<elementsZoomAll.length;i++){
      if(!elementsZoomAll[i].parentElement.classList.contains('ui-helper-hidden')){
        elementsZoomAll[i].parentElement.click()
      }
    }

  }
  // zoom popup image end

  // Next/previous controls
  // plusSlides(n) {
  //   this.showSlides(this.slideIndex += n);
  // }

  // Thumbnail image controls
  currentSlide(n) {
    this.showSlides(this.slideIndex = n);
  }

  showSlides(n) {

    if (n > this.slides.length) {
      this.slideIndex = 1
    }
    if (n < 1) {
      this.slideIndex = this.slides.length
    }
    for (let i = 0; i < this.slides.length; i++) {
      this.slides[i].style.display = "none";
    }

    this.slides[this.slideIndex - 1].style.display = "block";
  }

  onChangeMainImage(image) {
    this.currentMainImage = image;
    this.currentMainImageIndex = this.imagesSrcForModal2.indexOf(image)

    this.changeMainImageVisible = false
    setTimeout(() => {
      this.changeMainImageVisible = true
    }, )
  }

  mainImageLeftRight(e) {
    this.changeMainImageVisible = false
    setTimeout(() => {
      this.changeMainImageVisible = true
    }, )

    this.currentMainImageIndex = this.currentMainImageIndex + e;

    if(this.imagesSrcForModal2[this.currentMainImageIndex]) {
      this.currentMainImage = this.imagesSrcForModal2[this.currentMainImageIndex];
    } else {
      this.currentMainImage = this.imagesSrcForModal2[0]
      this.currentMainImageIndex = 0;
    }
  }

  plusSlides(n) {
    this.currentMainImageModalIndex = this.currentMainImageModalIndex + n;

    if(this.imagesSrcForModal2[this.currentMainImageModalIndex]) {
      this.currentMainImageModal = this.imagesSrcForModal2[this.currentMainImageModalIndex];
    } else {
      this.currentMainImageModal = this.imagesSrcForModal2[0]
      this.currentMainImageModalIndex = 0;
    }

    this.currentMainImageVisible = false
    setTimeout(() => {
      this.currentMainImageVisible = true
    }, )
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    // console.log('event.keyCode ', event.keyCode)
    // todo
    // this.currentMainImageIndex = this.currentMainImageIndex + e;
    //
    // if(this.imagesSrcForModal2[this.currentMainImageIndex]) {
    //   this.currentMainImage = this.imagesSrcForModal2[this.currentMainImageIndex];
    // } else {
    //   this.currentMainImage = this.imagesSrcForModal2[0]
    //   this.currentMainImageIndex = 0;
    // }
    // todo
    if (event.keyCode == 39){
      this.plusSlides(1);
      // todo
      this.currentMainImageIndex = this.currentMainImageIndex + 1;
      if(this.imagesSrcForModal2[this.currentMainImageIndex]) {
        this.currentMainImage = this.imagesSrcForModal2[this.currentMainImageIndex];
      } else {
        this.currentMainImage = this.imagesSrcForModal2[0]
        this.currentMainImageIndex = 0;
      }
      // todo
    } else if (event.keyCode == 37) {
      this.plusSlides(-1);
      // todo
      this.currentMainImageIndex = this.currentMainImageIndex - 1;
      if(this.imagesSrcForModal2[this.currentMainImageIndex]) {
        this.currentMainImage = this.imagesSrcForModal2[this.currentMainImageIndex];
      } else {
        this.currentMainImage = this.imagesSrcForModal2[0]
        this.currentMainImageIndex = 0;
      }
      // todo
    }

    this.currentMainImageVisible = false
    setTimeout(() => {
      this.currentMainImageVisible = true
    }, )

  }

}
