import {Component, Input, OnInit, HostListener} from '@angular/core';
import {AppGlobals} from "../../app.globals";

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {
  constructor(public globals: AppGlobals) {}

  @Input()banners: any[];
  @Input()left0: boolean = false;

  ngOnInit(): void {
  }

}
