import { Component, OnInit, OnDestroy } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {PublicDataService} from "../../../shared/services/public-data.service";
import {Router} from '@angular/router';
import {DataService} from "../../../shared/services/data.service";
import {EventsService} from "../../../shared/services/events.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-businesses',
  templateUrl: './businesses.component.html',
  styleUrls: ['./businesses.component.css']
})
export class BusinessesComponent implements OnInit, OnDestroy {

  private subscription: Subscription;

  searchFlage = false;

  form: FormGroup;
  user: string;
  cities: object[];
  communities: object[];
  streets: object[];

  dataLimit: number = 12;
  dataSkip: number = 0;
  dataTablePage = 1;

  constructor(public optionsData: PublicDataService, private router: Router, private service: DataService, private events: EventsService) {
    this.form = new FormGroup({
      announcementType: new FormControl('Բիզնես', [Validators.required]),
      transactions: new FormControl([], [Validators.required]),
      regions: new FormControl([], [Validators.required]),
      cities: new FormControl([], [Validators.required]),
      communities: new FormControl([], [Validators.required]),
      streets: new FormControl([], [Validators.required]),
      areasBusiness: new FormControl([], [Validators.required]),
      pricesStart: new FormControl('', [Validators.required]),
      pricesEnd: new FormControl('', [Validators.required]),
      currency: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit() {
    this.subscription = this.events.pageChanged$.subscribe(e => {
      if(this.events.activeSearchRoute === 'Բիզնես') {
        this.dataTablePage = e;
        this.dataSkip = (e * this.dataLimit) - this.dataLimit;
        this.formSend();
      }
    })
  }

  setBGcolor(){
    if(document.querySelectorAll('.typeOfMarkerStyleDiv1 .ui-chkbox-box')[1]){
      document.querySelectorAll('.typeOfMarkerStyleDiv1 .ui-chkbox-box')[1]["style"].backgroundColor="#FFCD00"
    }
    if(document.querySelectorAll('.typeOfMarkerStyleDiv1 .ui-chkbox-box')[2]){
      document.querySelectorAll('.typeOfMarkerStyleDiv1 .ui-chkbox-box')[2]["style"].backgroundColor="#004799"
    }
    if(document.querySelectorAll('.typeOfMarkerStyleDiv1 .ui-chkbox-box')[3]){
      document.querySelectorAll('.typeOfMarkerStyleDiv1 .ui-chkbox-box')[3]["style"].backgroundColor="#81D0F5"
    }
  }
  selectTransactions(event){
    if (this.optionsData.searchForm){
      this.optionsData.markers = event;
      document.getElementById("name").click();
    }
  }

  foo() {}

  onChange(item) {
    switch (item) {
      case 'region': {
        this.cities = [];
        this.communities = [];

        this.optionsData.cities
          .filter(items => this.form.get('regions').value
            .filter(item => item === items.city).length > 0)
          .forEach(items => {
            this.cities = this.cities.concat(items.info);
          });

        if (this.form.get('regions').value.length === 1 && this.form.get('regions').value[0] === 'Երևան') {
          this.cities = [
            {label: 'Երևան', value: 'Երևան'}
          ];
          this.form.get('cities').setValue(['Երևան']);
          this.optionsData.communities.filter(item => item.city === 'Երևան')
            .forEach(items => {
              this.communities = this.communities.concat(items.info);
            });
        } else {
          this.form.get('cities').setValue([]);
          this.form.get('communities').setValue([]);
        }
      }
        break;
      case 'cities': {
        this.communities = [];
        this.optionsData.communities.filter(items => this.form.get('cities').value
          .filter(item => item === items.city).length > 0)
          .forEach(items => {
            this.communities = this.communities.concat(items.info);
          })
      }
        break;
      case 'communities': {
        this.streets = [];
        this.optionsData.streets.filter(items => this.form.get('communities').value
          .filter(item => item == items.communities).length > 0)
          .forEach(items => {
            this.streets = this.streets.concat(items.info);
          })
      }
        break;
    }
  }

  resetPagination() {
    this.dataTablePage = 1;
    this.dataSkip = 0;
    this.events.resetPageNumber$.next(1);
  }

  formSend() {
    this.optionsData.searchForm = false;
    this.searchFlage = true;
      this.service.getFiltredData(this.form.value, this.dataLimit, this.dataSkip).subscribe((data) => {
      this.events.emitChange3(data);
      this.searchFlage = false;
    });
  }

  advancedSearch() {
    this.resetPagination()
    this.formSend();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
