import {Component, OnInit} from '@angular/core';
import {DataService} from '../../shared/services/data.service';
import {AppGlobals} from '../../app.globals';
import {EventsService} from '../../shared/services/events.service';
import {Router} from '@angular/router';
import {PublicDataService} from '../../shared/services/public-data.service';
import {TranslateService} from "@ngx-translate/core";
import {Meta, Title} from "@angular/platform-browser";
import {BannersService} from "../../shared/services/banners.service";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  banners = [];
  user: string;
  topCarts = [];
  searchPostsActive = true;
  urlForFiles = '';
  url = '';
  cycleData = [];
  announcementType;
  p = 1;

  flage1 = true;
  flage2 = false;
  flage3 = false;
  flage4 = false;
  flage5 = false;
  flage6 = false;
  flage7 = false;

  selectedTransactions;
  selectedData;
  urlForBanners;
  localStorageLanguage;

  responsiveOptions
  pageIndex;
  isActiveSearchType = 'listing';

  constructor(private translate: TranslateService,
              private service: DataService,
              public globals: AppGlobals,
              public events: EventsService,
              public router: Router,
              public publicData: PublicDataService,
              private titleService: Title,
              private metaTagService: Meta,
              private bannersService: BannersService) {
    // meta
    const language = localStorage.getItem('language');
    const amTitle = "Գլխավոր - Անշարժ գույք | cost.am"
    const enTitle = "Home - Real estate | cost.am"
    const ruTitle = "Главная - Недвижимость | Стоимость.am"
    const amDesc = 'Կոնսալտ ընկերությունն առաջարկում է անշարժ գույք,  շուկայական գնահատում, կադաստրային չափագրում։Ձեռք բերե՛ք ձեր անշարժ գույքը մեզ հետ'
    const enDesc = 'The consulting company offers real estate, market evaluation, cadastral surveying. Get your real estate with us.'
    const ruDesc = 'Консалтинговая компания предлагает недвижимость, оценку рынка, кадастровую съемку Приобретайте недвижимость вместе с нами.'

    let title = ''
    let description = ''

    if(language === 'am') {
      title = amTitle
      description = amDesc
    }
    if(language === 'en') {
      title = enTitle
      description = enDesc
    }
    if(language === 'ru') {
      title = ruTitle
      description = ruDesc
    }

    this.router.events.subscribe((val) => {
      const lang = localStorage.getItem('language');
      if(lang === 'am') {
        title = amTitle
        description = amDesc
      }
      if(lang === 'en') {
        title = enTitle
        description = enDesc
      }
      if(lang === 'ru') {
        title = ruTitle
        description = ruDesc
      }

      this.titleService.setTitle(title);
      this.metaTagService.addTag(
        { name: 'description', content: description },
      );
    });

    this.titleService.setTitle(title);
    this.metaTagService.addTag(
      { name: 'description', content: description },
    );
    // meta

    let lang = ''
    const rout = this.router.url.substring(1)
    if(rout === 'en') {
      lang = 'en'
    }
    if(rout === 'ru') {
      lang = 'ru'
    }
    if(rout === 'am' || rout === '') {
      lang = 'am'
    }

    this.localStorageLanguage = lang
    localStorage.setItem('language', lang);
    this.publicData.language = lang;
    this.translate.use(lang);

    this.urlForBanners = this.globals.url;


    this.url = this.globals.urlForFiles;

    events.changeEmitted$.subscribe(
      data => {
          this.cycleData = data
          this.searchPostsActive = false;
      });

  }


  ngOnInit() {
    this.events.activeSearchRoute = 'Բնակարան';
    this.pageIndex = 1
    // reset paginator page to 1 when search
    this.events.resetPageNumber$.subscribe(s => {
      this.pageIndex = s;
    })
    // reset paginator page to 1 when search
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
    if (localStorage.getItem('search')) {
      this.cycleData = JSON.parse(localStorage.getItem('search'));
      this.searchPostsActive = false;
    }

    this.service.getRandoms(this.publicData.randomSumArray()).subscribe((data: any) => {
      this.topCarts = data;
    });
    setTimeout(()=>{
      // document.getElementById('myApartament').click();
      // this.router.navigate(["../"])
      let lang = ''
      const rout = this.router.url.substring(1)
      if(rout === 'en') {
        lang = 'en'
      }
      if(rout === 'ru') {
        lang = 'ru'
      }
      if(rout === 'am' || rout === '') {
        lang = 'am'
      }
      localStorage.setItem('language', lang);
      this.publicData.language = lang;
      this.translate.use(lang);

      this.router.navigate([this.publicData.language !== 'am' ? '/' + this.publicData.language : "../"])
    },500);
    setTimeout(()=>{
      // console.log('get map')
      // await until image laoded, after get map markers
      document.getElementById('myApartament').click();
    // },1800);
    },600);

    this.bannersService.getBanners().subscribe((data: any) => {
      this.banners = data;
    });
  }

  onchangeSearchType(type: string): void {
    this.isActiveSearchType = type;
  }

  pageChanged(e) {
    this.events.pageChanged$.next(e);
    this.pageIndex = e
    let el = document.getElementById('searchTarget');
    el.scrollIntoView();
  }

  initSlider() {
    var $ = document;

    $.addEventListener('DOMContentLoaded', function() {

      const sliderMe = () => {
        // @ts-ignore
        // @ts-ignore
        // @ts-ignore
        let currentPosition = 0,
          sliderItem = document.querySelectorAll('.slider-item'),
          sliderItemWidth: any = window
            .getComputedStyle(sliderItem[0])
            .flexBasis.match(/\d+\.?\d+/g),
          sliderInner: any = $.querySelector('.slider-inner'),

          control = {
            next: $.querySelector('#next'),
            slideNext() {
              // @ts-ignore
              currentPosition += parseFloat(sliderItemWidth);
              if (currentPosition > limitPosition) {
                currentPosition = 0;
              }
              sliderInner.style.right = currentPosition + '%';
            },
            prev: $.querySelector('#prev'),
            slidePrev() {
              // @ts-ignore
              currentPosition -= parseFloat(sliderItemWidth);
              if (currentPosition < 0) {
                currentPosition = limitPosition;
              }
              sliderInner.style.right = currentPosition + '%';
            }
          },
          limitPosition = sliderItemWidth * (sliderItem.length - Math.floor(100 / sliderItemWidth));

        control.next.addEventListener('click', control.slideNext)
        control.prev.addEventListener('click', control.slidePrev)

        window.addEventListener("resize",function(){
          currentPosition = 0;
          const el:any = $.querySelector('.slider-inner')
          el.style.right = currentPosition + '%';
        })
      }
      sliderMe();

      window.addEventListener("resize", sliderMe)

    });

  }

  ngDoCheck(){
    if( localStorage.getItem('urlForFiless')){
      this.url = localStorage.getItem('urlForFiless');
      this.globals.urlForFiles = localStorage.getItem('urlForFiless');
    }
    if(this.localStorageLanguage != localStorage.getItem('language')){
      this.localStorageLanguage = localStorage.getItem('language')
    }
  }

  popupCloseMe() {
    var wrap: any = window.document.getElementById('popup_overlay');
    setTimeout(() => {
      wrap.classList.add("removePopup");
    }, 500)
  }


  // slick start
  slides = [
    {img: "../assets/images/1.jpg"},
    {img: "../assets/images/2.jpg"},
    {img: "../assets/images/3.jpg"},
    {img: "../assets/images/4.jpg"},
    {img: "../assets/images/5.jpg"},
    {img: "../assets/images/6.jpg"},
    {img: "../assets/images/7.jpg"},
    {img: "../assets/images/8.jpg"},
    {img: "../assets/images/9.jpg"},
    {img: "../assets/images/10.jpg"},
    {img: "../assets/images/11.jpg"},
    {img: "../assets/images/12.jpg"}
  ];
  next() {
    let elementsNext: HTMLElement = document.getElementsByClassName('next-slide')[1] as HTMLElement;
    elementsNext.click()
  }
  prev() {
    let elementsPrev: HTMLElement = document.getElementsByClassName('prev-slide')[1] as HTMLElement;
    elementsPrev.click()
  }
  slideConfig = {
    "slidesToShow": 5,
    "slidesToScroll": 2,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "dots": true,
    "infinite": false,
    responsive: [{
      breakpoint: 50000,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
  };

  errorImageLoad(e) {
    e.target.src = '../assets/images/no-picture-available.jpg';
  }
  goToDetails(cart) {
    // router language
    let lang = ''
    const rout = this.router.url.substring(1)
    console.log('rout ', rout)
    if(rout.startsWith('en')) {
      lang = 'en'
    }
    if(rout.startsWith('ru')) {
      lang = 'ru'
    }
    if(rout.startsWith('am') || rout === '') {
      lang = ''
    }

    window.open(window.location.origin + `${lang !== '' ? '/' : ''}${lang}/details/${cart._id}/${cart.announcementType == 'Բնակարան' ? 'apartments' :
      cart.announcementType == 'Առանձնատուն' ? 'houses' :
        // cart.announcementType == 'Կոմերցիոն' ? 'commercials' :
        cart.announcementType == 'Տարածք' ? 'commercials' :
          (cart.announcementType == 'Համալիր' && cart.purpose == 'Բազմաֆունկցիոնալ' && cart.operational == 'Բազմաֆունկցիոնալ' && (cart.actualUse == 'Բազմաֆունկցիոնալ' || cart.actualUse == 'Նորակառույց')) ? 'newlyBuilds' :
            cart.announcementType == 'Համալիր' ? 'commercials' :
              cart.announcementType == 'Հողամաս' ? 'lands' :
                cart.announcementType == 'Բիզնես' ? 'businesses' : null
    }`, '_blank');
  }

  goToOpenMarket() {
    window.open(window.location.origin + '/openMarket', '_blank');
  }

  goToEvaluation() {
    window.open(window.location.origin + '/evaluation', '_blank');
  }

  ngswitch(event, type) {
    // console.log('type > ', type)
    this.publicData.initialMapClicker = true;
    this.p = 1;
    this.publicData.searchForm = true;
    this.searchPostsActive = true;
    let item = event.target.innerText;
    if (event.target.dataset.id != undefined) {
      setTimeout(()=>{
        let houseId = document.getElementById(''+event.target.dataset.id);
        houseId.click();
        // console.log('click id')
      },400)
    }
    this.events.activeSearchRoute = type
    switch (item) {
      case 'Բնակարան':
        this.searchPostsActive = true;
        this.flage1 = true;
        this.flage2 = false;
        this.flage3 = false;
        this.flage4 = false;
        this.flage5 = false;
        this.flage6 = false;
        this.flage7 = false;
        break;
      case 'Առանձնատուն':
        this.searchPostsActive = true;
        this.flage1 = false;
        this.flage2 = true;
        this.flage3 = false;
        this.flage4 = false;
        this.flage5 = false;
        this.flage6 = false;
        this.flage7 = false;
        break;
      case 'Կոմերցիոն':
        this.searchPostsActive = true;
        this.flage1 = false;
        this.flage2 = false;
        this.flage3 = true;
        this.flage4 = false;
        this.flage5 = false;
        this.flage6 = false;
        this.flage7 = false;
        break;
      case 'Հողամաս':
        this.searchPostsActive = true;
        this.flage1 = false;
        this.flage2 = false;
        this.flage3 = false;
        this.flage4 = true;
        this.flage5 = false;
        this.flage6 = false;
        this.flage7 = false;
        break;
      case 'Բիզնես':
        this.searchPostsActive = true;
        this.flage1 = false;
        this.flage2 = false;
        this.flage3 = false;
        this.flage4 = false;
        this.flage5 = true;
        this.flage6 = false;
        this.flage7 = false;
        break;
      case 'Կոդ':
        this.searchPostsActive = true;
        this.flage1 = false;
        this.flage2 = false;
        this.flage3 = false;
        this.flage4 = false;
        this.flage5 = false;
        this.flage6 = true;
        this.flage7 = false;
        break;
      case 'Նորակառույց':
        this.searchPostsActive = true;
        this.flage1 = false;
        this.flage2 = false;
        this.flage3 = false;
        this.flage4 = false;
        this.flage5 = false;
        this.flage6 = false;
        this.flage7 = true;
        break;
    }

  }

  onChangeSlider(value) {
    this.events.ChangeSlider$.next(value);
  }
}
