import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";
import {DataService} from "../../../shared/services/data.service";
import {AppGlobals} from "../../../app.globals";

@Component({
  selector: 'app-banks-page',
  templateUrl: './banks-page.component.html',
  styleUrls: ['./banks-page.component.css']
})

export class BanksPageComponent implements OnInit {

  user: string;
  banks: [];
  lang: string;

  // banks = [
  //   {
  //     imageSrc: "../../../../assets/images/co-workers-page/banks/abb-bank.png",
  //     bankName: "«ՀԱՅԲԻԶՆԵՍԲԱՆԿ» ՓԲԸ",
  //     bankAddress: "ք. Երևան, Նալբանդյան փող., 48",
  //     bankPhoneNumber: "+374 10 59 20 20,  +374 60 37 25 00",
  //     bankEmail: "info@armbusinessbank.am",
  //     bankWebsite: "www.armbusinessbank.am",
  //   },
  //   {
  //     imageSrc: "../../../../assets/images/co-workers-page/banks/byblos-bank.png",
  //     bankName: "«ԲԻԲԼՈՍ ԲԱՆԿ» ՓԲԸ",
  //     bankAddress: "ք. Երևան, Ամիրյան փող., 18/3 շենք, թիվ 22",
  //     bankPhoneNumber: "+374 60 61 61 00",
  //     bankEmail: "infoarm@byblosbank.com",
  //     bankWebsite: "www.byblosbank.com",
  //   },
  //   {
  //     imageSrc: "../../../../assets/images/co-workers-page/banks/acba-bank.png",
  //     bankName: "«ԱԿԲԱ ԲԱՆԿ» ԲԲԸ",
  //     bankAddress: "ք. Երևան, Վ. Սարգսյան փ, 10 շ․, թիվ 123, 124",
  //     bankPhoneNumber: "+374 10 31 88 88",
  //     bankEmail: "acba@acba.am",
  //     bankWebsite: "www.acba.am",
  //   },
  //   {
  //     imageSrc: "../../../../assets/images/co-workers-page/banks/evoca-bank.png",
  //     bankName: "«ԷՎՈԿԱԲԱՆԿ» ՓԲԸ",
  //     bankAddress: "ք. Երևան, Հանրապետության փող., 44/2",
  //     bankPhoneNumber: "+374 10 60 55 55",
  //     bankEmail: "hello@evocabank.am",
  //     bankWebsite: "www.evocabank.am",
  //   },
  //   {
  //     imageSrc: "../../../../assets/images/co-workers-page/banks/ameria-bank.png",
  //     bankName: "«ԱՄԵՐԻԱԲԱՆԿ» ՓԲԸ",
  //     bankAddress: "ք. Երևան, Վազգեն Սարգսյան 2",
  //     bankPhoneNumber: "+374 10 56 11 11",
  //     bankEmail: "info@ameriabank.am",
  //     bankWebsite: "www.ameriabank.am",
  //   },
  //   {
  //     imageSrc: "../../../../assets/images/co-workers-page/banks/ineco-bank.png",
  //     bankName: "«ԻՆԵԿՈԲԱՆԿ» ՓԲԸ",
  //     bankAddress: "ք. Երևան, Թումանյան փող., 17",
  //     bankPhoneNumber: "+374 (10) 510 510",
  //     bankEmail: "inecobank@inecobank.am",
  //     bankWebsite: "www.inecobank.am",
  //   },
  //   {
  //     imageSrc: "../../../../assets/images/co-workers-page/banks/armsvis-bank.png",
  //     bankName: "«ԱՐՄՍՎԻՍԲԱՆԿ» ՓԲԸ",
  //     bankAddress: "ք. Երևան, Վազգեն Սարգսյան 10",
  //     bankPhoneNumber: "+374 60 75 70 00,  +374 11 75 70 00",
  //     bankEmail: "",
  //     bankWebsite: "www.armswissbank.am",
  //   },
  //   // {
  //   //   imageSrc: "../../../../assets/images/co-workers-page/banks/ardshin-bank.png",
  //   //   bankName: "«ԱՐԴՇԻՆԲԱՆԿ» ՓԲԸ",
  //   //   bankAddress: "ք. Երևան, Սուրբ Գրիգոր Լուսավորչի փող., 13",
  //   //   bankPhoneNumber: "+374 12 22 22 22",
  //   //   bankEmail: "ardshinbank@ardshinbank.am",
  //   //   bankWebsite: "www.ardshinbank.am ",
  //   // }
  //   {
  //     imageSrc: "../../../../assets/images/co-workers-page/banks/fast-bank.png",
  //     bankName: "«Ֆասթ Բանկ» ՓԲԸ",
  //     bankAddress: "0047, ՀՀ, ք. Երևան, Նորք- Մարաշ, Գ. Հովսեփյան 32/6",
  //     bankPhoneNumber: "(+374 10) 510 000",
  //     bankEmail: "fastcare@fcc.am",
  //     bankWebsite: "www.fastbank.am",
  //   }
  // ];
  image: any;

  constructor(public router: Router,
              private titleService: Title,
              private metaTagService: Meta,
              private service: DataService,
              public globals: AppGlobals) {
    const language = localStorage.getItem('language');

    const amTitle = "Գործընկեր բանկեր | Consult"
    const enTitle = "Partner banks | Consult"
    const ruTitle = "Банки-партнеры | Консульт"
    const amDesc = 'Կոնսալտ ընկերությունը համագործակցում է Հայաստանի բոլոր առաջատար բանկերի հետ | Consult'
    const enDesc = 'The Consult company cooperates with all the leading banks of Armenia Consult:'
    const ruDesc = 'Компания Консалт сотрудничает со всеми ведущими банками Армении Проконсультируйтесь:'

    let title = ''
    let description = ''
    if(language === 'am') {
      title = amTitle
      description = amDesc
    }
    if(language === 'en') {
      title = enTitle
      description = enDesc
    }
    if(language === 'ru') {
      title = ruTitle
      description = ruDesc
    }
    this.titleService.setTitle(title);

    this.metaTagService.addTag(
      { name: 'description', content: description },
    );

    this.router.events.subscribe((val) => {
      const lang = localStorage.getItem('language');
      if(lang === 'am') {
        title = amTitle
        description = amDesc
      }
      if(lang === 'en') {
        title = enTitle
        description = enDesc
      }
      if(lang === 'ru') {
        title = ruTitle
        description = ruDesc
      }

      this.titleService.setTitle(title);
      this.metaTagService.addTag(
        { name: 'description', content: description },
      );
    });

    this.service.getPartners({type: 1}).subscribe((data: any) => {
      this.lang = localStorage.getItem('language');
      this.banks = data;
    });
  }

  ngOnInit() {
    this.gotoTopInit();
  }

  gotoTopInit() {
    window.scroll({
      top: 0,
      left: 0
    });
  }


}
