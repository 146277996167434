import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-real-estate',
  templateUrl: './real-estate.component.html',
  styleUrls: ['./real-estate.component.css']
})
export class RealEstateComponent implements OnInit {

  user: string;

  constructor(public router: Router,
              private titleService: Title,
              private metaTagService: Meta) {
    const language = localStorage.getItem('language');

    const amTitle = "Շուկայական գնահատում | Consult"
    const enTitle = "Market valuation | Consult"
    const ruTitle = "Рыночная оценка | Консульт"
    const amDesc = 'Ծանոթացե՛ք անշարժ գույքի գներին թե՛ Երևանում, թե՛ Հայաստանի ողջ տարածքում Կոնսալտ ընկերության միջողով։'
    const enDesc = 'Get acquainted with real estate prices both in Yerevan and throughout Armenia through the Consult company.'
    const ruDesc = 'Ознакомьтесь с ценами на недвижимость как в Ереване, так и по всей Армении через компанию Консалт.'

    let title = ''
    let description = ''
    if(language === 'am') {
      title = amTitle
      description = amDesc
    }
    if(language === 'en') {
      title = enTitle
      description = enDesc
    }
    if(language === 'ru') {
      title = ruTitle
      description = ruDesc
    }
    this.titleService.setTitle(title);

    this.metaTagService.addTag(
      { name: 'description', content: description },
    );

    this.router.events.subscribe((val) => {
      const lang = localStorage.getItem('language');
      if(lang === 'am') {
        title = amTitle
        description = amDesc
      }
      if(lang === 'en') {
        title = enTitle
        description = enDesc
      }
      if(lang === 'ru') {
        title = ruTitle
        description = ruDesc
      }

      this.titleService.setTitle(title);
      this.metaTagService.addTag(
        { name: 'description', content: description },
      );
    });
  }

  ngOnInit() {
  }

}
