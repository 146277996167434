import { Component, OnInit } from '@angular/core';
import {PublicDataService} from "../../shared/services/public-data.service";
import {AppGlobals} from "../../app.globals";
import {BannersService} from "../../shared/services/banners.service";

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  user: string;

  banners = [];

  localStorageLanguage;
  urlForBanners;

  constructor( public publicData: PublicDataService,
               public globals: AppGlobals,
               private bannersService: BannersService) { }

  ngOnInit() {
    this.urlForBanners = this.globals.url;
    this.localStorageLanguage = localStorage.getItem('language');

    if(this.localStorageLanguage == 'am'){
      this.publicData.language = 'am'
    }

    if(this.localStorageLanguage == 'en'){
      this.publicData.language = 'en'
    }

    if(this.localStorageLanguage == 'ru'){
      this.publicData.language = 'ru'
    }

    this.bannersService.getBanners().subscribe((data: any) => {
      this.banners = [data[0], data[1]];
    });

    this.goToTopInit()
  }

  goToTopInit() {
    window.scroll({
      top: 0,
      left: 0
    });
  }

}
