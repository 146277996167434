import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  // todo... (language)
  static getLanguage() {
    // console.log('language ', localStorage.getItem('language'))
    return localStorage.getItem('language')
  }
}
