import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {catchError, tap} from "rxjs/operators";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      // Intercept the response and modify it if it's a 404 error
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.status === 404) {
          // Emit an empty HttpResponse with the 404 status code
          const notFoundResponse = new HttpResponse({ status: 404 });
          throw notFoundResponse;
        }
      }),
      // Catch the error and rethrow it
      catchError((error) => {
        throw error;
      })
    );
  }
}
