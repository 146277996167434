import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MapInfoService} from "../../shared/services/map-info.service";
import {PublicDataService} from "../../shared/services/public-data.service";
import {DataService} from "../../shared/services/data.service";
import {Router} from "@angular/router";
import {MapsAPILoader} from "@agm/core";
import {AppGlobals} from "../../app.globals";

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit, OnDestroy {
  // deprecated (component not used !)
  _id;
  apartmentsIs = false;
  hausesIs = false;
  newlyBuiltsIs = false;
  areasIs = false;
  complexsIs = false;
  landsIs = false;
  businessesIs = false;
  myActualUse;
  transactions;
  markers: any = [];
  selectedActualUse;
  selectedTransactions;
  selectedData;
  myMarkersApartments: any = [];
  myMarkersHauses: any = [];
  myMarkersNewlyBuilts: any = [];
  myMarkersAreas: any = [];
  myMarkersComplexs: any = [];
  myMarkersLands: any = [];
  myMarkersBusinesses: any = [];
  myMarkers: any = [];
  markersTransaction: any = [];
  myActualUseMarkers: any = [];
  myActualChangeEvent;
  myTransactionsChngeEvent: any = [];
  myArrayChangeData: any = [];
  newMarkersData = [];
  user: string;
  data;
  options;
  images;
  globalSrcRouteLanguage;
  array = [];
  lat = 40.183923;
  lng = 44.553241;
  zoom = 13;
  address: string;
  private geoCoder;
  @ViewChild('search', {static: false}) public searchElement: ElementRef;
  iconUrl: string;
  openedWindow : number = 0;
  isFulled = true;
  screenOptions = {
    position:  3
  };
  public mapStyles = [
    {
      "featureType": "poi",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ];

  curentData;
  newMarkerData;
  newlyBuildRoute = '';
  notNewlyBuildArray = [];
  onlyCommercials = [];
  urlForFiles;
  count = 0;
  m1;
  m2;

  constructor(private mapMarkerService: MapInfoService,  public publicData: PublicDataService, private dataService: DataService, private router: Router, private mapsAPILoader: MapsAPILoader,  private globals: AppGlobals) {
    this.urlForFiles = globals.urlForFiles;
  }

  ngOnInit() {
    this.mapMarkerService.getOnlycommercials().subscribe((data: any) => {
      for(let i = 0; i<data.length; i++){
        if(data[i].purpose !== 'Բազմաֆունկցիոնալ' && data[i].operational !== 'Բազմաֆունկցիոնալ' && (data[i].actualUse !== 'Բազմաֆունկցիոնալ' || data[i].actualUse !== 'Նորակառույց')){
          this.onlyCommercials.push(data[i]);
        }
      }
    });

    this.gotoTopInit();
    this.options = {
      onUpdate: (event: any) => {
      }
    };
    let x = this.router.url.split('id=')[1];
    let id = x.split('&')[0];
    let type = x.split('&')[1].split('type=')[1];
    this.dataService.getOneApartments(type, id).subscribe((data: any) => {
      this.curentData = data;
      if(data.announcementType == 'Համալիր' &&  data.purpose == 'Բազմաֆունկցիոնալ' && data.operational == 'Բազմաֆունկցիոնալ' && (data.actualUse == 'Բազմաֆունկցիոնալ' || data.actualUse == 'Նորակառույց')){
        this.newlyBuildRoute = 'Նորակառույց';
      }
      this.mapMarkerService.getMarkers(this.newlyBuildRoute == 'Նորակառույց' ? this.newlyBuildRoute : data.announcementType).subscribe((dataaa) => {
        this.data = dataaa;
        this.data = this.data.filter(item => item.toShow == true);
        this.lat = data.mapDetails.lat;
        this.lng = data.mapDetails.lng;
        if (data.announcementType == "Բնակարան") {
          this.globalSrcRouteLanguage = 'apartment';
          this.myApartament();
        } else if (data.announcementType == "Առանձնատուն") {
          this.globalSrcRouteLanguage = 'house';
          this.myHauses();
        }else if ( data.announcementType == 'Համալիր' &&  data.purpose == 'Բազմաֆունկցիոնալ' && data.operational =='Բազմաֆունկցիոնալ' && (data.actualUse == 'Բազմաֆունկցիոնալ' || data.actualUse == 'Նորակառույց')) {
          this.globalSrcRouteLanguage = 'newlyBuilds';
          this.myNewlyBuilts();
        }  else if (data.announcementType == "Տարածք") {
          this.globalSrcRouteLanguage = 'area';
          this.myAreas();
        }
        else if (data.announcementType == "Համալիր" && data.purpose != 'Բազմաֆունկցիոնալ' && data.operational != 'Բազմաֆունկցիոնալ' && (data.actualUse != 'Բազմաֆունկցիոնալ' || data.actualUse != 'Նորակառույց')) {
          this.myComplexs();
        } else if (data.announcementType == "Հողամաս") {
          this.globalSrcRouteLanguage = 'land';
          this.myLands();
        } else if (data.announcementType == "Բիզնես") {
          this.globalSrcRouteLanguage = 'business';
          this.myBusinesses();
        }
      });

      this.array.push(data);

      let dataa = []
        .concat(this.array[0].prices.filter(item => item.type === 'Վաճառք'))
        .concat(this.array[0].prices.filter(item => item.type === 'Վարձակալություն'))
        .concat(this.array[0].prices.filter(item => item.type === 'Օրավարձով'));

      this.array[0].prices = dataa;

      // @ts-ignore
      this.address = this.array[0].mapDetails.address;
      // @ts-ignore
      this.images = this.array[0].imgs;
      // @ts-ignore

    });

    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
    });
  }

  ngDoCheck() {
    if( localStorage.getItem('urlForFiless')){
      this.urlForFiles = localStorage.getItem('urlForFiless');
      this.globals.urlForFiles = localStorage.getItem('urlForFiless');
    }
  }

  errorImageLoad(e) {
    e.target.src = 'https://via.placeholder.com/150';
  }

  openWindow(m, _id) {
    this.openedWindow = _id; // alternative: push to array of numbers
    this.count++;
    if(this.count == 1){
      this.m1 = m;
    }
    if(this.count == 2){
      this.m2 = m;
      if(this.m2 === this.m1){
        // go to details markers
        let data = m;
        window.open(window.location.origin + `/details?id=${data._id}&type=${data.announcementType == 'Բնակարան' ? 'apartments' :
          data.announcementType == 'Առանձնատուն' ? 'houses' :
            // cart.announcementType == 'Կոմերցիոն' ? 'commercials' :
            data.announcementType == 'Տարածք' ? 'commercials' :
              (data.announcementType == 'Համալիր' && data.purpose == 'Բազմաֆունկցիոնալ' && data.operational == 'Բազմաֆունկցիոնալ' && (data.actualUse == 'Բազմաֆունկցիոնալ' || data.actualUse == 'Նորակառույց')) ? 'newlyBuilds' :
                data.announcementType == 'Համալիր' ? 'commercials' :
                  data.announcementType == 'Հողամաս' ? 'lands' :
                    data.announcementType == 'Բիզնես' ? 'businesses' : null
          }`, '_blank');
        // go to details markers
        this.count = 0;
      }else{
        this.m1 = this.m2;
        this.count = 1;
      }
    }
  }
  isInfoWindowOpen(_id) {
    return this.openedWindow == _id; // alternative: check if id is in array
  }
  setCurrentLocation() {
    this.zoom = 18;
  }
  //////////////////Բնակարան
  myApartament() {
    this.selectedTransactions = null;
    this.myMarkers = [];
    this.apartmentsIs = true;
    this.hausesIs = false;
    this.newlyBuiltsIs = false;
    this.areasIs = false;
    this.complexsIs = false;
    this.landsIs = false;
    this.businessesIs = false;
    this.myMarkers = this.data;
    for (let i = 0; i < this.myMarkers.length; i++) {
      if (this.myMarkers[i].codeValue !== this.curentData.codeValue) {
        this.myMarkers[i].iconUrl = {
          url: "../../../assets/images/mapMarkers/" + this.myMarkers[i].announcementType + this.myMarkers[i].transactions[0] + ".png",
          scaledSize: {
            height: 24,
            width: 24
          }
        };
      }
    }
  }
  //////////////////Առանձնատուն
  myHauses(){
    this.myMarkers = [];
    this.selectedTransactions = null;
    this.apartmentsIs = false;
    this.hausesIs = true;
    this.newlyBuiltsIs = false;
    this.areasIs = false;
    this.complexsIs = false;
    this.landsIs = false;
    this.businessesIs = false;
    this.myMarkers = this.data;
    for (let i = 0; i < this.myMarkers.length; i++) {
      if (this.myMarkers[i].codeValue !== this.curentData.codeValue) {
        this.myMarkers[i].iconUrl = {
          url: "../../../assets/images/mapMarkers/" + this.myMarkers[i].announcementType + this.myMarkers[i].transactions[0] +".png",
          scaledSize: {
            height: 24,
            width: 24
          }
        };
      }
    }
    this.markers = this.myMarkers;
    return this.markers;
  }
  /////////////////////////////////Նորակառույց
  myNewlyBuilts(){

    for(let i = 0; i < this.data.length; i++){
      if(this.data[i].announcementType == 'Համալիր' && (this.data[i].actualUse == 'Բազմաֆունկցիոնալ' || this.data[i].actualUse == 'Նորակառույց')){
        if(this.data[i].purpose == 'Բազմաֆունկցիոնալ' && this.data[i].operational == 'Բազմաֆունկցիոնալ'){
          this.myMarkersNewlyBuilts.push(this.data[i]);
        }
      }
    }
    this.myMarkers = [];

    this.myMarkers = this.myMarkersNewlyBuilts;
    for (let i = 0; i < this.myMarkers.length; i++) {
      if (this.myMarkers[i].codeValue !== this.curentData.codeValue) {
        this.myMarkers[i].iconUrl = {
          url: "../../../assets/images/mapMarkers/" + "Նորակառույց"  + this.myMarkers[i].transactions[0] + ".png",
          scaledSize: {
            height: 24,
            width: 24
          }
        };
      }
    }
    this.markers = this.myMarkers;
  }
  ///////////////////////Տարածք
  myAreas(){
    this.selectedTransactions = null;
    this.selectedActualUse = null;
    this.myMarkers = [];
    this.apartmentsIs = false;
    this.hausesIs = false;
    this.newlyBuiltsIs = false;
    this.areasIs = true;
    this.complexsIs = false;
    this.landsIs = false;
    this.businessesIs = false;
    this.myActualUse = this.publicData.actuallyBestUseAppartment;
    this.data = this.onlyCommercials;
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].purpose !== 'Բազմաֆունկցիոնալ' && this.data[i].operational !== 'Բազմաֆունկցիոնալ' && (this.data[i].actualUse !== 'Բազմաֆունկցիոնալ' || this.data[i].actualUse !== 'Նորակառույց')) {
        this.myMarkers.push(this.data[i]);
      }
    }


    for (let i = 0; i < this.myMarkers.length; i++) {
      if (this.myMarkers[i].announcementType == 'Տարածք') {
        if (this.myMarkers[i].codeValue !== this.curentData.codeValue) {
          this.myMarkers[i].iconUrl = {
            url: "../../../assets/images/mapMarkers/Commercial/" + this.myMarkers[i].announcementType + this.myMarkers[i].actualUse + this.myMarkers[i].transactions[0] + ".png",
            scaledSize: {
              height: 24,
              width: 24
            }
          };
        }
      }
      if (this.myMarkers[i].announcementType == 'Համալիր') {
        if (this.myMarkers[i].codeValue !== this.curentData.codeValue) {
          this.myMarkers[i].iconUrl = {
            url: "../../../assets/images/mapMarkers/Commercial/" + this.myMarkers[i].announcementType + this.myMarkers[i].actualUse + this.myMarkers[i].transactions[0] +".png",
            scaledSize: {
              height: 24,
              width: 24
            }
          };
        }
      }




    }
    this.markers = this.myMarkers;
    return this.markers;
  }
  //////////////// Համալիր
  myComplexs(){
    this.apartmentsIs = false;
    this.hausesIs = false;
    this.newlyBuiltsIs = false;
    this.areasIs = false;
    this.complexsIs = true;
    this.landsIs = false;
    this.businessesIs = false;
    this.data = this.onlyCommercials;
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].purpose !== 'Բազմաֆունկցիոնալ' && this.data[i].operational !== 'Բազմաֆունկցիոնալ' && (this.data[i].actualUse !== 'Բազմաֆունկցիոնալ' || this.data[i].actualUse !== 'Նորակառույց')) {
        this.myMarkers.push(this.data[i]);
      }
    }

    for (let i = 0; i < this.myMarkers.length; i++) {
      if (this.myMarkers[i].announcementType == 'Համալիր') {
        if (this.myMarkers[i].codeValue !== this.curentData.codeValue) {
          this.myMarkers[i].iconUrl = {
            url: "../../../assets/images/mapMarkers/Commercial/" + this.myMarkers[i].announcementType + this.myMarkers[i].actualUse  + this.myMarkers[i].transactions[0] +".png",
            scaledSize: {
              height: 24,
              width: 24
            }
          };
        }
      }
      if (this.myMarkers[i].announcementType == 'Տարածք') {
        if (this.myMarkers[i].codeValue !== this.curentData.codeValue) {
          this.myMarkers[i].iconUrl = {
            url: "../../../assets/images/mapMarkers/Commercial/" + this.myMarkers[i].announcementType + this.myMarkers[i].actualUse + this.myMarkers[i].transactions[0] + ".png",
            scaledSize: {
              height: 24,
              width: 24
            }
          };
        }
      }

    }
    this.markers = this.myMarkers;
  }
  /////////////////// Բիզնես
  myBusinesses(){
    this.selectedTransactions = null;
    this.selectedActualUse = null;
    this.myMarkers = [];
    this.apartmentsIs = false;
    this.hausesIs = false;
    this.newlyBuiltsIs = false;
    this.areasIs = false;
    this.complexsIs = false;
    this.landsIs = false;
    this.businessesIs = true;
    this.myActualUse = this.publicData.actuallyBestUseAppartment;
    this.myMarkers = this.data;
    for (let i = 0; i < this.myMarkers.length; i++) {
      if (this.myMarkers[i].codeValue !== this.curentData.codeValue) {
        this.myMarkers[i].iconUrl = {
          url: "../../../assets/images/mapMarkers/" + this.myMarkers[i].announcementType + this.myMarkers[i].transactions[0] +".png",
          scaledSize: {
            height: 24,
            width: 24
          }
        };
      }
    }
    this.markers = this.myMarkers;
  }
  /////////////////////// Հող
  myLands(){
    this.selectedTransactions = null;
    this.selectedActualUse = null;
    this.myMarkers = [];
    this.apartmentsIs = false;
    this.hausesIs = false;
    this.newlyBuiltsIs = false;
    this.areasIs = false;
    this.complexsIs = false;
    this.landsIs = true;
    this.businessesIs = false;
    this.myActualUse = this.publicData.actuallyBestUseLands;
    this.myMarkers = this.data;
    for (let i = 0; i < this.myMarkers.length; i++) {
      if (this.myMarkers[i].codeValue !== this.curentData.codeValue) {
        this.myMarkers[i].iconUrl = {
          url: "../../../assets/images/mapMarkers/" + this.myMarkers[i].announcementType + this.myMarkers[i].transactions[0] + ".png",
          scaledSize: {
            height: 24,
            width: 24
          }
        };
      }
    }
    this.markers = this.myMarkers;
  }

  ngOnDestroy(): void {
    localStorage.removeItem('cart');
    localStorage.removeItem('this.markersBnakaran[i]');
  }

  gotoTopInit() {
    window.scroll({
      top: 0,
      left: 0
    });
  }

  goToDetails(m) {

    window.open(window.location.origin + `/details?id=${m._id}&type=${m.announcementType == 'Բնակարան' ? 'apartments' :
      m.announcementType == 'Առանձնատուն' ? 'houses' :
        m.announcementType == 'Տարածք' ? 'commercials' :
          (m.announcementType == 'Համալիր' && m.purpose == 'Բազմաֆունկցիոնալ' && m.operational == 'Բազմաֆունկցիոնալ' && (m.actualUse == 'Բազմաֆունկցիոնալ' || m.actualUse == 'Նորակառույց')) ? 'newlyBuilds' :
            m.announcementType == 'Համալիր' ? 'commercials' :
              m.announcementType == 'Հողամաս' ? 'lands' :
                m.announcementType == 'Բիզնես' ? 'businesses' : null
      }`, '_blank');
  }

}
