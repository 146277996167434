import {Component, Input, OnInit, AfterViewInit, OnDestroy} from '@angular/core';
import {AppGlobals} from "../../app.globals";
import {ImagesApiService} from "../../shared/services/images-api.service";
import {DomSanitizer} from "@angular/platform-browser";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-image-tag',
  templateUrl: './image-tag.component.html',
  styleUrls: ['./image-tag.component.css']
})
export class ImageTagComponent implements OnInit, AfterViewInit, OnDestroy {

  subscription: Subscription[] = [];

  @Input()src: any;
  @Input()size: any;

  @Input()activeImageContainer?: any;

  url = '';
  imageSrc: any = ''

  loading = true;

  constructor(public globals: AppGlobals, private imagesApiService: ImagesApiService, private sanitizer: DomSanitizer) {
    this.url = this.globals.url;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getImage()
    }, 0)
  }

  ngOnInit() {}

  getImage() {
    this.imageSrc = '//wsrv.nl/?url=' + this.globals.urlForFiles + this.src
    this.loading = false
    // this.subscription.push(
    //   this.imagesApiService.getImage(this.src).subscribe((imageBlob: any) => {
    //     this.imageSrc = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(imageBlob));
    //     this.loading = false
    //   },(error: any) => {
    //     // Handle the error here
    //     console.error('Error loading image:', error);
    //     this.loading = false;
    //   })
    // )
  }

  errorImageLoad(e) {
    // e.target.src = 'https://via.placeholder.com/150';
    e.target.src = '../../../assets/images/no-picture-available.jpg';
    this.loading = false
  }

  ngOnDestroy():void {
    // this.subscription.forEach((subscription) => subscription.unsubscribe());
  }

}
