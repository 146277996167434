import {Injectable, Input} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AppGlobals} from "../../app.globals";

import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ImagesApiService {

  public changeSubject = new Subject();

  url;

  constructor( private http: HttpClient, private globals: AppGlobals) {
    this.url = this.globals.url;
  }

  getImage(imageSrc: string) {
    const options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'image/jpeg', // Adjust the content type based on your image format
        Accept: 'image/jpeg', // Adjust the accept type based on your image format
      }),
      responseType: 'blob' // Set the response type to 'blob'
    };

    return this.http.get<Blob>(`${this.url}/api/image/getImage?url=${encodeURIComponent(imageSrc)}`, options);
  }

}
