import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-cadastral-measurement',
  templateUrl: './cadastral-measurement.component.html',
  styleUrls: ['./cadastral-measurement.component.css']
})
export class CadastralMeasurementComponent implements OnInit {

  user: string;

  constructor(public router: Router,
              private titleService: Title,
              private metaTagService: Meta) {
    const language = localStorage.getItem('language');

    const amTitle = "Կադաստրային Չափագրում | Consult"
    const enTitle = "Cadastral surveying | Consult"
    const ruTitle = "Кадастровая съемка | Консульт"
    const amDesc = '«Կոնսալտ» ընկերությունն առաջարկում է անշարժ գույքի չափագրման,  կադաստրային աշխատքներ ցանկացած նպատակով:'
    const enDesc = '"Consult" company offers real estate measurement, cadastral works for any purpose.'
    const ruDesc = 'Компания «Консалт» предлагает замер недвижимости, кадастровые работы для любых целей.'

    let title = ''
    let description = ''
    if(language === 'am') {
      title = amTitle
      description = amDesc
    }
    if(language === 'en') {
      title = enTitle
      description = enDesc
    }
    if(language === 'ru') {
      title = ruTitle
      description = ruDesc
    }
    this.titleService.setTitle(title);

    this.metaTagService.addTag(
      { name: 'description', content: description },
    );

    this.router.events.subscribe((val) => {
      const lang = localStorage.getItem('language');
      if(lang === 'am') {
        title = amTitle
        description = amDesc
      }
      if(lang === 'en') {
        title = enTitle
        description = enDesc
      }
      if(lang === 'ru') {
        title = ruTitle
        description = ruDesc
      }

      this.titleService.setTitle(title);
      this.metaTagService.addTag(
        { name: 'description', content: description },
      );
    });
  }

  ngOnInit() {
  }

}
