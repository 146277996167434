import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Subject} from "rxjs";

import {AppGlobals} from "../../app.globals";

@Injectable({
  providedIn: 'root'
})
export class MapInfoService {
  announcementRouteGetOneDetails;
  url = '';

  private streetForMap = new Subject<any>();
  streetForMapChangeEmitted$ = this.streetForMap.asObservable();

  private cityForMap = new Subject<any>();
  cityForMapChangeEmitted$ = this.cityForMap.asObservable();

  streetForMapEmitChange(change: any) {
    this.streetForMap.next(change);
  }

  cityForMapEmitChange(change: any) {
    this.cityForMap.next(change);
  }



  constructor(private http: HttpClient, private globals: AppGlobals) {
    this.url = globals.url;
  }

  getOnlycommercials() {
    return this.http.get(this.url+'/api/commercialsOnly');
  }

  getMarkers(item) {
    switch (item) {
      case 'Բնակարան':
        return this.http.get(this.url+'/api/apartments');
        break;
      case 'Առանձնատուն':
        return this.http.get(this.url+'/api/houses');
        break;
        case 'Տարածք':
        return this.http.get(this.url+'/api/commercials');
        break;
        case 'Համալիր':
        return this.http.get(this.url+'/api/commercials');
        break;
      case 'Հողամաս':
        return this.http.get(this.url+'/api/lands');
        break;
      case 'Բիզնես':
        return this.http.get(this.url+'/api/businesses');
        break;
      case 'Նորակառույց':
        return this.http.get(this.url+'/api/newlyBuilds');
        break;
    }
  }

  // get one for details page start
  getOneAnnouncement (id, type, purpose, operational, actualUse){

    // router backend ....
    if(type == 'Բնակարան'){
      this.announcementRouteGetOneDetails = 'apartment'
    }
    if(type == 'Առանձնատուն'){
      this.announcementRouteGetOneDetails = 'house'
    }
    if(type == 'Տարածք'){
      this.announcementRouteGetOneDetails = 'area'
    }
    if(type == 'Համալիր'){
      this.announcementRouteGetOneDetails = 'complex'
    }
    if(type == 'Համալիր' && purpose == 'բազմաֆունկցիոնալ' && operational == 'բազմաֆունկցիոնալ' && (actualUse == 'Բազմաֆունկցիոնալ' || actualUse == 'Նորակառույց')){
      this.announcementRouteGetOneDetails = 'newlyBuild'
    }
    if(type == 'Հողամաս'){
      this.announcementRouteGetOneDetails = 'land'
    }
    if(type == 'Բիզնես'){
      this.announcementRouteGetOneDetails = 'business'
    }
    if(type == 'Շարժական'){
      this.announcementRouteGetOneDetails = 'portable'
    }
    // router backend ....
    return this.http.get<any>(`/api/` + this.announcementRouteGetOneDetails + `/` + id);
  }
  // get one for details page end

  // map marker filers
  getMapMarkers(type: any, filters: any) {
    const query = {
      announcementType: type,
      ...filters
    }
    return this.http.post(this.url+'/api/mapMarkers', query);
  }
  // map marker filers
}
