import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {NewsService} from "../../shared/services/news.service";
import {AppGlobals} from "../../app.globals";

@Component({
  selector: 'app-news-single-page',
  templateUrl: './news-single-page.component.html',
  styleUrls: ['./news-single-page.component.css']
})
export class NewsSinglePageComponent implements OnInit {

  news;
  url = '';

  constructor( private router: Router,
               private newsService: NewsService,
               private globals: AppGlobals ) {
  }

  ngOnInit() {
    let pathSegments = this.router.url.split("/");
    let lastSegment = pathSegments[pathSegments.length - 1];
    let id = lastSegment

    this.newsService.getNewsById(id).subscribe(data => {
      this.news = data;
    })

    this.url = this.globals.urlForFiles;
    this.gotoTopInit()
  }

  errorImageLoad(e) {
    e.target.src = 'https://via.placeholder.com/150';
  }

  gotoTopInit() {
    window.scroll({
      top: 0,
      left: 0
    });
  }

}
