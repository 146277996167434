import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AppGlobals} from "../../app.globals";

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  url;

  constructor( private http: HttpClient,
               private globals: AppGlobals ) {
    this.url = this.globals.url;
  }

  getNews(name, limit = 12, skip = 0) {
    return this.http.post<any>(`${this.url}/api/news/getNews?limit=${limit}&skip=${skip}`, name);
  }

  getNewsById(id) {
    return this.http.get(this.url + `/api/news/${id}`);
  }

}
