import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {PublicDataService} from '../../shared/services/public-data.service';
import {EventsService} from "../../shared/services/events.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  navOpenClose = false;
  navActive = false;
  user: string;

  isVisibleSubMenu = false;
  isVisibleSubMenuAbout = false;
  isVisibleSubMenuServices = false;

  currentLanguage;
  toggleFlagsDropdown = false;

  constructor(private translate: TranslateService, public publicData: PublicDataService, public events: EventsService, private router: Router) {
    let lang = ''
    const rout = this.router.url.substring(1)
    if(rout === 'en') {
      lang = 'en'
    }
    if(rout === 'ru') {
      lang = 'ru'
    }
    if(rout === 'am' || rout === '') {
      lang = 'am'
    }
    translate.setDefaultLang(lang);
    localStorage.setItem('language', lang);
    this.publicData.language = lang;
    this.translate.use(lang);

    this.currentLanguage = this.publicData.language
    setTimeout(() => {
      this.currentLanguage = this.publicData.language
    }, 600)
  }

  ngOnInit() {
    this.events.onCloseHeaderOpenedSubMenu$.subscribe((next) => {
      this.isVisibleSubMenu = false;
      this.isVisibleSubMenuAbout = false;
      this.isVisibleSubMenuServices = false;
      this.toggleFlagsDropdown = false;
    })
  }

  toggleNav() {
    this.navOpenClose = !this.navOpenClose;
    this.navActive = !this.navActive;
  }

  switchLanguage(language: string) {
    // rout
    let rout = ''
    if(language === 'am') {
      rout = this.router.url.substring(3)
    } else {
      if(localStorage.getItem('language') === 'am') {
        rout = '/' + language + this.router.url
      } else{
        rout = '/' + language + this.router.url.substring(3)
      }
    }

    this.toggleFlagsDropdown = !this.toggleFlagsDropdown;
    localStorage.setItem('language', language);
    this.publicData.language = language;
    this.translate.use(language);
    this.currentLanguage = localStorage.getItem('language');

    // language
    this.router.navigate([rout]);
  }

  toggleSubMenu() {
    event.preventDefault()
    this.isVisibleSubMenu = !this.isVisibleSubMenu;

    this.isVisibleSubMenuAbout = false;
    this.isVisibleSubMenuServices = false;
  }

  toggleSubMenuAbout() {
    event.preventDefault();
    this.isVisibleSubMenuAbout = !this.isVisibleSubMenuAbout;

    this.isVisibleSubMenu = false;
    this.isVisibleSubMenuServices = false;
  }

  toggleSubMenuServices() {
    event.preventDefault();
    this.isVisibleSubMenuServices = !this.isVisibleSubMenuServices;

    this.isVisibleSubMenu = false;
    this.isVisibleSubMenuAbout = false;
  }

}
