import {Component, HostListener, Input, OnInit} from '@angular/core';
import {AppGlobals} from "../../app.globals";
import {EventsService} from "../../shared/services/events.service";
import {ImagesApiService} from "../../shared/services/images-api.service";

import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {Router} from "@angular/router";

@Component({
  selector: 'app-footer-slider',
  templateUrl: './footer-slider.component.html',
  styleUrls: ['./footer-slider.component.css']
})
export class FooterSliderComponent implements OnInit {

  imageUrl: SafeUrl;

  @Input() data;

  SliderItemsLength;
  ChangeSliderPixels = 0;

  url = '';

  innerWidthState;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.onChangeResizeState(event.target.innerWidth)
  }

  constructor( public globals: AppGlobals,
               private events: EventsService,
               private imagesApiService: ImagesApiService,
               public sanitizer: DomSanitizer,
               public router: Router) {
    this.onChangeResizeState(window.innerWidth);
  }


  ngOnInit() {
    this.url = this.globals.urlForFiles;
    this.SliderItemsLength = this.data.length;

    this.events.ChangeFooterSlider$.subscribe(event => {
      if(event === 'prev' && this.ChangeSliderPixels !== 0){
        this.ChangeSliderPixels += 180
      }
        if(event === 'next' && this.ChangeSliderPixels !== -(this.SliderItemsLength - 2) * 180 && this.SliderItemsLength > 3 && this.innerWidthState > 420) {
          this.ChangeSliderPixels -= 180
        }

      if(event === 'next' && this.ChangeSliderPixels !== -(this.SliderItemsLength - 1) * 180 && this.SliderItemsLength > 3 && this.innerWidthState <= 420) {
        this.ChangeSliderPixels -= 180
      }
    })
  }

  onChangeResizeState(event) {
    this.innerWidthState = event;
    this.ChangeSliderPixels = 0;
  }

  errorImageLoad(e) {
    e.target.src = 'https://via.placeholder.com/150';
  }

  goToDetails(cart) {
    let lang = ''
    const rout = this.router.url.substring(1)
    if(rout.startsWith('en')) {
      lang = 'en'
    }
    if(rout.startsWith('ru')) {
      lang = 'ru'
    }
    if(rout.startsWith('am') || rout === '') {
      lang = ''
    }

    window.open(window.location.origin + `${lang !== '' ? '/' : ''}${lang}/details/${cart._id}/${cart.announcementType == 'Բնակարան' ? 'apartments' :
      cart.announcementType == 'Առանձնատուն' ? 'houses' :
        // cart.announcementType == 'Կոմերցիոն' ? 'commercials' :
        cart.announcementType == 'Տարածք' ? 'commercials' :
          (cart.announcementType == 'Համալիր' && cart.purpose == 'Բազմաֆունկցիոնալ' && cart.operational == 'Բազմաֆունկցիոնալ' && (cart.actualUse == 'Բազմաֆունկցիոնալ' || cart.actualUse == 'Նորակառույց')) ? 'newlyBuilds' :
            cart.announcementType == 'Համալիր' ? 'commercials' :
              cart.announcementType == 'Հողամաս' ? 'lands' :
                cart.announcementType == 'Բիզնես' ? 'businesses' : null
    }`, '_blank');
  }

}
