import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Subject} from "rxjs";

import {AppGlobals} from "../../app.globals";

@Injectable({
  providedIn: 'root'
})
export class BannersService {
  url = '';
  constructor(private http: HttpClient, private globals: AppGlobals) {
    this.url = globals.url;
  }
  getBanners() {
    return this.http.get(this.url + '/api/banners');
  }
}
