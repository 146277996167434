import {EventEmitter, Injectable} from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private emitChangeSource = new Subject<any>();
  changeEmitted$ = this.emitChangeSource.asObservable();

  public resetPageNumber$ = new Subject<number>()

  public pageChanged$ = new Subject<number>();
  activeSearchRoute;

  // for home page slider
  public ChangeSlider$ = new Subject();
  // for home page slider

  // for footer slider
  public ChangeFooterSlider$ = new Subject();
  // for footer slider

  emitChange3(change: any) {
    this.emitChangeSource.next(change);
  }

  public onCloseHeaderOpenedSubMenu$ = new Subject();

}
