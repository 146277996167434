import { Component, OnInit } from '@angular/core';
import {PublicDataService} from "../../shared/services/public-data.service";
import {AppGlobals} from "../../app.globals";
import {NewsService} from "../../shared/services/news.service";
import {Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";
import {BannersService} from "../../shared/services/banners.service";

@Component({
  selector: 'app-news-page',
  templateUrl: './news-page.component.html',
  styleUrls: ['./news-page.component.css']
})
export class NewsPageComponent implements OnInit {

  banners = [];

  localStorageLanguage;
  urlForBanners;

  user: string;
  news;
  newsNotFound = '';

  dataLength: number = 12;
  dataLimit: number = 12;
  dataSkip: number = 0;
  dataTablePage: number = 1;

  url = '';
  pageIndex;

  constructor( public publicData: PublicDataService,
               public globals: AppGlobals,
               private newsService: NewsService,
               public router: Router,
               private titleService: Title,
               private metaTagService: Meta,
               private bannersService: BannersService) {
    const language = localStorage.getItem('language');

    const amTitle = "Նորություններ | Consult"
    const enTitle = "News | Consult"
    const ruTitle = "Новости | Консульт"
    const amDesc = 'Ծանոթացե՛ք Հայաստանում անշարժ գույքի շուկայի նորություններին Կոնսալտ ընկերության հետ։ | cost.am'
    const enDesc = 'Get acquainted with the news of the real estate market in Armenia with the Consult company. | cost.am'
    const ruDesc = 'Знакомьтесь с новостями рынка недвижимости Армении вместе с компанией «Консалт». | cost.am'

    let title = ''
    let description = ''
    if(language === 'am') {
      title = amTitle
      description = amDesc
    }
    if(language === 'en') {
      title = enTitle
      description = enDesc
    }
    if(language === 'ru') {
      title = ruTitle
      description = ruDesc
    }
    this.titleService.setTitle(title);

    this.metaTagService.addTag(
      { name: 'description', content: description },
    );

    this.router.events.subscribe((val) => {
      const lang = localStorage.getItem('language');
      if(lang === 'am') {
        title = amTitle
        description = amDesc
      }
      if(lang === 'en') {
        title = enTitle
        description = enDesc
      }
      if(lang === 'ru') {
        title = ruTitle
        description = ruDesc
      }

      this.titleService.setTitle(title);
      this.metaTagService.addTag(
        { name: 'description', content: description },
      );
    });
  }

  ngOnInit() {
    this.urlForBanners = this.globals.url;
    this.localStorageLanguage = localStorage.getItem('language');

    if(this.localStorageLanguage == 'am'){
      this.publicData.language = 'am'
    }

    if(this.localStorageLanguage == 'en'){
      this.publicData.language = 'en'
    }

    if(this.localStorageLanguage == 'ru'){
      this.publicData.language = 'ru'
    }

    this.bannersService.getBanners().subscribe((data: any) => {
      this.banners = [data[0]];
    });

    this.url = this.globals.urlForFiles;
    this.getNews();
    this.gotoTopInit()
  }

  getNews() {
    this.news = [];
    this.newsNotFound = "";
    this.newsService.getNews('', this.dataLimit, this.dataSkip).subscribe((result) => {
      const [ data, count ] = result;
      this.news = data
      this.dataLength = count;
      // console.log('result ', result)
      if(!this.dataLength) {
        this.newsNotFound = "Նորություններ չեն գտնվել:";
      }
    })
  }

  errorImageLoad(e) {
    e.target.src = 'https://via.placeholder.com/150';
  }

  pageChanged(e) {
    // this.events.pageChanged$.next(e);
    this.pageIndex = e

    this.dataTablePage = e;
    this.dataSkip = (e * this.dataLimit) - this.dataLimit;
    // this.formSend();
    this.getNews();
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0
      });
    }, 1)
  }

  goToDetails(news) {
    // window.open(window.location.origin + `/${localStorage.getItem('language')}/news-single?id=${news._id}`, '_blank');
    window.open(window.location.origin + `/${localStorage.getItem('language') !== 'am' ? localStorage.getItem('language') : ''}/news-single/${news._id}`, '_blank');
  }

  gotoTopInit() {
    window.scroll({
      top: 0,
      left: 0
    });
  }

}
