import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";
import {DataService} from "../../../shared/services/data.service";
import {AppGlobals} from "../../../app.globals";

@Component({
  selector: 'app-builders-page',
  templateUrl: './builders-page.component.html',
  styleUrls: ['./builders-page.component.css']
})
export class BuildersPageComponent implements OnInit {

  user: string;
  builders: [];
  lang: string;

  // builders = [
  //   {
  //     imageSrc: "../../../../assets/images/co-workers-page/developers/1.png",
  //   },
  //   {
  //     imageSrc: "../../../../assets/images/co-workers-page/developers/2.png",
  //   },
  //   {
  //     imageSrc: "../../../../assets/images/co-workers-page/developers/3.png",
  //   },
  //   {
  //     imageSrc: "../../../../assets/images/co-workers-page/developers/4.png",
  //   },
  //   {
  //     imageSrc: "../../../../assets/images/co-workers-page/developers/5.png",
  //   },
  //   {
  //     imageSrc: "../../../../assets/images/co-workers-page/developers/6.png",
  //   }
  // ];

  constructor(public router: Router,
              private titleService: Title,
              private metaTagService: Meta,
              private service: DataService,
              public globals: AppGlobals) {
    const language = localStorage.getItem('language');

    const amTitle = "Կառուցապատողներ | Consult"
    const enTitle = "Developers | Consult"
    const ruTitle = "Разработчики | Консульт"
    const amDesc = 'Կոնսալտ ընկերությունը համագործակցում է Հայաստանի բոլոր առաջատար կառուցապատողների հետ | Consult'
    const enDesc = 'The Consult company cooperates with all the leading developers of Armenia | Consult'
    const ruDesc = 'Компания Консалт сотрудничает со всеми ведущими застройщиками Армении | Consult'

    let title = ''
    let description = ''
    if(language === 'am') {
      title = amTitle
      description = amDesc
    }
    if(language === 'en') {
      title = enTitle
      description = enDesc
    }
    if(language === 'ru') {
      title = ruTitle
      description = ruDesc
    }
    this.titleService.setTitle(title);

    this.metaTagService.addTag(
      { name: 'description', content: description },
    );

    this.router.events.subscribe((val) => {
      const lang = localStorage.getItem('language');
      if(lang === 'am') {
        title = amTitle
        description = amDesc
      }
      if(lang === 'en') {
        title = enTitle
        description = enDesc
      }
      if(lang === 'ru') {
        title = ruTitle
        description = ruDesc
      }

      this.titleService.setTitle(title);
      this.metaTagService.addTag(
        { name: 'description', content: description },
      );
    });

    this.service.getPartners({type: 3}).subscribe((data: any) => {
      //this.lang = localStorage.getItem('language');
      this.builders = data;
    });
  }

  ngOnInit() {
    this.gotoTopInit();
  }

  gotoTopInit() {
    window.scroll({
      top: 0,
      left: 0
    });
  }

}
