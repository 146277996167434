import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import {catchError} from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})

export class AppGlobals {

  port = '';
  protocol = 'https';
  hostname = '192.168.1.19:5000';
  urlForFiles;
  // uploadPath = 'http://localhost:4000';
  uploadPath = 'https://app.cost.am/images';

   // url = 'http://irealty.am:4203';   // TODO LIVE (FOR PRODUCTION) deprecated -> irealty domein removed

   url = 'https://app.cost.am';   // TODO LIVE (FOR PRODUCTION)
   // url = 'http://localhost:4203'; // TODO LOCAL (FOR DEVELOPMENT)
   // url = 'http://38.242.196.185:4203'; // TODO SOLIDY (FOR DEVELOPMENT)

  constructor(private http: HttpClient) {
      this.getUrl();
  }

  getUrl() {
    this.urlForFiles = 'https://app.cost.am/images';
    localStorage.setItem('urlForFiless', this.urlForFiles);

    // todo
    // this.http.get<any>(`${this.url}/api/image/activeServer`).subscribe((d) => {
    //   console.log('server ping !!!', d)
    // });

    // todo
    // this.http.get('http://185.57.69.144:5000').subscribe(d => console.log('....', d), this.errorHandler)
  }

  errorHandler(e) {
    if (e.status == 0) {
      this.urlForFiles = 'https://app.cost.am/images';
      localStorage.setItem('urlForFiless', this.urlForFiles);
    } else {
      this.urlForFiles = 'https://app.cost.am/images';
      localStorage.setItem('urlForFiless', this.urlForFiles);
    }
  }

}
