import {Component, Input, OnInit} from '@angular/core';
import {AppGlobals} from "../../app.globals";

@Component({
  selector: 'app-details-slider',
  templateUrl: './details-slider.component.html',
  styleUrls: ['./details-slider.component.css']
})
export class DetailsSliderComponent implements OnInit {

  @Input()images: any[];
  imagesSrcForModal = [];
  imagesSrcForModal2 = [];
  url;
  urlForFiles;
  modal;
  span;

  slideIndex = 1;
  slides;
  dots;

imgForSlider = [];
  constructor(private globals: AppGlobals) {
    this.url = this.globals.url + '/uploads/';
    this.urlForFiles = this.globals.urlForFiles;
  }

  ngOnInit() {
    this.images.forEach(img => {
      if(this.imgForSlider.includes(img) == false){
        this.imgForSlider.push(img);
      }
    })
    this.images = this.imgForSlider;

    this.images.forEach(item => {
      this.imagesSrcForModal.push({
        source: this.urlForFiles + item,
        alt: 'Description for Image 1',
        title: 'Title 1'
      });
    });

    for (let i = 0; i < this.imagesSrcForModal.length; i++) {
      this.imagesSrcForModal2.push(this.imagesSrcForModal[i].source);
    }

    this.modal = document.getElementById("myModal");
    this.span = document.getElementsByClassName("close")[0];

    this.span.addEventListener('click', () => {
      this.modal.style.display = "none";
    });

    window.addEventListener('click', (event) => {
      if (event.target == this.modal) {
        this.modal.style.display = "none";
      }
    });

    this.slides = document.getElementsByClassName("mySlides");
  }

  onImageClicked($event: any) {
    this.modal.style.display = "block";
    this.slideIndex = $event.index + 1;
    this.showSlides(this.slideIndex);
  }

  // zoom popup image start
  onImageClickedZoom(){
    let elementsZoomAll = document.getElementsByClassName('ui-panel-images');
    for(let i = 0;i<elementsZoomAll.length;i++){
      if(!elementsZoomAll[i].parentElement.classList.contains('ui-helper-hidden')){
        elementsZoomAll[i].parentElement.click()
      }
    }

  }
  // zoom popup image end

  // Next/previous controls
  plusSlides(n) {
    this.showSlides(this.slideIndex += n);
  }

  // Thumbnail image controls
  currentSlide(n) {
    this.showSlides(this.slideIndex = n);
  }

  showSlides(n) {

    if (n > this.slides.length) {
      this.slideIndex = 1
    }
    if (n < 1) {
      this.slideIndex = this.slides.length
    }
    for (let i = 0; i < this.slides.length; i++) {
      this.slides[i].style.display = "none";
    }

    this.slides[this.slideIndex - 1].style.display = "block";
  }


}
