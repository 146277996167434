import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";
import {DataService} from "../../../shared/services/data.service";
import {AppGlobals} from "../../../app.globals";

@Component({
  selector: 'app-credit-organizations-page',
  templateUrl: './credit-organizations-page.component.html',
  styleUrls: ['./credit-organizations-page.component.css']
})
export class CreditOrganizationsPageComponent implements OnInit {

  user: string;
  creditOrganizations: [];
  lang: string;

  constructor(public router: Router,
              private titleService: Title,
              private service: DataService,
              private metaTagService: Meta,
              public globals: AppGlobals) {
    const language = localStorage.getItem('language');

    const amTitle = "Վարկային կազմակերպություններ | Consult "
    const enTitle = "Credit organizations | Consult"
    const ruTitle = "Кредитные организации | Консульт"
    const amDesc = 'Կոնսալտ ընկերությունը համագործակցում է Հայաստանի բոլոր առաջատար վարկային կազմակերպությունների հետ | Consult'
    const enDesc = 'The Consult company cooperates with all the leading credit organizations of Armenia | Consult'
    const ruDesc = 'Компания Консалт сотрудничает со всеми ведущими кредитными организациями Армении. | Consult'

    let title = ''
    let description = ''
    if(language === 'am') {
      title = amTitle
      description = amDesc
    }
    if(language === 'en') {
      title = enTitle
      description = enDesc
    }
    if(language === 'ru') {
      title = ruTitle
      description = ruDesc
    }
    this.titleService.setTitle(title);

    this.metaTagService.addTag(
      { name: 'description', content: description },
    );

    this.router.events.subscribe((val) => {
      const lang = localStorage.getItem('language');
      if(lang === 'am') {
        title = amTitle
        description = amDesc
      }
      if(lang === 'en') {
        title = enTitle
        description = enDesc
      }
      if(lang === 'ru') {
        title = ruTitle
        description = ruDesc
      }

      this.titleService.setTitle(title);
      this.metaTagService.addTag(
        { name: 'description', content: description },
      );
    });

    this.service.getPartners({type: 2}).subscribe((data: any) => {
      this.lang = localStorage.getItem('language');
      this.creditOrganizations = data;
      console.log(data );
    });
  }

  ngOnInit() {
    this.gotoTopInit();
  }

  gotoTopInit() {
    window.scroll({
      top: 0,
      left: 0
    });
  }

}
