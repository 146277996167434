import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.http.get('/non-existent-endpoint').subscribe(
      () => {}, // Empty success callback
      (error) => {
        if (error.status === 404) {
          // console.log('404 Not Found');
        }
      }
    );
  }

}
