import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-to-builders',
  templateUrl: './to-builders.component.html',
  styleUrls: ['./to-builders.component.css']
})
export class ToBuildersComponent implements OnInit {

  user: string;

  constructor(public router: Router,
              private titleService: Title,
              private metaTagService: Meta) {
    const language = localStorage.getItem('language');

    const amTitle = "Կառուցապատողներին | Consult"
    const enTitle = "To the developers | Consult"
    const ruTitle = "Разработчикам | Консульт"
    const amDesc = 'Կոնսալտ ընկերությունն իրականացնում է բազմաֆունկցիոնալ նորակառույց բնակելի շենքերի վաճառքի գործընթացի բոլոր փուլերը'
    const enDesc = '"The Consult company carries out all stages of the sales process of multi-functional newly built residential buildings'
    const ruDesc = 'Консалтинговая компания осуществляет все этапы процесса продажи многофункциональных новостроек'

    let title = ''
    let description = ''
    if(language === 'am') {
      title = amTitle
      description = amDesc
    }
    if(language === 'en') {
      title = enTitle
      description = enDesc
    }
    if(language === 'ru') {
      title = ruTitle
      description = ruDesc
    }
    this.titleService.setTitle(title);

    this.metaTagService.addTag(
      { name: 'description', content: description },
    );

    this.router.events.subscribe((val) => {
      const lang = localStorage.getItem('language');
      if(lang === 'am') {
        title = amTitle
        description = amDesc
      }
      if(lang === 'en') {
        title = enTitle
        description = enDesc
      }
      if(lang === 'ru') {
        title = ruTitle
        description = ruDesc
      }

      this.titleService.setTitle(title);
      this.metaTagService.addTag(
        { name: 'description', content: description },
      );
    });
  }

  ngOnInit() {
  }

}
